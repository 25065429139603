import {
  submitTrackBehaviorAction,
  trackBehaviorRequestInitAction,
  viewTrackBehaviorAction,
  viewTrackBehaviorResultsAction,
  routeChangeTrackBehaviorAction,
  getTrackBehaviorAssessmentQuestionsAction,
  GetTrackBehaviorSubmissionsAction,
  SetTrackBehaviorSubmissionsAction
} from "./actions";
import {
  getTrackBehaveSurvey,
  postTrackBehaveSurvey,
  getTrackBehaveSubmissions
} from "./utils";

/**
 * To represetn chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

export const submitTrackBehaviorAssessment = (values, history) => {
  return dispatch => {
    postTrackBehaveSurvey(values)
      .then(response => {
        const { data, message } = response;

        dispatch(submitTrackBehaviorAction(data));

        history.push("/tracking-behavioral-changes/response");
      })
      .catch(e => console.log(e));
  };
};

export const fetchTrackBehaviorQuestions = () => {
  return dispatch => {
    dispatch(trackBehaviorRequestInitAction);

    return getTrackBehaveSurvey()
      .then(response => {
        // we have the data
        return dispatch(getTrackBehaviorAssessmentQuestionsAction(response));
        //
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const GetTrackBehaviorSubmissions = userIdPayload => {
  return dispatch => {
    dispatch(GetTrackBehaviorSubmissionsAction("Retrieving past submissions"));
    return getTrackBehaveSubmissions(userIdPayload)
      .then(response => {
        dispatch(
          SetTrackBehaviorSubmissionsAction(
            response.data,
            "Success Fetch Submissions"
          )
        );
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export default {
  viewTrackBehaviorResultsAction,
  viewTrackBehaviorAction,
  routeChangeTrackBehaviorAction
};
