import types from "./types";
import produce from "immer";
import Moment from "moment";

const arrayToObj = (array, lang = "english") =>
  array.reduce((obj, item, index, copy) => {
    obj[item.qNum] = {
      symptomSelected: null,
      selectEnabled: false,
      surveyQuestionId: item.id,
      response: lang === "english" ? "Select Reaction" : "Reacción"
    };
    return obj;
  }, {});

const defaultState = {
  score: {
    MemoryScore: -1,
    DisruptionScore: -1,
    DepressionScore: -1,
    TotalScore: -1
  },
  showResponse: false,
  questions: [],
  questions_es: [],
  assessmentInitialValues: {},
  assessmentInitialValues_es: {},
  loading: true,
  message: ""
};

const trackBehaviorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TrackBehavior_API_REQUEST_INIT:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.TrackBheavior_GET_QUESTIONS:
      return produce(state, draft => {
        // parse the questions and separate english from spanish
        draft.questions = action.payload.questions
          .filter(q => q.lang === "english")
          .map(q => {
            // Remove the first two elements ["Missing", "No Symptom"]
            q.decisions.shift();
            q.decisions.shift();

            return q;
          });

        draft.assessmentInitialValues = arrayToObj(draft.questions);

        draft.questions_es = action.payload.questions
          .filter(q => q.lang === "spanish")
          .map(q => {
            // Remove the first two elements ["Falta", "No hay sintoma" ]
            q.decisions.shift();
            q.decisions.shift();

            return q;
          });
        draft.assessmentInitialValues_es = arrayToObj(
          draft.questions_es,
          "spanish"
        );
        draft.message = action.payload.message;
        draft.loading = action.payload.loading;
      });

    case types.TrackBehavior_SUBMIT_SUCCESS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.score = action.payload.score;
        draft.showResponse = action.payload.showResponse;
      });
    case types.TrackBehavior_FAIL:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.TrackBehavior_GET_PAST_SUBMISSIONS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.TrackBehavior_SET_PAST_SUBMISSIONS:
      return produce(state, draft => {
        let submissions = [];

        /**
         * Parse through the submissions received from the API
         * and filter out boilerplate data
         */
        action.payload.submissions.forEach(submission => {
          let submissionDraft = {
            createdAt: Moment(submission.createdAt),
            id: submission.id,
            score: submission.score.totalScore,
            question_submission: []
          };

          submission.survey_question_submissions.forEach(question => {
            submissionDraft.question_submission.push({
              id: question.id,
              response: question.response === "No" ? "N/A" : question.response,
              text: question.survey_question.text,
              text_es: question.survey_question.text_es,
              response_es:
                question.response === "No"
                  ? "N/A"
                  : question.response_translation
            });
          });

          submissions.push(submissionDraft);
        });

        draft.submissions = submissions;

        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    default:
      return state;
  }
};

export default trackBehaviorReducer;
