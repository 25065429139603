import request from "../../utils/apis/request";

/**
 * Returns an array of objects.
 * Each object has a caregiver object, and 1..N patient objects associated
 * to the caregiver.
 * @param {Array} pids an array of patient ids
 */
export const getAllPatients = () => {
  return request({
    method: "get",
    url: "/api/users/get-patients-caregiver"
  });
};

/**
 * Returns the Provider of Caregiver identified by id
 * @param {Number} id Caregiver Id
 */
export const getProviderForCaregiver = id => {
  return request({
    method: "get",
    url: "/api/users/provider-for-caregiver/" + id
  });
};

/**
 * Returns an array of objects
 * id         number
 * createdAt  Date
 * updatedAt  Date
 * weight     number
 * patientId  number
 *
 * Each object is a record of a patient's weight that was recorded by
 * their caregiver
 */
export const getPatientWeightRecords = patientId =>
  request({
    method: "get",
    url: `/api/users/track-weight/${patientId}`
  });

/**
 * Creates a new weight record for the patient.
 *
 * Payload shape:
 *  patientId  number
 *  weight     number
 */
export const postPatientWeightRecord = data =>
  request({
    method: "post",
    url: "/api/users/track-weight",
    data
  });

export const deletePatientWeight = (patientId, recordId) =>
  request({
    method: "delete",
    url: `/api/users/track-weight/${patientId}/${recordId}`
  });
