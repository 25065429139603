import React from "react";
import { Row, Col, Button } from "antd";
import bg from "../assets/img/pages/login/background/med_pattern_dark.png";
import styled from "styled-components";
import { connect } from "react-redux";
import {
  requestPasswordReset,
  requestPasswordChange
} from "../modules/auth/operations";
import ForgotPasswordForm from "./recover-password/ForgotPasswordForm.tsx";
import ChangePasswordForm from "./recover-password/ChangePasswordForm.tsx";

const bgStyle = {
  height: "100%",
  backgroundImage: `url(${bg})`
};

const Container = styled.div`
  background-image: bg;
`;

const ContainerRow = styled(Row)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled(Col)`
  height: 540px;
  max-width: 400px;
  margin: auto;
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background: #f7f7f7;
  min-width: 400px;

  @media (max-width: 400px) {
    height: 100vh;
    margin: 0;
    width: 100vw;
  }

  @media (max-width: 575px and min-width: 401px) {
    height: 75vh;
    margin: 0;
    width: 95vw;
  }
`;

const getUrlParams = queryString => {
  const result = {};
  const params = (queryString.split("?")[1] || "").split("&");

  for (const param in params) {
    if (params.hasOwnProperty(param)) {
      const keyValuePair = params[param].split("=");
      result[keyValuePair[0]] = decodeURIComponent(keyValuePair[1] || "");
    }
  }

  return result;
};

class RecoverPassword extends React.Component {
  state = {
    hasToken: false,
    token: ""
  };

  goToLoginScreen = () => {
    this.props.history.push("/auth/login");
  };

  componentDidMount() {
    const queryString = this.props.history.location.search;
    const token = getUrlParams(queryString)["token"];
    if (token !== undefined) {
      console.log(token);
      this.setState({
        hasToken: true,
        token
      });
    }
  }

  handlePasswordChange = password => {
    return this.props.requestPasswordChange(password, this.state.token);
  };

  render() {
    return (
      <Container style={bgStyle}>
        <ContainerRow>
          <Content>
            {!this.state.hasToken ? (
              <ForgotPasswordForm
                goToLoginScreen={this.goToLoginScreen}
                requestPasswordReset={this.props.requestPasswordReset}
              />
            ) : (
                <ChangePasswordForm
                  handlePasswordChange={this.handlePasswordChange}
                  handleLogin={this.goToLoginScreen}
                />
              )}
          </Content>
        </ContainerRow>
      </Container>
    );
  }
}

const mapState = state => ({});
const mapDispatch = {
  requestPasswordReset,
  requestPasswordChange
};

export default connect(mapState, mapDispatch)(RecoverPassword);
