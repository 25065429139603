import request from "../../utils/apis/request";

export const login = (email, password) => {
  return request({
    method: "post",
    url: "/api/users/login",
    data: {
      email: email,
      password: password
    }
  });
};

export const forgotPassword = email => {
  return request({
    method: "post",
    url: "/api/users/forgot-password",
    data: {
      email
    }
  });
};

export const changePassword = (password, token) => {
  return request({
    method: "post",
    url: "/api/users/reset-password",
    data: {
      token,
      password
    }
  })
}
