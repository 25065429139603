import React from "react";
import { Row, Col } from "antd";
import styled from "styled-components";
import { string, object, array } from "prop-types";
import { SplitLongWords } from "../../utils/transfomers/strings";

const QuestionCell = styled.p`
  border-bottom: 1px solid black;
  font-weight: 750;
  padding-left: 8px;
  margin-bottom: 0px;
  height: 25px;
`;

const TextCell = styled.div`
  border-bottom: ${props => (props.last ? "2px solid black" : "none")};
  padding-left: 8px;
  padding-top: auto;
  padding-bottom: auto;
  height: 90px;
  min-height: ${props => (props.last ? "90px" : "0px")};
  font-size: 1.1em;
`;

export const SurveyQuestions = props => {
  const { surveyName, i18n, lang, submissions } = props;

  // Split the words that have a / in the middle
  submissions[0].question_submission.forEach(submission => {
    submission.text = SplitLongWords(submission.text);
    submission.text_es = SplitLongWords(submission.text_es);
  });

  return (
    <Col
      md={surveyName === "DailyLiving" ? 9 : 11}
      lg={9}
      style={{ border: "2px solid black" }}
    >
      <Row>
        <Col span={24}>
          <QuestionCell>{i18n[lang].PrevAssessments.Questions}</QuestionCell>
          {submissions[0].question_submission.map((submission, index) => (
            <TextCell
              key={submission.id}
              last={submissions[0].question_submission.length - 1 !== index}
            >
              {`${index + 1}. ${
                lang === "en" ? submission.text : submission.text_es
                }`}
            </TextCell>
          ))}
        </Col>
      </Row>
    </Col>
  );
};

SurveyQuestions.propTypes = {
  surveyName: string.isRequired,
  i18n: object.isRequired,
  lang: string.isRequired,
  submissions: array.isRequired
};
