import { format, compareAsc } from "date-fns";

const PrescriptionValidation = values => {
  const errors = {};
  let taskMissing = true;

  for (const [key, value] of Object.entries(values)) {
    if (value) {
      taskMissing = false;
    }
  }

  if (taskMissing) {
    errors.missing = "Please select at least one task.";
  }

  return errors;
};

export default PrescriptionValidation;
