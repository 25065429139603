import React from "react";
import { object, string, func } from "prop-types";

import { Form, Icon, Input, Button, Row, Col } from "antd";
import { Formik } from "formik";
import styled from "styled-components";
import { useSelector } from "react-redux";

import * as Yup from "yup";
import CustomButton from "../../../components/CustomButton/CustomButton";
import TranslationButtons from "../../../components/TranslationButtons/index";

/**
 * Styled Components
 */
const ContainerRow = styled(Row)`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled(Col)`
  height: 540px;
  margin: auto;
  padding: 15px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  background: #f7f7f7;

  @media (max-width: 400px) {
    height: 100vh;
    margin: 0;
    width: 100vw;
  }

  @media (max-width: 575px and min-width: 401px) {
    height: 75vh;
    margin: 0;
    width: 95vw;
  }
`;

const TitleSection = styled(Row)`
  margin: 10px 0px;
`;

const LoginLink = styled(Row)`
  padding-bottom: 8px;
`;

const Title = styled.h2`
  font-weight: 600;
  display: inline-block;
`;

const SubTible = styled.h2`
  font-weight: 300;
  display: inline-block;
  margin-left: 10px;
`;

/**
 * Validation Schema for the Login Form
 */
const loginSchema = Yup.object().shape({
  Email: Yup.string()
    .email("Invalid Email")
    .required(),
  Password: Yup.string()
    .min(1)
    .required("Password is required")
});

/**
 * View
 */
export const LoginView = ({ handleSubmit, handleForgotPassword }) => {
  const { i18n, lang } = useSelector(state => state.LangReducer);

  return (
    <Formik
      initialValues={{ Email: "", Password: "" }}
      validationSchema={loginSchema}
      onSubmit={({ Email, Password }) => handleSubmit(Email, Password)}
      validateOnBlur={false}
      validateOnChange={false}
      render={({ handleSubmit, handleChange, handleBlur, values, errors }) => (
        <ContainerRow>
          <Content>
            <TranslationButtons />
            <TitleSection type="flex" justify="center">
              <Title>CareHeroes</Title>
              <SubTible>Login</SubTible>
            </TitleSection>
            <Row>
              <h3>{i18n[lang].LoginView.formLabel}</h3>
              <p>{i18n[lang].LoginView.instructions}</p>
            </Row>
            <Form onSubmit={handleSubmit}>
              <Form.Item
                validateStatus={errors && errors.Email ? "error" : null}
                help={errors && errors.Email}
              >
                <Input
                  name="Email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Email}
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder={i18n[lang].LoginView.email}
                />
              </Form.Item>
              <Form.Item
                validateStatus={errors && errors.Password ? "error" : null}
                help={errors && errors.Password}
              >
                <Input
                  name="Password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.Password}
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="Password"
                  placeholder={i18n[lang].LoginView.password}
                />
              </Form.Item>
              <Form.Item>
                <CustomButton type="primary" htmlType="submit" block>
                  {i18n[lang].LoginView.loginBtn}
                </CustomButton>
              </Form.Item>
              <LoginLink>
                <Button type="link" onClick={handleForgotPassword}>
                  {lang === "en" ? "Forgot Password" : "Recuperar Contraseña."}
                </Button>
              </LoginLink>
              <LoginLink>
                {/* <Button type="link">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                      About Careheroes
                    </a>
                  </Button> */}
              </LoginLink>
            </Form>
          </Content>
        </ContainerRow>
      )}
    />
  );
};

LoginView.propTyes = {
  handleSubmit: func
};

export default LoginView;
