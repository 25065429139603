/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const POSTIVE_ASPECTS_SUBMIT = "careheroes/assessments/POSTIVE_ASPECTS_SUBMIT";
const POSTIVE_ASPECTS_SUCCESS =
  "careheroes/assessments/POSTIVE_ASPECTS_SUCCESS";
const POSTIVE_ASPECTS_FAIL = "careheroes/assessments/POSTIVE_ASPECTS_FAIL";
const POSTIVE_ASPECTS_VIEW = "careheroes/assessments/POSTIVE_ASPECTS_VIEW";
const POSTIVE_ASPECTS_VIEW_RESULTS =
  "careheroes/assessments/POSTIVE_ASPECTS_VIEW_RESULTS";
const POSTIVE_ASPECTS_ROUTE_CHANGE =
  "carehereoes/assessments/POSTIVE_ASPECTS_ROUTE_CHANGE";
const POSITIVE_ASPECTS_SET_QUESTIONS =
  "careheroes/assessments/POSITIVE_ASPECTS_SET_QUESTIONS";
const POSITIVE_ASPECTS_GET_QUESTIONS =
  "careheroes/assessments/POSTIVE_ASPECTS_GET_QUESTIONS";
const POSITIVE_ASPECTS_GET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/POSITIVE_ASPECTS_GET_PAST_SUBMISSIONS";
const POSITIVE_ASPECTS_SET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/POSITIVE_ASPECTS_SET_PAST_SUBMISSIONS";

export default {
  POSTIVE_ASPECTS_SUBMIT,
  POSTIVE_ASPECTS_SUCCESS,
  POSTIVE_ASPECTS_FAIL,
  POSTIVE_ASPECTS_VIEW,
  POSTIVE_ASPECTS_VIEW_RESULTS,
  POSTIVE_ASPECTS_ROUTE_CHANGE,
  POSITIVE_ASPECTS_GET_QUESTIONS,
  POSITIVE_ASPECTS_SET_QUESTIONS,
  POSITIVE_ASPECTS_GET_PAST_SUBMISSIONS,
  POSITIVE_ASPECTS_SET_PAST_SUBMISSIONS
};
