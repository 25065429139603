import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input as AntInput, Row, Col, DatePicker, message, Spin } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Input, Radio } from "formik-antd";
import moment from "moment";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { updatePatient } from "../../../modules/providerHome/utils";
const InputGroup = AntInput.Group;

const EditPatientSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name Required"),
  lastname: Yup.string().required("Last Name Required"),
  relationship: Yup.string().required(
    "Relationship of Patient to Caregiver required."
  ),
  birthday: Yup.string().required("Birthday Required"),
  gender: Yup.string()
    .matches(/(male|female)/, "Select a gender.")
    .required("Gender Required")
});

// Provider View for editing patient and caregiver data
const EditPatient = props => {
  const [updatingPatient, setUpdatingPatient] = useState(false);
  const patient = useSelector(state => state.ProviderHome.currentcombo.patient);
  const patientId = useSelector(state => state.ProviderHome.patientId);

  const disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  return (
    <div>
      <Formik
        initialValues={{
          firstname: patient.firstname,
          lastname: patient.lastname,
          relationship: patient.relationship,
          birthday: patient.birthday,
          gender: patient.gender
        }}
        validationSchema={EditPatientSchema}
        onSubmit={async values => {
          try {
            setUpdatingPatient(true);

            updatePatient(patientId, values);

            setUpdatingPatient(false);
            message.success("Patient data updated.", 2.5);

            //go back to dashboard
            props.history.push("/dashboard");
          } catch (error) {
            setUpdatingPatient(false);
            message.error("Error updating patient data.", 2.5);
          }
        }}
        render={({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <h3>Patient:</h3>
            <InputGroup>
              <Row gutter={8}>
                <Col lg={12}>
                  <Form.Item label="First Name" name="firstname">
                    <Input name="firstname" />
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item label="Last Name" name="lastname">
                    <Input name="lastname" />
                  </Form.Item>
                </Col>
              </Row>
            </InputGroup>
            <Form.Item label="Relationship to Caregiver" name="relationship">
              <Input name="relationship" />
            </Form.Item>
            <InputGroup>
              <Row gutter={8}>
                <Col sm={24} md={12} lg={5}>
                  <Form.Item label="Date of Birth" name="birthday">
                    {/* 
                      // @ts-ignore */}
                    <DatePicker
                      onChange={e => setFieldValue("birthday", e.format("L"))}
                      format="MM/DD/YYYY"
                      name="birthday"
                      disabledDate={disabledDate}
                      defaultValue={moment(patient.birthday)}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={5}>
                  <Form.Item label="Gender" name="gender">
                    <Radio.Group name="gender">
                      <Radio.Button value="male">Male</Radio.Button>
                      <Radio.Button value="female">Female</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </InputGroup>
            <div>
              <CustomButton type="primary" htmlType="submit">
                {!updatingPatient ? `Update` : `Updating`}
              </CustomButton>
              {updatingPatient && (
                <span style={{ paddingLeft: "15px" }}>
                  <Spin />
                </span>
              )}
            </div>
          </Form>
        )}
      />
    </div>
  );
};

export default EditPatient;
