import React from "react";
import { Row, Col, Spin, Icon, Button } from "antd";
import { withGetScreen } from "react-getscreen";
import PropTypes from "prop-types";
import { setBorderStylingFor } from "../../modules/trackBehaviorAssessment/utils";
import { connect } from "react-redux";
import SurveySubmissionsMobile from "./SurveySubmissionsMobile";
import { AssessmentButtons } from "./AssessmentButtons";
import { SurveyQuestions } from "./SurveyQuestions";
import { SurveyResponses } from "./SurveyResponses";

/**
 * This component can be further broken down into smaller components
 * REFACTOR!!!!!!! This is becoming a mess
 *
 * Refactored on 11/13/19
 */
const SpinStyle = {
  textAlign: "center",
  // background: "rgba(0,0,0, 0.05)",
  borderRadius: "4px",
  marginBottom: "20px",
  padding: "30px 50px",
  margin: "20px 0"
};

class SurveySubmissions extends React.Component {
  state = {
    submissions: [],
    displayedSubmissions: [],
    loading: true,
    leftIndex: -1,
    rightIndex: -1
  };

  handleLeftShift = e => {
    e.preventDefault();

    if (this.props.leftIndex > 0) {
      this.props.handleIndexShift(
        this.props.leftIndex - 1,
        this.props.rightIndex - 1
      );
    }
  };

  handleRightShift = e => {
    e.preventDefault();

    if (this.props.submissions.length !== this.props.rightIndex + 1) {
      this.props.handleIndexShift(
        this.props.leftIndex + 1,
        this.props.rightIndex + 1
      );
    }
  };

  render() {
    const { leftIndex, rightIndex, i18n, lang } = this.props;
    const length = this.props.submissions.length;

    // detecting if the leftIndex and rightIndeex have changed should be moved to redux
    // TODO: move them
    if (this.props.submissions[0].id === -1 || leftIndex === -1) {
      return (
        <div className="content" style={SpinStyle}>
          <Spin />
          <p>{lang === "en" ? "Fetching Submissions" : "Obteniendo Envíos"}</p>
        </div>
      );
    } else {
      if (this.props.isMobile()) {
        return (
          <SurveySubmissionsMobile
            submissions={this.props.submissions}
            submissionsLen={length}
            instructions={this.props.children}
          />
        );
      } else {
        return (
          // Buttons
          <div className="content">
            <AssessmentButtons
              i18n={i18n}
              lang={lang}
              leftIndex={leftIndex}
              rightIndex={rightIndex}
              handleLeftShift={this.handleLeftShift}
              handleRightShift={this.handleRightShift}
              submissions={this.props.submissions}
            />

            {this.props.submissions.length > 0 && (
              <>
                <Row>
                  {/* Instructions passed by the parent component */}
                  <Col>{this.props.children}</Col>
                </Row>

                {/* These are the questions */}
                <Row>
                  <SurveyQuestions
                    surveyName={this.props.surveyName}
                    i18n={i18n}
                    lang={lang}
                    submissions={this.props.submissions}
                  />

                  {/* These are the responses */}
                  <SurveyResponses
                    surveyName={this.props.surveyName}
                    submissions={this.props.submissions}
                    rightIndex={rightIndex}
                    leftIndex={leftIndex}
                  />
                </Row>
              </>
            )}
          </div>
        );
      }
    }
  }
}

SurveySubmissions.propTypes = {
  leftIndex: PropTypes.number,
  rightIndex: PropTypes.number,
  submissions: PropTypes.array,
  handleIndexShift: PropTypes.func,
  isMobile: PropTypes.func,
  surveyName: PropTypes.string.isRequired
};

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapState)(withGetScreen(SurveySubmissions));
