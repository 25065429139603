import { generateMedia } from "styled-media-query";

const MediaQueries = generateMedia({
  huge: "1440px",
  large: "1170px",
  medium: "768px",
  small: "450px"
});

export const GetScreenLimits = {
  mobileLimit: 450,
  tabletLimit: 1170,
  shouldListenOnResize: true
}

export default MediaQueries;
