import { createStore, compose, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
// import createHistory from "history/createBrowserHistory";
import { createBrowserHistory } from "history";
import createRootReducer from "../modules";
import sessionStorage from "redux-persist/lib/storage/session";
import { persistStore, persistReducer } from "redux-persist";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
  stateReconciler: autoMergeLevel1,
  blacklist: [
    "phq",
    "zarit",
    "PositiveAspectsReducer",
    "DailyLivingReducer",
    "TrackBehavior",
    "LangReducer"
  ]
};
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.

export const history = createBrowserHistory();

function configureStoreProd(initialState = {}) {
  const middlewares = [
    // Add other middleware on this line...

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk
  ];

  const store = createStore(
    persistReducer(persistConfig, createRootReducer()),
    initialState,
    compose(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  return { store, persistor };
}

function configureStoreDev(initialState = {}) {
  const middlewares = [
    // Add other middleware on this line...

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    // reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
    thunk,
    // reactRouterMiddleware,
    logger
  ];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    persistReducer(persistConfig, createRootReducer()),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );

  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept("../modules", () => {
  //     const nextRootReducer = require("../modules/index").default; // eslint-disable-line global-require
  //     store.replaceReducer(connectRouterHistory(nextRootReducer));
  //   });
  // }

  const persistor = persistStore(store);

  return { store, persistor };
}

const configureStore =
  process.env.NODE_ENV === "production"
    ? configureStoreProd
    : configureStoreDev;

export default configureStore;
