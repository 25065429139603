import types from "./types";
import { IUser } from "./interfaces";
import { IAction } from "../../interfaces/modules";

const defaultState: IUser = {
  firstname: "",
  lastname: "",
  picture: "",
  phone: "",
  email: "",
  id: -1
};

const UserReducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.User_LOAD:
      return { ...state, ...action.payload };
    case types.User_RESET:
      return {
        ...defaultState
      };
    case types.User_UPDATE: //TODO: update when PUT is implemented
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default UserReducer;
