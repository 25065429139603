import React from "react";
import { Row, Col } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { object, string, number } from "prop-types";
import CustomButton from "../../../components/CustomButton/CustomButton";

class PhqResponse extends React.Component {
  handleClick = () => this.props.history.push("/dashboard");
  render() {
    const { score, i18n, lang } = this.props;
    const translation = i18n[lang];
    const positive_instructions = `${translation.AssessmentResponses.PHQ.Positive_Instructions_Part1} ${translation.AssessmentResponses.PHQ.Positive_Instructions_Part2}`;

    if (score >= 2) {
      return (
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <h4>{translation.AssessmentResponses.PHQ.Positive_Title}</h4>
            <h4>{translation.AssessmentResponses.PHQ.Positive_Q}</h4>
            <h5>{positive_instructions}</h5>
            <p>{translation.AssessmentResponses.Buttons.Instructions}</p>
          </Col>
          <Col>
            <CustomButton onClick={this.handleClick}>
              {translation.AssessmentResponses.Buttons.Label}
            </CustomButton>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <h5>{translation.AssessmentResponses.PHQ.Negative_Instructions}</h5>
            <p>{translation.AssessmentResponses.Buttons.Instructions}</p>
          </Col>
          <Col>
            <CustomButton onClick={this.handleClick}>
              {translation.AssessmentResponses.Buttons.Label}
            </CustomButton>
          </Col>
        </Row>
      );
    }
  }
}
PhqResponse.propTypes = {
  score: number,
  i18n: object,
  lang: string
};
const mapStateToProps = state => ({
  score: state.phq.score,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatchToProps = dispatch => {
  return Object.assign({ dispatch }, bindActionCreators({}, dispatch));
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhqResponse);
