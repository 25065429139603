import types from "./types";
/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const submitPhq2 = () => ({
  type: types.PHQ2_SUBMIT,
  payload: {
    loading: true,
    message: "Submitting Patient Health Questionnaire"
  }
});

export const submitPhq2Success = (message, score) => ({
  type: types.PHQ2_SUCCESS,
  payload: {
    message,
    loading: false,
    showResponse: true,
    score: score
  }
});

export const phq2Fail = message => ({
  type: types.PHQ2_FAIL,
  payload: {
    message,
    loading: false
  }
});

export const setPhq2Questions = (questions, message) => ({
  type: types.PHQ2_SET_QUESIONS,
  payload: {
    questions: questions,
    message: message,
    loading: false
  }
});

export const getPhq2Questions = message => ({
  type: types.PHQ2_GET_QUESTIONS,
  payload: {
    loading: true,
    message: message
  }
});

export const GetPhq2SubmissionsAction = message => ({
  type: types.PHQ2_GET_PAST_SUBMISSIONS,
  payload: {
    loading: true,
    message
  }
});

export const SetPhq2SubmissionsAction = (data, message) => ({
  type: types.PHQ2_SET_PAST_SUBMISSIONS,
  payload: {
    submissions: data,
    message,
    loading: false
  }
});

export const viewPhq2 = () => ({
  type: types.PHQ2_VIEW,
  payload: {
    showResponse: false,
    score: 0
  }
});

export const PHQ2_VIEW_RESULTS = () => ({
  type: types.PHQ2_VIEW_RESULTS,
  payload: {}
});

export const routeChangePhq2 = () => ({
  type: types.PHQ2_ROUTE_CHANGE
});
