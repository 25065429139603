import request from "../../utils/apis/request";

export const getZaritSurvey = () => {
  return request({
    url: "/api/surveys/zarit-burden-inventory",
    method: "get"
  });
};

export const postZaritSurvey = data => {
  return request({
    url: "/api/surveys/zarit-burden-inventory",
    method: "post",
    data
  });
};

export const getZaritSubmissions = () => {
  return request({
    url: "/api/surveys/submissions/zarit-burden-inventory",
    method: "post"
  });
};
