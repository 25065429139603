/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const PHQ2_SUBMIT = "careheroes/assessments/PHQ2_SUBMIT";
const PHQ2_SUCCESS = "careheroes/assessments/PHQ2_SUCCESS";
const PHQ2_FAIL = "careheroes/assessments/PHQ2_FAIL";
const PHQ2_VIEW = "careheroes/assessments/PHQ2_VIEW";
const PHQ2_ROUTE_CHANGE = "carehereoes/assessments/PHQ2_ROUTE_CHANGE";
const PHQ2_GET_QUESTIONS = "careheroes/assessments/PHQ2_GET_QUESTIONS";
const PHQ2_SET_QUESIONS = "careheroes/assessments/PHQ2_SET_QUESTIONS";
const PHQ2_GET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/PHQ2_GET_PAST_SUBMISSIONS";
const PHQ2_SET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/PHQ2_SET_PAST_SUBMISSIONS";

export default {
  PHQ2_SUBMIT,
  PHQ2_VIEW,
  PHQ2_ROUTE_CHANGE,
  PHQ2_SUCCESS,
  PHQ2_FAIL,
  PHQ2_GET_QUESTIONS,
  PHQ2_SET_QUESIONS,
  PHQ2_GET_PAST_SUBMISSIONS,
  PHQ2_SET_PAST_SUBMISSIONS
};
