import AdminHome from "../pages/admin/AdminHome";
import RegisterProvider from "../pages/admin/registerprovider/RegisterProvider.tsx";

/**
 * Routes related to the Admin Account
 */
const AdminRoutes = [
  {
    path: "/dashboard/adm",
    component: AdminHome,
  },
  {
    path: "/register/provider",
    component: RegisterProvider,
  },
];

export default AdminRoutes;
