import React from "react";
import ProviderViewPatient from "./provider/ViewPatient/ProviderViewPatient";

class PatientViewContainer extends React.Component {
  render() {
    return (
      <div className="content">
        <ProviderViewPatient />
      </div>
    );
  }
}

export default PatientViewContainer;
