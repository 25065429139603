import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import CustomButton from "../../../components/CustomButton/CustomButton";

/**
 */
class TrackBehaviorResponse extends React.Component {
  handleClick = e => {
    e.preventDefault();

    this.props.history.push("/dashboard");
  };
  render() {
    const { i18n, lang } = this.props;

    return (
      <div className="content">
        <Row type="flex" justify="center" align="middle">
          <Col span={24}>
            <Row type="flex" justify="center">
              <h4
                style={{
                  textAlign: "center"
                }}
              >
                {i18n[lang].AssessmentResponses.TrackBehavior.Response}
              </h4>
              <p>{i18n[lang].AssessmentResponses.Buttons.Instructions}</p>
            </Row>
          </Col>
          <Col>
            <CustomButton onClick={this.handleClick}>
              {i18n[lang].AssessmentResponses.Buttons.Label}
            </CustomButton>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapState)(TrackBehaviorResponse);
