import React from "react";
import { Row, Col, Button } from "antd";
import PaocInstructions from "./positive-aspects-caregiving/PaocInstructions";
import PaocAssessment from "./positive-aspects-caregiving/PaocAssessment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { submitPositiveAspectsAssessment } from "../modules/positiveAspectsAssessment/operations";
import CustomButton from '../components/CustomButton/CustomButton';


class PositiveAspectsCaregiving extends React.Component {
  state = {
    showWizard: false
  };

  handleToggleWizard = e => {
    e.preventDefault();

    this.setState({
      showWizard: true
    });
  };

  handleShowPastAssessments = e => {
    e.preventDefault();

    this.props.history.push("/paoc/view");
  };

  handleSubmit = totalScore => {
    this.props.submitPositiveAspectsAssessment(totalScore, this.props.history);
  };

  render() {
    const { i18n, lang } = this.props;
    return (
      <div
        className="content"
        style={
          this.state.showWizard
            ? {}
            : {
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }
        }
      >
        <Row style={this.state.showWizard ? {} : { maxWidth: "600px" }}>
          {!this.state.showWizard && (
            <>
              <Col md={24}>
                <Row style={{ textAlign: "center" }}>
                  <PaocInstructions />
                </Row>
              </Col>

              <Col span={24}>
                <CustomButton type="primary" onClick={this.handleToggleWizard} block>
                  {i18n[lang].Assessments.BeginTest}
                </CustomButton>
                <Button
                  type="secondary"
                  onClick={this.handleShowPastAssessments}
                  block
                >
                  {i18n[lang].Assessments.ShowSubmissions}
                </Button>
              </Col>
            </>
          )}
          <Col>
            {this.state.showWizard && (
              <PaocAssessment handleSubmit={this.handleSubmit} />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ submitPositiveAspectsAssessment }, dispatch)
  );
};

export default connect(
  mapState,
  mapDispatchToProps
)(PositiveAspectsCaregiving);
