import React from "react";
import { Row, Col, Spin } from "antd";
import {
  submitTrackBehaviorAssessment,
  fetchTrackBehaviorQuestions
} from "../../modules/trackBehaviorAssessment/operations";
import { connect } from "react-redux";
import Wizard from "../../components/Wizard/AssessmentWizard";
import { withRouter } from "react-router-dom";
import TrackBehaviorChoices from "./trackBehaviorAssessment/TrackBehaviorChoices";
import { array, object, func, string, bool } from "prop-types";

class TrackBehaviorAssessment_ES extends React.Component {
  componentDidMount() {
    if (
      this.props.questions.length == 0 ||
      this.props.questions_es.length == 0
    ) {
      this.props.fetchTrackBehaviorQuestions().then(() => {
        console.log("one of the questions was missing");
        this.setState({
          initialValues: this.props.assessmentInitialValues_es
        });
      });
    } else {
      this.setState({
        initialValues: this.props.assessmentInitialValues_es
      });
    }
    // this.props.fetchTrackBehaviorQuestions().then(e => {
    //   // Moved the intialValues of the assessments to local state
    //   // in order to do this
    //   // recreate a copy of the state
    //   // with the surveyQuestionId assigned before rendering
    //   const arrayToObj = array =>
    //     array.reduce((obj, item, index, copy) => {
    //       obj[item.qNum] = {
    //         symptomSelected: null,
    //         selectEnabled: false,
    //         surveyQuestionId: item.id,
    //         response: "Reacción"
    //       };
    //       return obj;
    //     }, {});

    //   const iValues = arrayToObj(this.props.questions_es);
    //   this.setState({
    //     initialValues: iValues,
    //     loading: false
    //   });
    // });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("prevProps", prevProps, "current props", this.props);
  }

  state = {
    loading: true,
    initialValues: {
      q1: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q2: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q3: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q4: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q5: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q6: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q7: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q8: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q9: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q10: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q11: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q12: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q13: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q14: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q15: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q16: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q17: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q18: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q19: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q20: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q21: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q22: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q23: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      },
      q24: {
        symptomSelected: null,
        selectEnabled: false,
        surveyQuestionId: -1,
        response: "Reacción"
      }
    }
  };

  render() {
    const { i18n, lang } = this.props;

    const defaultStateModified = () =>
      this.state.initialValues.q1.surveyQuestionId !== -1;

    if (this.props.loading === false) {
      return (
        <Row>
          <Col span={24}>
            {defaultStateModified(this.state) && (
              <Wizard
                loading={this.state.loading}
                initialValues={this.state.initialValues}
                onSubmit={async values => {
                  let temp = [];

                  // iterate through the values array
                  // eslint-disable-next-line no-unused-vars
                  for (let [key, obj] of Object.entries(values)) {
                    // New requirement from 11/14/19
                    if (obj.symptomSelected === "No") {
                      obj.response = "No Symptom";
                    }

                    // New requirement from 11/14/19
                    if (obj.symptomSelected === null) {
                      obj.response = "Missing";
                    }

                    // just in case
                    if (
                      obj.symptomSelected === "yes" &&
                      obj.response === "Reacción"
                    ) {
                      obj.response = "Missing";
                    }

                    if (obj.response === "Para nada") {
                      obj.response = "Not at all";
                    }

                    if (obj.response === "Un poco") {
                      obj.response = "A Little";
                    }

                    if (obj.response === "Moderadamente") {
                      obj.response = "Moderately";
                    }

                    if (obj.response === "Mucho") {
                      obj.response = "Very Much";
                    }

                    if (obj.response === "Extremadamente") {
                      obj.response = "Extremely";
                    }

                    temp.push(obj);
                  }

                  // removed unused fields in final payload
                  temp = temp.map(v => {
                    delete v.selectEnabled;
                    delete v.symptomSelected;
                    return v;
                  });

                  for (let [key, obj] of Object.entries(temp)) {
                    obj.surveyQuestionId = this.props.questions[key].id;
                  }

                  this.props.submitTrackBehaviorAssessment(
                    temp,
                    this.props.history
                  );
                }}
                instructions={
                  <>
                    <h4>{i18n[lang].TrackBehaviorQ.InstructionTitle}</h4>
                    <p>{i18n[lang].TrackBehaviorQ.Instructions}</p>
                  </>
                }
              >
                <Wizard.Page>
                  {props => {
                    return (
                      <Row>
                        {this.props.questions_es.length > 0 &&
                          this.props.questions_es
                            .slice(0, 3)
                            .map((question, index) => (
                              <TrackBehaviorChoices
                                rank={0 + index + 1}
                                key={index}
                                {...question}
                                {...props}
                              />
                            ))}
                      </Row>
                    );
                  }}
                </Wizard.Page>
                <Wizard.Page>
                  {props => {
                    return (
                      <>
                        <Row>
                          {this.props.questions_es.length > 0 &&
                            this.props.questions_es
                              .slice(3, 6)
                              .map((question, index) => (
                                <TrackBehaviorChoices
                                  rank={3 + index + 1}
                                  key={index}
                                  {...question}
                                  {...props}
                                />
                              ))}
                        </Row>
                      </>
                    );
                  }}
                </Wizard.Page>
                <Wizard.Page>
                  {props => {
                    return (
                      <>
                        <Row>
                          {this.props.questions_es.length > 0 &&
                            this.props.questions_es
                              .slice(6, 9)
                              .map((question, index) => (
                                <TrackBehaviorChoices
                                  key={index}
                                  rank={6 + index + 1}
                                  {...question}
                                  {...props}
                                />
                              ))}
                        </Row>
                      </>
                    );
                  }}
                </Wizard.Page>
                <Wizard.Page>
                  {props => {
                    return (
                      <>
                        <Row>
                          {this.props.questions_es.length > 0 &&
                            this.props.questions_es
                              .slice(9, 12)
                              .map((question, index) => (
                                <TrackBehaviorChoices
                                  key={index}
                                  rank={9 + index + 1}
                                  {...question}
                                  {...props}
                                />
                              ))}
                        </Row>
                      </>
                    );
                  }}
                </Wizard.Page>
                <Wizard.Page>
                  {props => {
                    return (
                      <>
                        <Row>
                          {this.props.questions_es.length > 0 &&
                            this.props.questions_es
                              .slice(12, 15)
                              .map((question, index) => (
                                <TrackBehaviorChoices
                                  key={index}
                                  rank={12 + index + 1}
                                  {...question}
                                  {...props}
                                />
                              ))}
                        </Row>
                      </>
                    );
                  }}
                </Wizard.Page>
                <Wizard.Page>
                  {props => {
                    return (
                      <>
                        <Row>
                          {this.props.questions_es.length > 0 &&
                            this.props.questions_es
                              .slice(15, 18)
                              .map((question, index) => (
                                <TrackBehaviorChoices
                                  key={index}
                                  rank={15 + index + 1}
                                  {...question}
                                  {...props}
                                />
                              ))}
                        </Row>
                      </>
                    );
                  }}
                </Wizard.Page>
                <Wizard.Page>
                  {props => {
                    return (
                      <>
                        <Row>
                          {this.props.questions_es.length > 0 &&
                            this.props.questions_es
                              .slice(18, 21)
                              .map((question, index) => (
                                <TrackBehaviorChoices
                                  key={index}
                                  rank={18 + index + 1}
                                  {...question}
                                  {...props}
                                />
                              ))}
                        </Row>
                      </>
                    );
                  }}
                </Wizard.Page>
                <Wizard.Page>
                  {props => {
                    return (
                      <>
                        <Row>
                          {this.props.questions_es.length > 0 &&
                            this.props.questions_es
                              .slice(21, 24)
                              .map((question, index) => (
                                <TrackBehaviorChoices
                                  rank={21 + index + 1}
                                  key={index}
                                  {...question}
                                  {...props}
                                />
                              ))}
                        </Row>
                      </>
                    );
                  }}
                </Wizard.Page>
              </Wizard>
            )}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row type="flex" justify="center">
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <h5>Buscando Pruebas Tomadas</h5>
              <Spin size="large" />
            </div>
          </Col>
        </Row>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    questions: state.TrackBehavior.questions,
    questions_es: state.TrackBehavior.questions_es,
    assessmentInitialValues: state.TrackBehavior.assessmentInitialValues,
    assessmentInitialValues_es: state.TrackBehavior.assessmentInitialValues_es,
    loading: state.TrackBehavior.loading,
    message: state.TrackBehavior.message,
    i18n: state.LangReducer.i18n,
    lang: state.LangReducer.lang
  };
};

const mapDispatchToProps = {
  submitTrackBehaviorAssessment,
  fetchTrackBehaviorQuestions
};

TrackBehaviorAssessment_ES.propTypes = {
  i18n: object.isRequired,
  loading: bool.isRequired,
  lang: string.isRequired,
  history: object.isRequired,
  submitTrackBehaviorAssessment: func.isRequired,
  questions: array.isRequired,
  questions_es: array.isRequired,
  fetchTrackBehaviorQuestions: func.isRequired
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TrackBehaviorAssessment_ES)
);

const TrackBehaveQ = props => {
  const { sliceStart, sliceEnd, base } = props;

  if (props.lang === "en") {
    return (
      <>
        <Row>
          {props.questions.length > 0 &&
            props.questions
              .slice(sliceStart, sliceEnd)
              .map((question, index) => (
                <TrackBehaviorChoices
                  rank={base + index + 1}
                  key={index}
                  {...question}
                  {...props}
                />
              ))}
        </Row>
      </>
    );
  } else {
    return (
      <>
        <Row>
          {props.questions_es.length > 0 &&
            props.questions_es
              .slice(sliceStart, sliceEnd)
              .map((question, index) => (
                <TrackBehaviorChoices
                  rank={base + index + 1}
                  key={index}
                  {...question}
                  {...props}
                />
              ))}
        </Row>
      </>
    );
  }
};
