import request from "../../utils/apis/request";

/**
 * Makes a request to the surveys api for the questions
 * of the daily living assessment
 */
export const getSurvey = () => {
  return request({
    method: "get",
    url: "/api/surveys/daily-living"
  });
};

/**
 * Sends a payload of the DailyLiving assessment to the surveys api
 * @param {DailyLivingSurveyResponse} data
 */
export const postSurvey = data => {
  return request({
    method: "post",
    url: "/api/surveys/daily-living",
    data
  });
};

export const getDailyLivingSubmissions = userIdPayload => {
  if (userIdPayload) {
    return request({
      url: "/api/surveys/submissions/daily-living",
      method: "post",
      data: userIdPayload
    });
  } else {
    return request({
      url: "/api/surveys/submissions/daily-living",
      method: "post"
    });
  }
};
