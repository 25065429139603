import types from "./types";

/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const submitPositveAspects = score => ({
  type: types.POSTIVE_ASPECTS_SUBMIT,
  payload: {
    score: score,
    showResponse: true
  }
});

export const viewPositiveAspects = () => ({
  type: types.POSTIVE_ASPECTS_VIEW,
  payload: {
    showResponse: false,
    score: 0
  }
});

export const getPositiveAspect = () => ({
  type: types.POSITIVE_ASPECTS_GET_QUESTIONS,
  payload: {
    message: "Fetching survey",
    loading: true
  }
});

export const PositiveAspectFail = message => ({
  type: types.POSTIVE_ASPECTS_FAIL,
  payload: {
    message: message,
    loading: false
  }
});

export const setPositveAspectQuestions = data => ({
  type: types.POSITIVE_ASPECTS_SET_QUESTIONS,
  payload: {
    questions: data,
    loading: false,
    message: "Fethicng survey success"
  }
});

export const viewPositiveAspectsResults = () => ({
  type: types.POSTIVE_ASPECTS_VIEW_RESULTS,
  payload: {}
});

export const routeChangePositveAspects = () => ({
  type: types.POSTIVE_ASPECTS_ROUTE_CHANGE
});

export const PositiveAspectsSuccessAction = (score, message) => ({
  type: types.POSTIVE_ASPECTS_SUCCESS,
  payload: {
    loading: false,
    message,
    score,
    showResponse: true
  }
});

export const GetPaocSubmissionsAction = message => ({
  type: types.POSITIVE_ASPECTS_GET_PAST_SUBMISSIONS,
  payload: {
    loading: true,
    message
  }
});

export const SetPaocSubmissionsAction = (data, message) => ({
  type: types.POSITIVE_ASPECTS_SET_PAST_SUBMISSIONS,
  payload: {
    submissions: data,
    message,
    loading: false
  }
});
