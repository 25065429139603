import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import {
  submitDailyLivingAssessment,
  fetchDailyLivingAssessment
} from "../../modules/dailyLiving/operations";
import Wizard from "../../components/Wizard/AssessmentWizard";
import { withRouter } from "react-router-dom";
import CustomRadioGroup from "../../components/CustomRadioGroup/index";
import { func, array, string, object } from "prop-types";

class DailyLivingAssessment extends React.Component {
  state = {
    fetching: true
  };
  componentDidMount() {
    this.props.fetchDailyLivingAssessment().then(() => {
      this.setState({ fetching: false });
    });
  }

  force = () => {
    this.forceUpdate();
  };

  render() {
    const { i18n, lang } = this.props;

    return (
      <Row>
        <Col span={24}>
          {!this.state.fetching ? (
            <Wizard
              initialValues={{
                q1: { surveyQuestionId: -1, response: "" },
                q2: { surveyQuestionId: -1, response: "" },
                q3: { surveyQuestionId: -1, response: "" },
                q4: { surveyQuestionId: -1, response: "" },
                q5: { surveyQuestionId: -1, response: "" },
                q6: { surveyQuestionId: -1, response: "" },
                q7: { surveyQuestionId: -1, response: "" },
                q8: { surveyQuestionId: -1, response: "" },
                q9: { surveyQuestionId: -1, response: "" },
                q10: { surveyQuestionId: -1, response: "" },
                q11: { surveyQuestionId: -1, response: "" },
                q12: { surveyQuestionId: -1, response: "" },
                q13: { surveyQuestionId: -1, response: "" },
                q14: { surveyQuestionId: -1, response: "" },
                q15: { surveyQuestionId: -1, response: "" },
                q16: { surveyQuestionId: -1, response: "" },
                q17: { surveyQuestionId: -1, response: "" },
                q18: { surveyQuestionId: -1, response: "" },
                q19: { surveyQuestionId: -1, response: "" },
                q20: { surveyQuestionId: -1, response: "" }
              }}
              onSubmit={async (values, actions) => {
                const { questions } = this.props;

                let payload = [];

                // each response (obj) has a meta object
                // index : position of answer picked
                // position: the index of the question
                // decision: text of the decision picked
                // lang: language of the decision picked
                for (let [key, obj] of Object.entries(values)) {
                  // we are only sending the english responses to the backend, for research purposes
                  // so we need to replace the response that is
                  // in spanish with the corresponding english version and the surveyQuestionId
                  const { index, position, lang } = obj.meta;

                  if (lang === "spanish") {
                    obj.response = questions[position].decisions[index];
                    obj.surveyQuestionId = questions[position].id;
                  }

                  delete obj.meta;

                  payload.push(obj);
                }

                this.props.submitDailyLivingAssessment(
                  payload,
                  this.props.history
                );
              }}
              instructions={
                <>
                  <h4>{i18n[lang].ActivitiesQ.InstructionTitle}</h4>
                  <p>{i18n[lang].ActivitiesQ.Instructions}</p>
                </>
              }
            >
              <Wizard.Page
                validate={values => {
                  const errors = {};
                  if (values.q1.response === "") {
                    errors.q1 =
                      lang === "en"
                        ? "Question 1 Missing An Answer"
                        : "Le falta respuesta a la pregunta 1";
                  }
                  if (values.q2.response === "") {
                    errors.q2 =
                      lang === "en"
                        ? "Question 2 Missing An Answer"
                        : "Le falta respuesta a la pregunta 2";
                  }
                  if (values.q3.response === "") {
                    errors.q3 =
                      lang === "en"
                        ? "Question 3 Missing An Answer"
                        : "Le falta respuesta a la pregunta 3";
                  }
                  if (values.q4.response === "") {
                    errors.q4 =
                      lang === "en"
                        ? "Question 4 Missing An Answer"
                        : "Le falta respuesta a la pregunta 4";
                  }
                  return errors;
                }}
              >
                {props => {
                  return (
                    <Row lang={this.props.lang}>
                      {generateFormItems(
                        this.props.lang === "en"
                          ? this.props.questions.slice(0, 4)
                          : this.props.questions_es.slice(0, 4),
                        props,
                        0
                      )}
                    </Row>
                  );
                }}
              </Wizard.Page>
              <Wizard.Page
                validate={values => {
                  const errors = {};
                  if (values.q5.response === "") {
                    errors.q5 =
                      lang === "en"
                        ? "Question 5 Missing An Answer"
                        : "Le falta respuesta a la pregunta 5";
                  }
                  if (values.q6.response === "") {
                    errors.q6 =
                      lang === "en"
                        ? "Question 6 Missing An Answer"
                        : "Le falta respuesta a la pregunta 6";
                  }
                  if (values.q7.response === "") {
                    errors.q7 =
                      lang === "en"
                        ? "Question 7 Missing An Answer"
                        : "Le falta respuesta a la pregunta 7";
                  }
                  if (values.q8.response === "") {
                    errors.q8 =
                      lang === "en"
                        ? "Question 8 Missing An Answer"
                        : "Le falta respuesta a la pregunta 8";
                  }
                  if (values.q9.response === "") {
                    errors.q9 =
                      lang === "en"
                        ? "Question 9 Missing An Answer"
                        : "Le falta respuesta a la pregunta 9";
                  }
                  return errors;
                }}
              >
                {props => {
                  return (
                    <>
                      <Row lang={this.props.lang}>
                        {generateFormItems(
                          this.props.lang === "en"
                            ? this.props.questions.slice(4, 9)
                            : this.props.questions_es.slice(4, 9),
                          props,
                          4
                        )}
                      </Row>
                    </>
                  );
                }}
              </Wizard.Page>
              <Wizard.Page
                validate={values => {
                  const errors = {};
                  if (values.q10.response === "") {
                    errors.q10 =
                      lang === "en"
                        ? "Question 10 Missing An Answer"
                        : "Le falta respuesta a la pregunta 10";
                  }
                  if (values.q11.response === "") {
                    errors.q11 =
                      lang === "en"
                        ? "Question 11 Missing An Answer"
                        : "Le falta respuesta a la pregunta 11";
                  }
                  if (values.q12.response === "") {
                    errors.q12 =
                      lang === "en"
                        ? "Question 12 Missing An Answer"
                        : "Le falta respuesta a la pregunta 12";
                  }
                  if (values.q13.response === "") {
                    errors.q13 =
                      lang === "en"
                        ? "Question 13 Missing An Answer"
                        : "Le falta respuesta a la pregunta 13";
                  }

                  return errors;
                }}
              >
                {props => {
                  return (
                    <>
                      <Row lang={this.props.lang}>
                        {generateFormItems(
                          this.props.lang === "en"
                            ? this.props.questions.slice(9, 13)
                            : this.props.questions_es.slice(9, 13),
                          props,
                          9
                        )}
                      </Row>
                    </>
                  );
                }}
              </Wizard.Page>
              <Wizard.Page
                validate={values => {
                  const errors = {};
                  if (values.q14.response === "") {
                    errors.q14 =
                      lang === "en"
                        ? "Question 14 Missing An Answer"
                        : "Le falta respuesta a la pregunta 14";
                  }
                  if (values.q15.response === "") {
                    errors.q15 =
                      lang === "en"
                        ? "Question 15 Missing An Answer"
                        : "Le falta respuesta a la pregunta 15";
                  }
                  return errors;
                }}
              >
                {props => {
                  return (
                    <Row lang={this.props.lang}>
                      {generateFormItems(
                        this.props.lang === "en"
                          ? this.props.questions.slice(13, 15)
                          : this.props.questions_es.slice(13, 15),
                        props,
                        13
                      )}
                    </Row>
                  );
                }}
              </Wizard.Page>
              <Wizard.Page
                validate={values => {
                  const errors = {};
                  if (values.q16.response === "") {
                    errors.q16 =
                      lang === "en"
                        ? "Question 16 Missing An Answer"
                        : "Le falta respuesta a la pregunta 16";
                  }
                  if (values.q17.response === "") {
                    errors.q17 =
                      lang === "en"
                        ? "Question 17 Missing An Answer"
                        : "Le falta respuesta a la pregunta 17";
                  }
                  if (values.q18.response === "") {
                    errors.q18 =
                      lang === "en"
                        ? "Question 18 Missing An Answer"
                        : "Le falta respuesta a la pregunta 18";
                  }
                  if (values.q19.response === "") {
                    errors.q19 =
                      lang === "en"
                        ? "Question 19 Missing An Answer"
                        : "Le falta respuesta a la pregunta 19";
                  }
                  if (values.q20.response === "") {
                    errors.q20 =
                      lang === "en"
                        ? "Question 20 Missing An Answer"
                        : "Le falta respuesta a la pregunta 20";
                  }
                  return errors;
                }}
              >
                {props => {
                  return (
                    <>
                      <Row lang={this.props.lang}>
                        {generateFormItems(
                          this.props.lang === "en"
                            ? this.props.questions.slice(15)
                            : this.props.questions_es.slice(15),
                          props,
                          15
                        )}
                      </Row>
                    </>
                  );
                }}
              </Wizard.Page>
            </Wizard>
          ) : null}
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    questions: state.DailyLivingReducer.questions,
    questions_es: state.DailyLivingReducer.questions_es,
    fetchingQuestions: state.DailyLivingReducer.loadding,
    i18n: state.LangReducer.i18n,
    lang: state.LangReducer.lang
  };
};

const mapDispatchToProps = {
  submitDailyLivingAssessment,
  fetchDailyLivingAssessment
};

DailyLivingAssessment.propTypes = {
  fetchDailyLivingAssessment: func.isRequired,
  i18n: object.isRequired,
  lang: string.isRequired,
  submitDailyLivingAssessment: func.isRequired,
  history: object.isRequired,
  questions: array.isRequired,
  questions_es: array.isRequired
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DailyLivingAssessment)
);

const generateFormItems = (questions, props, base) => {
  if (questions) {
    return questions.map((question, index) => (
      <CustomRadioGroup
        key={index}
        rank={base + index + 1}
        {...question}
        {...props}
      />
    ));
  } else {
    return null;
  }
};
