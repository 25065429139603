import types from "./types";
import produce from "immer";
import Moment from "moment";

const defaultState = {
  score: 0,
  showResponse: false,
  questions: [],
  questions_es: [],
  loading: true,
  message: "",
  submissions: [
    {
      createdAt: "",
      id: -1,
      score: -1,
      question_submission: [
        {
          id: -1,
          response: "",
          text: ""
        }
      ]
    }
  ],
  submissionsLeftIndex: 0,
  submissionsRightIndex: 0
};

const phqReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.PHQ2_SUBMIT:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.PHQ2_SUCCESS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
        draft.showResponse = action.payload.showResponse;
        draft.score = action.payload.score;
      });
    case types.PHQ2_FAIL:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.PHQ2_GET_QUESTIONS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.PHQ2_SET_QUESIONS:
      return produce(state, draft => {
        // parse the questions and separate english from spanish
        draft.questions = action.payload.questions.filter(
          q => q.lang === "english"
        );
        draft.questions_es = action.payload.questions.filter(
          q => q.lang === "spanish"
        );
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.PHQ2_GET_PAST_SUBMISSIONS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.PHQ2_SET_PAST_SUBMISSIONS:
      return produce(state, draft => {
        let submissions = [];

        /**
         * Parse through the submissions received from the API
         * and filter out boilerplate data
         */
        action.payload.submissions.forEach(submission => {
          let submissionDraft = {
            createdAt: Moment(submission.createdAt),
            id: submission.id,
            score: submission.score.totalScore,
            question_submission: []
          };

          submission.survey_question_submissions.forEach(question => {
            submissionDraft.question_submission.push({
              id: question.id,
              response: question.response,
              response_es: question.response_translation,
              text: question.survey_question.text,
              text_es: question.survey_question.text_es
            });
          });

          submissions.push(submissionDraft);
        });

        draft.submissions = submissions;

        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    default:
      return state;
  }
};

export default phqReducer;
