import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  submitZaritAssessment,
  getZaritAssessment
} from "../../modules/zaritAssessment/operations";
import Wizard from "../../components/Wizard/AssessmentWizard";
import { withRouter } from "react-router-dom";
import CustomRadioGroup from "../../components/CustomRadioGroup/index";
import { array, object, string, func, bool } from "prop-types";
/**
 * Positive Aspects of Caregiving
 */
class ZaritAssessment extends React.PureComponent {
  componentDidMount() {
    this.props.getZaritAssessment();
  }
  render() {
    const { i18n, lang } = this.props;

    return (
      <Row>
        <Col span={24}>
          <Wizard
            initialValues={{
              q1: { surveyQuestionId: -1, response: "" },
              q2: { surveyQuestionId: -1, response: "" },
              q3: { surveyQuestionId: -1, response: "" },
              q4: { surveyQuestionId: -1, response: "" },
              q5: { surveyQuestionId: -1, response: "" },
              q6: { surveyQuestionId: -1, response: "" }
            }}
            onSubmit={async (values, actions) => {
              const payload = [];
              const { questions } = this.props;

              // each response (obj) has a meta object
              // index : position of answer picked
              // position: the index of the question
              // decision: text of the decision picked
              // lang: language of the decision picked
              for (let [key, obj] of Object.entries(values)) {
                // we are only sending the english responses to the backend, for research purposes
                // so we need to replace the response that is
                // in spanish with the corresponding english version and the surveyQuestionId
                const { index, position, lang } = obj.meta;

                if (lang === "spanish") {
                  obj.response = questions[position].decisions[index];
                  obj.surveyQuestionId = questions[position].id;
                }

                delete obj.meta;
                payload.push(obj);
              }
              this.props.submitZaritAssessment(payload, this.props.history);
            }}
            instructions={
              <>
                <h4>{i18n[lang].OverwhelmedQ.InstructionTitle}</h4>
                <p>{i18n[lang].OverwhelmedQ.Instructions}</p>
              </>
            }
          >
            {/* Page 1 */}
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q1.response === "") {
                  errors.q1 =
                    lang === "en"
                      ? "Question 1 Missing An Answer"
                      : "Le falta respuesta a la pregunta 1";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={this.props.lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    rank={1}
                    index={0}
                    {...props}
                  />
                );
              }}
            </Wizard.Page>

            {/* Page 2 */}
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q2.response === "") {
                  errors.q2 =
                    lang === "en"
                      ? "Question 2 Missing An Answer"
                      : "Le falta respuesta a la pregunta 2";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={this.props.lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    rank={2}
                    index={1}
                    {...props}
                  />
                );
              }}
            </Wizard.Page>

            {/* Page 3 */}
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q3.response === "") {
                  errors.q3 =
                    lang === "en"
                      ? "Question 3 Missing An Answer"
                      : "Le falta respuesta a la pregunta 3";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={this.props.lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    rank={3}
                    index={2}
                    {...props}
                  />
                );
              }}
            </Wizard.Page>

            {/* Page 4 */}
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q4.response === "") {
                  errors.q4 =
                    lang === "en"
                      ? "Question 4 Missing An Answer"
                      : "Le falta respuesta a la pregunta 4";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={this.props.lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    rank={4}
                    index={3}
                    {...props}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q5.response === "") {
                  errors.q5 =
                    lang === "en"
                      ? "Question 5 Missing An Answer"
                      : "Le falta respuesta a la pregunta 5";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={this.props.lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    rank={5}
                    index={4}
                    {...props}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q6.response === "") {
                  errors.q6 =
                    lang === "en"
                      ? "Question 6 Missing An Answer"
                      : "Le falta respuesta a la pregunta 6";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={this.props.lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    rank={6}
                    index={5}
                    {...props}
                  />
                );
              }}
            </Wizard.Page>
          </Wizard>
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  questions: state.zarit.questions,
  questions_es: state.zarit.questions_es,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang,
  loading: state.zarit.loading
});

const mapDispatchToProps = dispatch => {
  return Object.assign(
    { dispatch },
    bindActionCreators({ submitZaritAssessment, getZaritAssessment }, dispatch)
  );
};

ZaritAssessment.propTypes = {
  questions: array.isRequired,
  questions_es: array.isRequired,
  i18n: object.isRequired,
  lang: string.isRequired,
  history: object.isRequired,
  submitZaritAssessment: func.isRequired,
  getZaritAssessment: func.isRequired,
  loading: bool.isRequired
};

export default withRouter(
  connect(
    mapState,
    mapDispatchToProps
  )(ZaritAssessment)
);

const Question = props => {
  if (props.lang === "en") {
    return (
      <Row>
        {!props.loading && props.questions.length > 1 && (
          <CustomRadioGroup
            {...props.questions[props.index]}
            {...props}
            lang="english"
            patient={props.patient}
            rank={props.rank}
          />
        )}
      </Row>
    );
  } else {
    return (
      <Row>
        {!props.loading && props.questions_es.length > 1 && (
          <CustomRadioGroup
            {...props.questions_es[props.index]}
            {...props}
            lang="spanish"
            patient={props.patient}
            rank={props.rank}
          />
        )}
      </Row>
    );
  }
};
