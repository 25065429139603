import React from "react";
import { Row, Col, Button } from "antd";
import CustomButton from "../../../../components/CustomButton/CustomButton";

const ButtonGroup = Button.Group;

type Props = {
  handleRegister: () => void;
  handleEditPatient: () => void;
  editModeEnabled: () => void;
  handleAddProvider: () => void;
};

export const AccountControl = ({
  handleRegister,
  handleEditPatient,
  editModeEnabled,
  handleAddProvider,
}: Props) => {
  return (
    <Row>
      <Row type="flex" justify="center">
        <h5>Patient & Provider Controls </h5>
      </Row>
      <Row type="flex" gutter={8}>
        <div>
          <CustomButton
            block
            onClick={handleRegister}
            style={{ margin: "0 0 8px 0" }}
          >
            Register Patient
          </CustomButton>

          <CustomButton
            block
            onClick={handleAddProvider}
            style={{ margin: "0 0 8px 0" }}
          >
            Register Provider
          </CustomButton>

          <CustomButton
            block
            onClick={handleEditPatient}
            style={{ margin: "0 0 8px 0" }}
          >
            {editModeEnabled ? "Stop Editing" : "Toggle Edit Mode"}
          </CustomButton>
        </div>
      </Row>
    </Row>
  );
};
