import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

class ZaritInstructions extends React.Component {
  render() {
    const { i18n, lang } = this.props;
    return (
      <Row>
        <Col span={24}>
          <h2>{i18n[lang].OverwhelmedQ.Title}</h2>
          <h2>{i18n[lang].OverwhelmedQ.Subtitle}</h2>
          <h3>{i18n[lang].Assessments.LanguageInstructions}</h3>
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  lang: state.LangReducer.lang,
  i18n: state.LangReducer.i18n
});

export default connect(mapState)(ZaritInstructions);
