import React from "react";
import { Row, Col } from "antd";
import { submitPrescriptionTodo } from "../../../../../modules/todo-prescription/operations";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

const Instructions = styled.div`
  margin-top: 0.5rem;
`;

const MetaSpan = styled.span`
  padding-right: 0.3rem;
`;

const About = styled.div`
  font-style: italic;
`;

/**
 * Component responsible for displaying the task.
 *
 * Redirecting the user to the quiz assessments if that is the task
 *
 * Redirecting the user to the Links page if that is the task
 *
 * Redirecting the user to the Videos page if that is the task
 */
const TaskDescription = props => {
  const lang = useSelector(state => state.LangReducer.lang);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleComplete = () => {
    const { url, type, id } = props;

    if (type === "SURVEY") {
      history.push(url);
    } else {
      dispatch(submitPrescriptionTodo(id, type));
    }
  };

  const { description, instructions } = props;
  return (
    <Row>
      <Col xs={24} sm={16}>
        {description && (
          <About>
            <MetaSpan>{lang === "en" ? "About" : "Acerca"}:</MetaSpan>
            {description}
          </About>
        )}

        {/* <Instructions>
          {lang === "en"
            ? "Additional Instructions:"
            : "Instrucciones Adicionales:"}{" "}
          <span>{instructions}</span>
        </Instructions> */}
      </Col>
      <Col xs={24} sm={8}>
        <Row type="flex" justify="center">
          <CustomButton onClick={handleComplete}>
            {lang === "en" ? "Complete" : "Completar"}
          </CustomButton>
        </Row>
      </Col>
    </Row>
  );
};

export default TaskDescription;
