import React from "react";
import { Row, Col } from "antd";
import Chatbot from "../components/chatbot/Chatbot";
import { useSelector } from "react-redux";
import ChatbotGuide from "components/chatbot/ChatbotGuide";

/**
 * Contact Us Route
 */
const ContactUs = () => {
  // grab the translation
  const lang = useSelector(state => state.LangReducer.lang);

  return (
    <>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Chatbot></Chatbot>
        </Col>
        <Col>
          <p>
            {lang === "en"
              ? `The Dementia Caregiver: A Guide to Caring for Someone with Alzheimer's Disease and Other Neurocognitive Disorders (Guides to Caregiving)" authored by Marc E. Agronin, MD. The book can be purchased at`
              : 'El cuidador de demencia: una guía para cuidar a alguien con Enfermedad de Alzheimer y otros trastornos neurocognitivos (guías para Cuidado) "escrito por Marc E. Agronin, MD. El libro puede ser comprado en'}{" "}
            <a
              href="https://www.amazon.com/Dementia-Caregiver-Alzheimers-Neurocognitive-Caregiving-ebook/dp/B0162I5LLG"
              target="_blank"
              rel="noopener noreferrer"
              style={{}}
            >
              https://www.amazon.com/Dementia-Caregiver-Alzheimers-Neurocognitive-Caregiving-ebook/dp/B0162I5LLG
            </a>{" "}
          </p>
          <p>
            {lang === "en"
              ? `Only minor edits were made in order to fit the format and audience.`
              : `Solo se hicieron ediciones menores para ajustarse al formato y al público.`}
          </p>
          <a
            href="https://sway.office.com/m4lDRMxeF2x8dCHT?ref=Link&loc=play"
            target="_blank"
            rel="noopener noreferrer"
          >
            {lang === "en"
              ? `Instructions for using the chabot. Click here.`
              : `Instrucciones para utilizar el Chatbot`}
          </a>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={24} md={18}>
          {/* <iframe
            width="760px"
            height="500px"
            src="https://sway.office.com/s/m4lDRMxeF2x8dCHT/embed"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            max-width="100%"
            sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-same-origin allow-scripts"
            scrolling="no"
            style={{ border: "none; max-width: 100%; max-height: 100vh" }}
            allowFullScreen
          ></iframe> */}
          {/* <ChatbotGuide /> */}
        </Col>
      </Row>
    </>
  );
};

export default ContactUs;
