import React from "react";
import { Button } from "antd";
import styled from "styled-components";

const BlueStyledButton = styled(Button)`
  background-color: rgba(9, 31, 63, 0.9);
  color: #ffffff;
  border-color: rgba(9, 31, 63, 0.8);

  &:hover {
    background-color: rgba(19, 41, 73, 1);
  }

  &:focus {
    background-color: rgba(248, 201, 62, 1);
    color: #081e3f;
  }

  &:active {
    background-color: rgba(248, 201, 62, 1);
    color: #081e3f;
  }

  .ant-btn-primary:focus {
    background-color: rgba(9, 31, 63, 1);
  }
`;

const CustomButton = props => {
  const { children } = props;
  return (
    <BlueStyledButton {...props} style={props.style}>
      {children}
    </BlueStyledButton>
  );
};

export default CustomButton;
