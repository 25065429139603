import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Avatar, Button } from "antd";
import defaultAvartSvg from "../../assets/img/avatars/avatar.svg";
import styled from "styled-components";
import { EditCaregiverProfile } from "./dashboard/caregiverprofile/EditCaregiverProfile";

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .detail {
    font-weight: 600;
    margin-top: 15px;

    span {
      font-weight: 400;
    }
  }
`;

export const CaregiverProfile = () => {
  const user = useSelector(state => state.User);
  const lang = useSelector(state => state.LangReducer.lang);
  const [showEditForm, toggleEditForm] = useState(false);

  if (showEditForm === false) {
    return (
      <ProfileContainer>
        <h2>{lang === "en" ? "Caregiver" : "Cuidador"}</h2>
        <Avatar
          size={164}
          src={user.picture !== "" ? user.picture : defaultAvartSvg}
        />
        <div className="detail">
          <p>
            {lang === "en" ? "First Name" : "Primer Nombre"}:{" "}
            <span>{user.firstname}</span>
          </p>
          <p>
            {lang === "en" ? "Last Name" : "Apellido"}:{" "}
            <span>{user.lastname}</span>
          </p>
        </div>
        <div>
          <Button onClick={() => toggleEditForm(true)}>
            {lang === "en" ? "Edit Profile" : "Cambiar Perfil"}
          </Button>
        </div>
      </ProfileContainer>
    );
  } else {
    return <EditCaregiverProfile toggleEditForm={toggleEditForm} />;
  }
};
