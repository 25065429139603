import {
  viewPhq2,
  routeChangePhq2,
  submitPhq2,
  getPhq2Questions,
  setPhq2Questions,
  phq2Fail,
  submitPhq2Success,
  GetPhq2SubmissionsAction,
  SetPhq2SubmissionsAction
} from "./actions";
import { getPhq2Survey, postPhq2Survey, getPhq2Submissions } from "./utils";

/**
 * To represetn chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

/**
 * Makes a POST request to the backend api to send
 * the resulting score and frequency picked for each question
 * TODO: implement the backend api call
 * @param {Number} q1 question one frequency
 * @param {Number} q2 question two frequency
 * @param {Number} score the sum of question one, and question two
 */
export const submitPhq2Assessment = (payload, history) => {
  return dispatch => {
    dispatch(submitPhq2());

    return postPhq2Survey(payload)
      .then(response => {
        dispatch(
          submitPhq2Success(
            "Succesfully submitted survey",
            response.data.score.totalScore
          )
        );
        // redirect to the response route
        history.push("/phq/response");
      })
      .catch(err => {
        dispatch(phq2Fail("Failed to submit the survey"));
      });
  };
};

export const getPhq2SurveyQuestions = () => {
  return dispatch => {
    dispatch(getPhq2Questions("Fetching PHQ2"));

    return getPhq2Survey()
      .then(response => {
        return dispatch(setPhq2Questions(response.data, response.message));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const GetPhqSubmissions = () => {
  return dispatch => {
    dispatch(GetPhq2SubmissionsAction("Retrieving past submissions"));
    return getPhq2Submissions()
      .then(response => {
        dispatch(
          SetPhq2SubmissionsAction(response.data, "Success Fetch Submissions")
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(phq2Fail(err));
      });
  };
};

export default {
  viewPhq2,
  routeChangePhq2,
  submitPhq2Assessment
};
