import {
  submitDailyLiving,
  submitDailyLivingFail,
  submitDailyLivingSuccess,
  viewDailyLiving,
  viewDailyLivingResults,
  routeChangeDailyLiving,
  getDailyLivingAssessment,
  GetDailyLivingSubmissionsAction,
  SetDailyLivingSubmissionsAction
} from "./actions";
import { getSurvey, postSurvey, getDailyLivingSubmissions } from "./utils";

/**
 * To represetn chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

export const submitDailyLivingAssessment = (assessment, history) => {
  return dispatch => {
    // set loading state to true
    dispatch(submitDailyLiving);

    return postSurvey(assessment)
      .then(response => {
        const { data, message } = response;

        // the API responds with a 201!
        // update the state...
        dispatch(submitDailyLivingSuccess(message));
        history.push("/activities-daily-living/response");
      })
      .catch(error => {
        const { message } = error;
        dispatch(submitDailyLivingFail(message));
      });
    // dispatch(submitDailyLiving(score));

    // redirect to the response route
    // dispatch(push("/activities-daily-living/response"));
  };
};

export const fetchDailyLivingAssessment = () => {
  return dispatch => {
    return getSurvey()
      .then(response => {
        const { data, message } = response;
        return dispatch(getDailyLivingAssessment(data, message));
      })
      .catch(err => {
        console.log(err);
      });
  };
};

export const GetPastDailyLivingSubmissions = userIdPayload => {
  return dispatch => {
    dispatch(GetDailyLivingSubmissionsAction("Retrieving past submissions"));
    return getDailyLivingSubmissions(userIdPayload)
      .then(response => {
        dispatch(
          SetDailyLivingSubmissionsAction(
            response.data,
            "Success fetch submissions"
          )
        );
      })
      .catch(err => {
        console.log(err);
        // TODO: implement on error dispatch
      });
  };
};

export default {
  viewDailyLivingResults,
  viewDailyLiving,
  routeChangeDailyLiving
};
