export const englishResources = [
  /**
   * Id 0 through 4 are under
   * Do you need tips to take care of yourself?
   */
  {
    id: 0,
    title: "Tips for Caregiver Self Care",
    url:
      "http://www.aarp.org/home-family/caregiving/info-2014/caregiving-find-time-for-exercise.html",
    type: "RESOURCE",
    body: "AARP’s Tips on remaining active when you are a caregiver."
  },
  {
    id: 1,
    title: "Tips for Caregiver Self Care",
    url: "http://www.alz.org/care/alzheimers-dementia-healthy-caregiver.asp",
    type: "RESOURCE",
    body: "Alzheimer’s Association tips on being a healthy caregiver."
  },
  {
    id: 2,
    title: "Tips for Caregiver Self Care",
    url:
      "http://www.alz.org/care/alzheimers-dementia-caregiver-stress-burnout.asp",
    type: "RESOURCE",
    body: "Alzheimer’s Association tips on managing caregiver stress."
  },
  {
    id: 3,
    title: "Tips for Caregiver Self Care",
    url: "https://alzfdn.org/caregiving-resources/",
    type: "RESOURCE",
    body: "Alzheimer’s Foundation of America’s Caregiving Tips."
  },
  {
    id: 4,
    title: "Tips for Caregiver Self Care",
    url: "https://www.choosemyplate.gov/eathealthy/WhatIsMyPlate",
    type: "RESOURCE",
    body: "USDA’s Tips on Eating Healthy."
  },
  /**
   * ID 5 - 10 are under the section title
   * Do you need tips on understand Alzheimer's disease or other types of dementia?
   */
  {
    id: 5,
    title: "Understanding Alzheimer's Diseases and Other Types of Dementia",
    url: "http://www.alz.org/care/alzheimers-dementia-stages-behaviors.asp",
    type: "RESOURCE",
    body:
      "Alzheimer's Association resource for understanding stages and behaviors."
  },
  {
    id: 6,
    title: "Understanding Alzheimer's Diseases and Other Types of Dementia",
    url: "https://www.nia.nih.gov/health/tips-coping-sundowning",
    type: "RESOURCE",
    body:
      "National Instititute on Aging's information and tips about sundowning."
  },
  {
    id: 7,
    title: "Understanding Alzheimer's Diseases and Other Types of Dementia",
    url:
      "https://order.nia.nih.gov/sites/default/files/2018-03/alzheimers-disease-medications-fact-sheet.pdf",
    type: "RESOURCE",
    body:
      "National Instititute on Aging's Fact Sheet on Alzheimer's Disease Medication."
  },
  {
    id: 8,
    title: "Understanding Alzheimer's Diseases and Other Types of Dementia",
    url:
      "https://www.mayoclinic.org/diseases-conditions/frontotemporal-dementia/symptoms-causes/syc-20354737",
    type: "RESOURCE",
    body: "The Mayo Clinic's Information on Frontotemporal Dementia."
  },
  {
    id: 9,
    title: "Understanding Alzheimer's Diseases and Other Types of Dementia",
    url:
      "https://www.mayoclinic.org/diseases-conditions/lewy-body-dementia/symptoms-causes/syc-20352025",
    type: "RESOURCE",
    body: "The Mayo Clinic's Information on Lewy Body Dementia."
  },
  {
    id: 10,
    title: "Understanding Alzheimer's Diseases and Other Types of Dementia",
    url:
      "https://www.mayoclinic.org/diseases-conditions/vascular-dementia/symptoms-causes/syc-20378793",
    type: "RESOURCE",
    body: "The Mayo Clinic's Information on Vascular Dementia."
  },
  /**
   * ID 11-14 are under the section
   * Do you need tips on Legal or financial issues for a person
   * with dementia?
   */
  {
    id: 11,
    title:
      "Tips on Legal and Financial Issues for Someone with Alzheimer's Disease and Other Types of Dementia",
    url:
      "https://order.nia.nih.gov/sites/default/files/2017-12/legal-and-financial-planning-for-people-with-alzheimers-disease.pdf",
    type: "RESOURCE",
    body:
      "National Institute on Aging's guide on Legal and Financial Planning for People with Alzheimer's Diseases."
  },
  {
    id: 12,
    title:
      "Tips on Legal and Financial Issues for Someone with Alzheimer's Disease and Other Types of Dementia",
    url: "http://www.alz.org/national/documents/brochure_moneymatters.pdf",
    type: "RESOURCE",
    body: "Alzheimer's Association guide Money Matters."
  },
  {
    id: 13,
    title:
      "Tips on Legal and Financial Issues for Someone with Alzheimer's Disease and Other Types of Dementia",
    url: "http://www.alz.org/documents/national/brochure_legalplans.pdf",
    type: "RESOURCE",
    body: "Alzheimer's Association guide Legal Plans."
  },
  {
    id: 14,
    title:
      "Tips on Legal and Financial Issues for Someone with Alzheimer's Disease and Other Types of Dementia",
    url:
      "https://www.alz.org/help-support/caregiving/financial-legal-planning/tax-deductions-credits",
    type: "RESOURCE",
    body: "Alzheimer's Association guide to state and federal tax deductions."
  },
  /**
   * 15-17
   * Caregiver Assessments
   */
  {
    id: 15,
    body: "Feeling Sad or Blue?",
    url: "/phq",
    alias: "PHQ-2 (Caregiver self-assessment)",
    shouldRedirect: true,
    type: "SURVEY",
    title: "Caregiver Self-Assessment"
  },
  {
    id: 16,
    body: "Feeling overwhelmed?",
    url: "/zarit",
    alias: "Zarit Burden Scale (Caregiver self-assessment)",
    shouldRedirect: true,
    type: "SURVEY",
    title: "Caregiver Self-Assessment"
  },
  {
    id: 17,
    body: "Take the Caregiving Test To See How You Are?",
    url: "/paoc",
    alias: "Positive Aspects of Caregiving (Caregiver self-assessment)",
    shouldRedirect: true,
    type: "SURVEY",
    title: "Caregiver Self-Assessment"
  },
  /**
   * 18-19
   * Patient Assessments
   */
  {
    id: 18,
    body: "Activities of Daily Living.",
    url: "/activities-daily-living",
    alias: "Activities of Daily Living (Caregiver assesses patient)",
    shouldRedirect: true,
    type: "SURVEY",
    title: "Caregiver Assesses Patient"
  },
  {
    id: 19,
    body: "Keeping Track of Behavior.",
    url: "/tracking-behavioral-changes",
    alias: "Revised Memory and Behavior Checklist (Caregiver assesses patient)",
    shouldRedirect: true,
    type: "SURVEY",
    title: "Caregiver Assesses Patient"
  },
  /**
   * Other Resources
   */
  {
    id: 20,
    body: "Use the Chatbot to ask questions about dementia.",
    url: "/contact",
    shouldRedirect: true,
    type: "CHATBOT",
    title: "Chatbot"
  },
  {
    id: 21,
    body: "Enter updates to patient weight.",
    url: "/dashboard",
    shouldRedirect: false,
    type: "OTHER",
    title: "Track Weight"
  },
  {
    id: 22,
    body:
      "Use the Refrigerator Door to add reminders and questions you want to ask the provider at your next visit.",
    url: "/dashboard",
    shouldRedirect: false,
    type: "OTHER",
    title: "Refrigerator Door"
  },
  {
    id: 23,
    body: "Watch the Caregiver Video on taking care of yourself.",
    url: "/Videos",
    shouldRedirect: true,
    type: "VIDEO",
    title: "Video"
  },
  {
    id: 24,
    body:
      "Watch the Caregiver Video on preparing for a medical visit with someone with dementia.",
    url: "/Videos",
    shouldRedirect: true,
    title: "Video",
    type: "VIDEO"
  }
];

export const spanishResources = [
  /**
   * Id 0 through 4 are under
   * Do you need tips to take care of yourself?
   */
  {
    id: 0,
    title: "Cuidado de ti",
    url:
      "https://www.aarp.org/espanol/recursos-para-el-cuidado/cuidar-cuida/info-2016/tiempo-hacer-ejercicio-rutina.html?intcmp=AE-CRC-TOSPA-TOGL-ES",
    type: "RESOURCE",
    body: "Consejos de AARP: Como mantenerse agil meintras cuidas."
  },
  {
    id: 1,
    title: "Cuidado de ti",
    url:
      "https://www.alz.org/l/ayuda-y-apoyo/cuidado/cuidadores-saludables?lang=es-MX",
    type: "RESOURCE",
    body: "Alzheimer’s Association consejos para ser un cuidador saludable."
  },
  {
    id: 2,
    title: "Cuidado de ti",
    url:
      "https://www.alz.org/help-support/caregiving/caregiver-health/caregiver-stress?lang=es-MX",
    type: "RESOURCE",
    body:
      "Alzheimer’s Association consejos para controlar el estrés en los cuidadores."
  },
  {
    id: 3,
    title: "Cuidado de ti",
    url: "http://www.alzfdn.org/EducationandCare/strategiesforsuccess.html",
    type: "RESOURCE",
    body: "Alzheimer’s Foundation of America’s: Recursos de Cuidado."
  },
  {
    id: 4,
    title: "Cuidado de ti",
    url:
      "https://www.choosemyplate.gov/browse-by-audience/view-all-audiences/multiple-languages/multilanguage-spanish",
    type: "RESOURCE",
    body: "Consejos para comer mas saludable."
  },
  /**
   * ID 5 - 10 are under the section title
   * Do you need tips on understand Alzheimer's disease or other types of dementia?
   */
  {
    id: 5,
    title: "Comprender las Enfermdades de Alzhiemer y Otros Tipos de Demencia",
    url: "https://www.alz.org/ayuda-y-apoyo/cuidado/comportamientos?lang=es-MX",
    type: "RESOURCE",
    body:
      "Alzheimer's Association: recursos para comprender diferentes comportamientos."
  },
  {
    id: 6,
    title: "Comprender las Enfermdades de Alzhiemer y Otros Tipos de Demencia",
    url:
      "https://www.mayoclinic.org/es-es/diseases-conditions/alzheimers-disease/expert-answers/sundowning/faq-20058511",
    type: "RESOURCE",
    body:
      "Mayo Clinic: Síndrome Vespertino: Desorientación en las últimas horas de la tarde."
  },
  {
    id: 7,
    title: "Comprender las Enfermdades de Alzhiemer y Otros Tipos de Demencia",
    url: "https://www.nia.nih.gov/health/medicamentos-enfermedad-alzheimer",
    type: "RESOURCE",
    body: "National Instititute: Medicamentos para la enfermedad de Alzheimer."
  },
  {
    id: 8,
    title: "Comprender las Enfermdades de Alzhiemer y Otros Tipos de Demencia",
    url:
      "https://www.mayoclinic.org/es-es/diseases-conditions/frontotemporal-dementia/symptoms-causes/syc-20354737",
    type: "RESOURCE",
    body: "Mayo Clinic: información de Demencia Frontotemporal."
  },
  {
    id: 9,
    title: "Comprender las Enfermdades de Alzhiemer y Otros Tipos de Demencia",
    url:
      "https://www.mayoclinic.org/es-es/diseases-conditions/lewy-body-dementia/symptoms-causes/syc-20352025",
    type: "RESOURCE",
    body: "Mayo Clinic: información de Demencia Con Cuerpos de Lewy."
  },
  {
    id: 10,
    title: "Comprender las Enfermdades de Alzhiemer y Otros Tipos de Demencia",
    url:
      "https://www.mayoclinic.org/es-es/diseases-conditions/vascular-dementia/symptoms-causes/syc-20378793",
    type: "RESOURCE",
    body: "Mayo Clinic: información de Demencia Vascular."
  },
  /**
   * ID 11-14 are under the section
   * Do you need tips on Legal or financial issues for a person
   * with dementia?
   */
  {
    id: 11,
    body:
      "Guía del Instituto Nacional del Envejecimiento sobre planificación legal y financiera para personas con enfermedad de Alzheimer.",
    url:
      "https://order.nia.nih.gov/sites/default/files/2017-12/legal-and-financial-planning-for-people-with-alzheimers-disease.pdf",
    type: "RESOURCE",
    title: "Consejos para problemas legales o financieros",
    oldbody:
      "National Institute on Aging's guide on Legal and Financial Planning for People with Alzheimer's Diseases."
  },
  {
    id: 12,
    body: "Guía de la Asociación de Alzheimer Money Matters.",
    url: "http://www.alz.org/national/documents/brochure_moneymatters.pdf",
    type: "RESOURCE",
    title: "Consejos para problemas legales o financieros",
    oldbody: "Alzheimer's Association guide Money Matters."
  },
  {
    id: 13,
    body: "uía de la Asociación de Alzheimer Planes Legales.",
    url: "http://www.alz.org/documents/national/brochure_legalplans.pdf",
    type: "RESOURCE",
    title: "Consejos para problemas legales o financieros",
    oldbody: "Alzheimer's Association guide Legal Plans."
  },
  {
    id: 14,
    body:
      "Guía de la Asociación de Alzheimer para las deducciones de impuestos estatales y federales.",
    url:
      "https://www.alz.org/help-support/caregiving/financial-legal-planning/tax-deductions-credits",
    type: "RESOURCE",
    title: "Consejos para problemas legales o financieros",
    oldbody:
      "Alzheimer's Association guide to state and federal tax deductions."
  },
  /**
   * 15-17
   * Caregiver Assessments
   */
  {
    id: 15,
    bodyEn: "Feeling Sad or Blue?",
    body: "¿Te sientes Triste o Deprimido?",
    url: "/phq",
    alias: "PHQ-2 (Caregiver self-assessment)",
    shouldRedirect: true,
    type: "SURVEY",
    titleEn: "Caregiver Self-Assessment",
    title: "Autoevaluación del Cuidador"
  },
  {
    id: 16,
    bodyEn: "Feeling overwhelmed?",
    body: "¿Te sientes abrumado?",
    url: "/zarit",
    alias: "Zarit Burden Scale (Caregiver self-assessment)",
    shouldRedirect: true,
    type: "SURVEY",
    titleEn: "Caregiver Self-Assessment",
    title: "Autoevaluación del Cuidador"
  },
  {
    id: 17,
    bodyEn: "Take the Caregiving Test To See How You Are?",
    body: "¿Usted se pregunta que tan bien lo esta haciendo como Cuidador?",
    url: "/paoc",
    alias: "Positive Aspects of Caregiving (Caregiver self-assessment)",
    shouldRedirect: true,
    type: "SURVEY",
    titleEn: "Caregiver Self-Assessment",
    title: "Autoevaluación del Cuidador"
  },
  /**
   * 18-19
   * Patient Assessments
   */
  {
    id: 18,
    bodyEn: "Activities of Daily Living.",
    body: "Actividades del Diario Vivir",
    url: "/activities-daily-living",
    alias: "Activities of Daily Living (Caregiver assesses patient)",
    shouldRedirect: true,
    type: "SURVEY",
    title: "Caregiver Assesses Patient"
  },
  {
    id: 19,
    bodyEn: "Keeping Track of Behavior.",
    body: "Seguimientos de Cambios de Comportamiento",
    url: "/tracking-behavioral-changes",
    alias: "Revised Memory and Behavior Checklist (Caregiver assesses patient)",
    shouldRedirect: true,
    type: "SURVEY",
    titleEn: "Caregiver Assesses Patient",
    title: "El cuidador(a) evalúa al paciente"
  },
  /**
   * Other Resources
   */
  {
    id: 20,
    bodyEn: "Use the Chatbot to ask questions about dementia.",
    body: "Utilice el Chatbot para hacer preguntas sobre la demencia.",
    url: "/contact",
    shouldRedirect: true,
    type: "CHATBOT",
    title: "Chatbot"
  },
  {
    id: 21,
    bodyEn: "Enter updates to patient weight.",
    body: "Ingrese actualizaciones para el peso del paciente.",
    url: "/dashboard",
    shouldRedirect: false,
    type: "OTHER",
    titleEn: "Track Weight",
    title: "Seguimiento de peso"
  },
  {
    id: 22,
    bodyEn:
      "Use the Refrigerator Door to add reminders and questions you want to ask the provider at your next visit.",
    body:
      "Use la Puerta del Refrigerador para agregar recordatorios y preguntas que desee hacerle al proveedor en su próxima visita.",
    url: "/dashboard",
    shouldRedirect: false,
    type: "OTHER",
    title: "Refrigerator Door",
    titleEs: "Refrigerator Door"
  },
  {
    id: 23,
    bodyEn: "Watch the Caregiver Video on taking care of yourself.",
    body: "Vea el video para cuidadores sobre cómo cuidarse a sí mismo.",
    url: "/Videos",
    shouldRedirect: true,
    type: "VIDEO",
    title: "Video",
    titleEs: "Video"
  },
  {
    id: 24,
    bodyEn:
      "Watch the Caregiver Video on preparing for a medical visit with someone with dementia.",
    body:
      "Vea el video del cuidador sobre cómo prepararse para una visita médica con alguien con demencia.",
    url: "/Videos",
    shouldRedirect: true,
    title: "Video",
    type: "VIDEO",
    titleEs: "Video"
  }
];
