export const SplitLongWords = text => {
  const i = text && text.indexOf("/");
  const words = text && text.split("/");
  let newWord = "";
  if (i >= 0) {
    newWord = `${words[0]} / ${words[1]}`;
  }

  return i >= 0 ? newWord : text;
};
