import styled from "styled-components";
import { Row, Col } from "antd";

export const ContentButtonsContainer = styled(Row)`
display: flex;
flex-direction: column;
width: 100%;

@media (min-width: 768px) {
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 15px;
  padding: 0 5px;
}
`;

export const BtnWrapper = styled(Col)`
  margin-bottom: 8px;

  @media (min-width: 576px) {
    margin-bottom: inherit;
  }
`;