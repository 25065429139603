/**
 * To represetn chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.
 */
import {
  forgotPasswordAction,
  forgotPasswordSuccessAction,
  forgotPasswordFailAction,
  changePasswordAction,
  changePasswordSuccessAction,
  changePasswordFailAction
} from "./actions";
import { forgotPassword, changePassword, login } from "./utils";
import { setUserAccount } from "utils/apis/Authentication/auth.service";
import types from "./types";
import { message } from "antd";
import { UserOperations } from "modules/user";

export const loginAction = (email, password, history?) => {
  return dispatch => {
    return login(email, password)
      .then(res => {
        if (res && res.code === 200) {
          // set the user's info into the session storage
          const userAccount = {
            authenticated: true,
            role: res.data.type,
            success: true,
            token: `${res.data.token}`
          };

          /**
           * If the user account is active
           */
          if (res.data && res.data.isActive) {
            setUserAccount(userAccount);
            sessionStorage.setItem("token", res.data.token);

            dispatch({
              type: types.LOGIN_SUCCESS,
              payload: {
                role: res.data.type,
                isAuthenticated: true
              }
            });

            message.success("Login Succesful");

            /**
             * Set Up the User fields for its Reducer
             */
            const User = {
              firstname: res.data.firstname,
              lastname: res.data.lastname,
              picture: res.data.picture,
              phone: res.data.phone,
              email: res.data.email,
              id: res.data.id
            };

            dispatch(UserOperations.LoadUser(User));

            // Navigate to the dashboard of this user
            history.push("/dashboard");
          } else {
            // user account has been disabled
            //TODO: dispatch a disabled account action
          }
        } else {
          //TODO: if message is handled by line 75, change dispatch
          dispatch({
            type: types.LOGIN_FAIL,
            payload: {
              isAuthenticated: false,
              loading: false,
              message: "Something went wrong"
            },
            role: null,
            message: "Wrong Credentials"
          });
          message.error("Something went wrong 94");
        }
      })
      .catch(err => {
        //TODO: if message is handled by line 90 then change dispatch
        dispatch({
          type: types.LOGIN_FAIL,
          isAuthenticated: false,
          message: "Wrong Credentials",
          payload: {
            isAuthenticated: false,
            loading: false,
            message: "Invalid Username and/or Password"
          }
        });
        message.error("Invalid Username and/or Password");
      });
  };
};

const loginUser = (email, password) => {
  return dispatch => {
    dispatch(loginAction(email, password));
  };
};

export const requestPasswordReset = email => {
  return dispatch => {
    dispatch(forgotPasswordAction);

    //make post request to backend to send the reset password email
    return forgotPassword(email)
      .then(response => {
        console.log(response);
        dispatch(forgotPasswordSuccessAction);
      })
      .catch(error => {
        console.log(error);
        dispatch(forgotPasswordFailAction);
      });
  };
};

export const requestPasswordChange = (password, token) => {
  return dispatch => {
    dispatch(changePasswordAction);
    return changePassword(password, token)
      .then(response => {
        console.log(response);
        dispatch(changePasswordSuccessAction);
      })
      .catch(err => {
        console.log(err);
        dispatch(changePasswordFailAction);
      });
  };
};

export default {
  loginAction,
  loginUser,
  requestPasswordReset
};
