import React, { useState } from "react";
import { Row, Col, Input as AntInput, message, DatePicker } from "antd";
import { Formik } from "formik";
import { registercaregiver } from "../utils/apis/Authentication/auth.service";
import defaultAvatarSvg from "../assets/img/avatars/avatar.svg";
import CustomButton from "../components/CustomButton/CustomButton";
import * as Yup from "yup";
import { Form, Input, Radio } from "formik-antd";
import moment from "moment";

const CaregiverSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name Required"),
  lastname: Yup.string().required("Last Name Required"),
  email: Yup.string()
    .email("Invalid Email")
    .required("Email Required"),
  patientInfo: Yup.object().shape({
    firstname: Yup.string().required("First Name Required"),
    lastname: Yup.string().required("Last Name Required"),
    relationship: Yup.string().required(
      "Relationship of Patient to Caregiver required."
    ),
    birthday: Yup.string().required("Birthday Required"),
    gender: Yup.string()
      .matches(/(male|female)/, "Select a gender.")
      .required("Gender Required")
  })
});

const InputGroup = AntInput.Group;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isValid =
    file.type === "image/jpeg" ||
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png";
  if (!isValid) {
    message.error("You can only upload JPG & PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isValid && isLt2M;
}

const Register = props => {
  const [state, setState] = useState({ loading: false, selectedfile: null });

  // const handlePhotoChange = (info, setFieldValueForFormik, field) => {
  //   if (info.file.status === "uploading") {
  //     this.setState({ loading: true });
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, imageUrl => {
  //       setFieldValueForFormik(field, imageUrl);
  //       this.setState({
  //         imageUrl,
  //         loading: false
  //       });
  //     });
  //   }
  // };

  const disabledDate = current => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <h2>Register Patient and Caregiver</h2>
          <p>
            The following form is to register a Patient along with their
            Caregiver.
          </p>
        </Col>
      </Row>
      <Formik
        initialValues={{
          firstname: "",
          lastname: "",
          email: "",
          phone: "",
          picture: "",
          patientInfo: {
            firstname: "",
            lastname: "",
            relationship: "",
            birthday: "",
            gender: "",
            picture: ""
          }
        }}
        validationSchema={CaregiverSchema}
        onSubmit={async (values, formikHelpers) => {
          const { setSubmitting } = formikHelpers;

          /**
              If the user does not upload a picture for the patient
              then set the default one
             */
          if (
            !values.patientInfo.picture ||
            values.patientInfo.picture === ""
          ) {
            values.patientInfo.picture = defaultAvatarSvg;
          }

          // If the user does not upload a picture for the caregiver
          // then just set the default avatar
          if (!values.picture || values.picture === "") {
            values.picture = defaultAvatarSvg;
          }

          registercaregiver(values)
            .then(() => {
              setSubmitting(false);
              message.success("Caregiver and Patient Registered", 2.5);
              props.history.push("/dashboard");
            })
            .catch(error => {
              setSubmitting(false);

              if (error.data.code === 400) {
                // set the error
                message.error(error.data.data.error.errors[0].message, 3);
              }
            });
        }}
        render={({ handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} layout="vertical">
              <h3>Caregiver:</h3>
              <InputGroup>
                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item label="First Name" name="firstname">
                      <Input name="firstname" />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item label="Last Name" name="lastname">
                      <Input name="lastname" />
                    </Form.Item>
                  </Col>
                </Row>
              </InputGroup>
              <InputGroup>
                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item label="Email" name="email">
                      <Input placeholder="name@mail.com" name="email" />
                    </Form.Item>
                  </Col>
                </Row>
              </InputGroup>
              <h3>Patient:</h3>
              <InputGroup>
                <Row gutter={8}>
                  <Col lg={12}>
                    <Form.Item label="First Name" name="patientInfo.firstname">
                      <Input name="patientInfo.firstname" />
                    </Form.Item>
                  </Col>
                  <Col lg={12}>
                    <Form.Item label="Last Name" name="patientInfo.lastname">
                      <Input name="patientInfo.lastname" />
                    </Form.Item>
                  </Col>
                </Row>
              </InputGroup>
              <Form.Item
                label="Relationship to Caregiver"
                name="patientInfo.relationship"
              >
                <Input name="patientInfo.relationship" />
              </Form.Item>

              <InputGroup>
                <Row gutter={8}>
                  <Col lg={3}>
                    <Form.Item
                      label="Date of Birth"
                      name="patientInfo.birthday"
                    >
                      {/* 
                        // @ts-ignore */}
                      <DatePicker
                        onChange={e =>
                          setFieldValue("patientInfo.birthday", e.format("L"))
                        }
                        format="MM/DD/YYYY"
                        name="patientInfo.birthday"
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={4}>
                    <Form.Item label="Gender" name="patientInfo.gender">
                      <Radio.Group
                        name="patientInfo.gender"
                        onChange={e => {
                          setFieldValue("patientInfo.gender", e.target.value);
                        }}
                      >
                        <Radio.Button value="male">Male</Radio.Button>
                        <Radio.Button value="female">Female</Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </InputGroup>
              <CustomButton type="primary" htmlType="submit">
                Register
              </CustomButton>
            </Form>
          );
        }}
      />
    </>
  );
};

export default Register;
