import * as React from "react";
import { useState } from "react";
import { Row, Col, message } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../../components/CustomButton/CustomButton";
import TranslationButtons from "components/TranslationButtons";

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Must have at least 8 characters")
    .matches(/(?=.*?[A-Z])/, "Must have at least one upper case.")
    .matches(/(?=.*?[a-z])/, "Must have at least one lowercase letter.")
    .matches(/(?=.*?[0-9])/, "Must have at least 1 digit.")
    .matches(
      /(?=.*?[#?!@$%^&*-])/,
      "Must have at least one of the following special characters #?!@$%^&*-"
    )
    .required("Password Required"),
  confirmpassword: Yup.string()
    .required("Type in your password again.")
    .test("passwords-match", "Passwords do not match.", function(value) {
      return this.parent.password === value;
    })
});

const EnglishResetForm = ({ handlePasswordChange, setUserSubmittedForm }) => {
  return (
    <>
      <TranslationButtons />
      <Formik
        initialValues={{
          password: "",
          confirmpassword: ""
        }}
        validationSchema={NewPasswordSchema}
        onSubmit={values => {
          handlePasswordChange(values.password)
            .then(() => {
              setUserSubmittedForm(true);
            })
            .catch(() => {
              message.error(
                "There was an error changing your password. Please contact the CareHeroes tech support team.",
                3
              );
            });
        }}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <h5>Reset Password</h5>
                <p>Enter your new password.</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="New Password" name="password">
                  <Input name="password" type="password" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Confirm Password" name="confirmpassword">
                  <Input name="confirmpassword" type="password" />
                </Form.Item>
              </Col>
            </Row>
            <CustomButton type="primary" htmlType="submit">
              Submit
            </CustomButton>
          </Form>
        )}
      />
    </>
  );
};

export default EnglishResetForm;
