import React from "react";
import { Row, Col, Form, Radio, Button } from "antd";
import { connect } from "react-redux";
import {
  submitPhq2Assessment,
  getPhq2SurveyQuestions
} from "../modules/phqAssessment/operations";
import Wizard from "../components/Wizard/AssessmentWizard";
import CustomButton from "../components/CustomButton/CustomButton";
import { func, object, string, array } from "prop-types";
import CustomRadioGroup from "../components/CustomRadioGroup/index";
import styled from "styled-components";

const Container = styled.div`
  height: ${props => (props.viewingWizard ? "auto" : "100%")};
  display: ${props => (props.viewingWizard ? "initial" : "flex")};
  justify-content: ${props => (props.viewingWizard ? "flex-start" : "center")};
  align-items: ${props => (props.viewingWizard ? "stretch" : "center")};
`;

const ContentRow = styled(Row)`
  max-width: ${props => (props.viewingWizard ? "none" : "600px")};
`;

/**
 * The questionaire for the
 * Feeling Sad or Blue survey
 */
class PatientHealthQuestionaire extends React.PureComponent {
  state = {
    showWizard: false
  };
  componentDidMount() {
    this.props.getPhq2SurveyQuestions();
  }

  handleToggleWizard = e => {
    e.preventDefault();

    this.setState({
      showWizard: true
    });
  };

  handleShowPastAssessments = e => {
    e.preventDefault();

    this.props.history.push("/phq/view");
  };

  render() {
    const { i18n, lang } = this.props;

    return (
      <Container viewingWizard={this.state.showWizard}>
        <ContentRow viewingWizard={this.state.showWizard}>
          {!this.state.showWizard && (
            <>
              <Col md={24}>
                <Row style={{ textAlign: "center" }}>
                  <h2>{i18n[lang].FeelingSadQ.Title}</h2>
                  <h2>{i18n[lang].Subtitle}</h2>
                  <h3>{i18n[lang].Assessments.LanguageInstructions}</h3>
                </Row>
              </Col>

              <Col span={24}>
                <CustomButton
                  type="primary"
                  onClick={this.handleToggleWizard}
                  block
                >
                  {i18n[lang].Assessments.BeginTest}
                </CustomButton>
                <Button
                  type="secondary"
                  onClick={this.handleShowPastAssessments}
                  block
                >
                  {i18n[lang].Assessments.ShowSubmissions}
                </Button>
              </Col>
            </>
          )}
          <Col>
            {this.state.showWizard && (
              <Wizard
                initialValues={{
                  q1: { surveyQuestionId: -1, response: "" },
                  q2: { surveyQuestionId: -1, response: "" }
                }}
                onSubmit={(values, actions) => {
                  const { questions } = this.props;
                  let payload = [];

                  // each response (obj) has a meta object
                  // index : position of answer picked
                  // position: the index of the question
                  // decision: text of the decision picked
                  // lang: language of the decision picked
                  for (let [key, obj] of Object.entries(values)) {
                    // we are only sending the english responses to the backend, for research purposes
                    // so we need to replace the response that is
                    // in spanish with the corresponding english version and the surveyQuestionId

                    const { index, position, lang } = obj.meta;

                    if (lang === "spanish") {
                      obj.response = questions[position].decisions[index];
                      obj.surveyQuestionId = questions[position].id;
                    }

                    delete obj.meta;

                    payload.push(obj);
                  }

                  this.props.submitPhq2Assessment(payload, this.props.history);
                }}
                instructions={
                  <>
                    <h4>{i18n[lang].FeelingSadQ.InstructionTitle}</h4>
                    <p>{i18n[lang].FeelingSadQ.Instructions}</p>
                  </>
                }
              >
                <Wizard.Page
                  validate={values => {
                    const errors = {};
                    if (values.q1.response === "") {
                      errors.q1 =
                        lang === "en"
                          ? "Question 1 Missing An Answer"
                          : "Le falta respuesta a la pregunta 1";
                    }
                    return errors;
                  }}
                >
                  {props => {
                    if (this.props.lang === "en") {
                      return (
                        <Row lang={this.props.lang}>
                          {/* row needs this prop in order for translation to work */}
                          <CustomRadioGroup
                            {...this.props.questions[0]}
                            {...props}
                            rank={1}
                          />
                        </Row>
                      );
                    } else {
                      return (
                        <Row lang={this.props.lang}>
                          <CustomRadioGroup
                            {...this.props.questions_es[0]}
                            {...props}
                            rank={1}
                          />
                        </Row>
                      );
                    }
                  }}
                </Wizard.Page>
                <Wizard.Page
                  validate={values => {
                    const errors = {};
                    if (values.q2.response === "") {
                      errors.q2 =
                        lang === "en"
                          ? "Question 2 Missing An Answer"
                          : "Le falta respuesta a la pregunta 2";
                    }
                    return errors;
                  }}
                >
                  {props => {
                    if (this.props.lang === "en")
                      return (
                        <Row lang={this.props.lang}>
                          <CustomRadioGroup
                            {...this.props.questions[1]}
                            {...props}
                            rank={2}
                          />
                        </Row>
                      );
                    else {
                      return (
                        <Row lang={this.props.lang}>
                          <CustomRadioGroup
                            {...this.props.questions_es[1]}
                            {...props}
                            rank={2}
                          />
                        </Row>
                      );
                    }
                  }}
                </Wizard.Page>
              </Wizard>
            )}
          </Col>
        </ContentRow>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  questions: state.phq.questions,
  questions_es: state.phq.questions_es,
  loading: state.phq.loading,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});
const mapDispatchToProps = { submitPhq2Assessment, getPhq2SurveyQuestions };

PatientHealthQuestionaire.propTypes = {
  history: object.isRequired,
  getPhq2SurveyQuestions: func.isRequired,
  i18n: object.isRequired,
  lang: string.isRequired,
  questions: array.isRequired,
  questions_es: array.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientHealthQuestionaire);

const generateFormItems = (questions, props, rank) => {
  if (questions) {
    return questions.map((question, index) => (
      <CustomRadioGroup key={index} rank={rank} {...question} {...props} />
    ));
  } else {
    return null;
  }
};
