import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import CustomButton from "../../../components/CustomButton/CustomButton";

class DailyLivingResponse extends React.Component {
  handleClick = e => {
    e.preventDefault();
    this.props.history.push("/dashboard");
  };

  componentDidMount() {
    console.log(this.props);
  }

  render() {
    const { i18n, lang } = this.props;

    return (
      <Row type="flex" justify="center">
        <Col
          style={{
            textAlign: "center"
          }}
          span={24}
        >
          <h4>{i18n[lang].AssessmentResponses.DailyLiving.Response}</h4>
          <p>{i18n[lang].AssessmentResponses.Buttons.Instructions}</p>
        </Col>
        <Col>
          <CustomButton onClick={this.handleClick}>
            {i18n[lang].AssessmentResponses.Buttons.Label}
          </CustomButton>
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapState)(DailyLivingResponse);
