import produce from "immer";
import types from "./types";
import translationDictionary from "./translation.json";

const defaultState = {
  lang: "en",
  i18n: translationDictionary,
  isTakingAssessment: false
};

const LangReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.Lang_SET:
      return produce(state, draft => {
        draft.lang = action.payload.lang;
      });
    case types.User_TAKING_ASSESSMENT: {
      return produce(state, draft => {
        draft.isTakingAssessment = true;
      });
    }
    case types.User_NOT_TAKING_ASSESSMENT: {
      return produce(state, draft => {
        draft.isTakingAssessment = false;
      });
    }
    default:
      return state;
  }
};

export default LangReducer;
