import React, { Component } from "react";
import { Row, Col } from "antd";
import Iframe from "react-iframe";

class Chatbot extends Component {
  constructor(props, context) {
    super(props, context);
    this.iFrameRef = React.createRef;
  }

  state = {};

  render() {
    return (
      <div className="content">
        <Row type="flex" justify="center" align="middle">
          <Col>
            <Iframe
              url="https://users.cs.fiu.edu/~clarkep/ch/"
              width="450px"
              height="700px"
              id="myId"
              display="initial"
              position="relative"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Chatbot;
