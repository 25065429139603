import React from "react";
import { Row, Col, Tabs, message } from "antd";
import DailyLivingReport from "./PatientContent/DailyLivingReport";
import BehaviorTracking from "./PatientContent/BehaviorTracking";
import PrescriptionForm from "./PatientContent/PrescriptionForm.tsx";
import { connect } from "react-redux";
import { fetchRefrigeratorDoorData } from "../../../../modules/refrigeratordoor/utils";
import ProviderRefrigeratorDoor from "./PatientContent/ProviderRefrigeratorDoor";

const { TabPane } = Tabs;

/**
 * Renders the Behavior Tracking, Daily Living, Refrigerator Door, and Prescribe Content depending on size of window
 *
 * HOC for the Content of Refrigerator Door
 */
class PatientContent extends React.Component {
  state = {
    prescriptions: [],
    reminders: [],
    phoneNumbers: []
  };

  /**
   * Fetch the refrigerator door from the backend
   */
  componentDidMount() {
    // Get the PatientID
    // let pId = sessionStorage.getItem("pId");
    // const patientId = JSON.parse(pId);

    // let cId = sessionStorage.getItem("cId");
    // const caregiverId = JSON.parse(cId);

    const { caregiverId, patientId } = this.props;

    fetchRefrigeratorDoorData(caregiverId, patientId)
      .then(response => {
        this.setState({
          prescriptions: response.data.prescriptions,
          reminders: response.data.reminders,
          phoneNumbers: response.data.phone_numbers
        });
      })
      .catch(err => {
        // message.error("Errror fetching refrigerator door of patient", 2.5)
        console.log("failed to fetch refrigerator door", err);
      });
  }
  render() {
    return (
      <>
        <Row>
          <Col>
            <Tabs>
              <TabPane tab="Memory/Behavior" key="1">
                <BehaviorTracking />
              </TabPane>
              <TabPane tab="Daily Living" key="2">
                <DailyLivingReport />
              </TabPane>
              <TabPane tab="Refrigerator Door" key="3">
                <ProviderRefrigeratorDoor
                  prescriptions={this.state.prescriptions}
                  reminders={this.state.reminders}
                  phoneNumbers={this.state.phoneNumbers}
                />
              </TabPane>
              <TabPane tab="Prescribe" key="4">
                <PrescriptionForm />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}

const mapState = state => ({
  caregiverId: state.ProviderHome.caregiverId,
  patientId: state.ProviderHome.patientId
});

export default connect(mapState)(PatientContent);
