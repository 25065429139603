import request from "../../utils/apis/request";

export const getPositveAspectSurvey = () => {
  return request({
    url: "/api/surveys/positive-aspects-of-caregiving",
    method: "get"
  });
};

export const postPostiveAspectSurvey = data => {
  return request({
    url: "/api/surveys/positive-aspects-of-caregiving",
    method: "post",
    data: data
  });
};

export const getPaocSubmissions = () => {
  return request({
    url: "/api/surveys/submissions/positive-aspects-of-caregiving",
    method: "post"
  });
};
