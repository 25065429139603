import { Row, Col } from "antd";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import React, { useRef } from "react";
import styled from "styled-components";
import { Input, Form, Checkbox } from "formik-antd";
import PrescriptionValidation from "./PrescriptionForm/PrescriptionValidation";
import { createNewPrescriptionTodo } from "../../../../../modules/todo-prescription/operations.js";
import CustomButton from "../../../../../components/CustomButton/CustomButton";
import {
  GenerateInitialValues,
  ParseKeysForResources
} from "./PrescriptionForm/prescription-form.service";
import {
  englishResources,
  spanishResources
} from "../../../../../utils/resources/index";

const CustomCheckbox = styled(Checkbox)`
  &&& {
    display: block;

    span:first-child {
      padding: 14px;
    }

    span:first-child:hover {
      cursor: pointer;
    }

    span > input {
      width: 18px;
      height: 18px;
    }
  }
`;

const PrescriptionForm = () => {
  const formStart = useRef(null);

  const dispatch = useDispatch();
  const caregiverId = useSelector(state => state.ProviderHome.caregiverId);
  const scrollToFormStart = () =>
    window.scrollTo(0, formStart.current.offsetTop);

  return (
    <>
      <Row>
        <Col>
          <h3>Provider Instructions:</h3>
          <p>
            CareHeroes allows the provider to recommend to the caregiver use of
            a Chatbot, various assessments, educational videos, and resource
            links between scheduled appointments. Please select a task and then
            click on submit.
          </p>
        </Col>
      </Row>
      <Row ref={formStart}>
        <Col>
          <Formik
            initialValues={{
              ...GenerateInitialValues(englishResources),
              custom: ""
            }}
            validate={PrescriptionValidation}
            onSubmit={async (values, formikBag) => {
              // create the custom task
              const custom = {
                assignedTo: caregiverId,
                body: values.custom,
                bodyEs: values.custom,
                title: "Custom Task",
                titleEs: "Tarea Personalizada",
                type: "CUSTOM",
                url: ""
              };

              delete values.custom;

              const results = ParseKeysForResources(
                values,
                englishResources,
                spanishResources
              );

              // set up the assignedTo value
              results.forEach(r => {
                r.assignedTo = caregiverId;
              });

              // merge all tasks
              results.push(custom);

              await dispatch(createNewPrescriptionTodo(results));

              // Reset the form and go to the top of form

              formikBag.resetForm();
              scrollToFormStart();
            }}
            validateOnBlur={false}
            validateOnChange={false}
            render={({ handleSubmit, errors }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <h4>Education Resources</h4>
                  <h5>Tips for Caregiver Self Care</h5>

                  {/* 
      // @ts-ignore */}

                  <Form.Item
                    validateStatus={errors && errors.missing ? "error" : null}
                    help={errors.missing}
                  >
                    {englishResources.slice(0, 5).map(v => (
                      <CustomCheckbox key={`${v.id}`} name={`${v.id}`}>
                        {v.body}
                      </CustomCheckbox>
                    ))}

                    <h5>
                      Understanding Alzheimer's Diseases and Other Types of
                      Dementia
                    </h5>
                    {englishResources.slice(5, 11).map(v => (
                      <CustomCheckbox key={`${v.id}`} name={`${v.id}`}>
                        {v.body}
                      </CustomCheckbox>
                    ))}

                    <h5>
                      Understanding Alzheimer's Diseases and Other Types of
                      Dementia
                    </h5>
                    {englishResources.slice(11, 15).map(v => (
                      <CustomCheckbox key={`${v.id}`} name={`${v.id}`}>
                        {v.body}
                      </CustomCheckbox>
                    ))}

                    <h4>Assessments</h4>

                    {englishResources.slice(15, 18).map(v => (
                      <CustomCheckbox key={`${v.id}`} name={`${v.id}`}>
                        {v.alias}
                      </CustomCheckbox>
                    ))}

                    {englishResources.slice(18, 20).map(v => (
                      <CustomCheckbox key={`${v.id}`} name={`${v.id}`}>
                        {v.alias}
                      </CustomCheckbox>
                    ))}

                    <h4>Other Tasks</h4>

                    {englishResources.slice(20).map(v => (
                      <CustomCheckbox key={`${v.id}`} name={`${v.id}`}>
                        {v.body}
                      </CustomCheckbox>
                    ))}

                    <h5>Additional Instructions</h5>
                    <Input.TextArea
                      name="custom"
                      autoSize={{ minRows: 3, maxRows: 8 }}
                      placeholder="Custom Task Requirement(s)"
                    />
                  </Form.Item>
                  <CustomButton type="primary" htmlType="submit">
                    Submit
                  </CustomButton>
                </Form>
              );
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PrescriptionForm;
