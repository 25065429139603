import { englishResources } from "../../../../../../utils/resources/index";

/**
 *  title: "",
    body: "",
    url: "",
    frequency: "",
    selectedValue: -1,
    dueDate: "",
    instructions: "",

  */

/**
 * Formik Initial Values
 *
 * Takes a list of all available resources
 * and returns an object where each key is the id
 * of a resource and the value is false
 */
export const GenerateInitialValues = list => {
  const values = {};
  list.forEach(v => {
    values[`${v.id}`] = false;
  });
  return values;
};

/**
 * Before Submitting Selected Resources
 * Iterate through the provided object of key:boolean pairs
 * For each key that is true, get the respective resource
 * and return it as an array
 *
 * @param {*} selectedResources
 */
export const ParseKeysForResources = (
  selectedResources,
  englishResourceList,
  spanishResourceList
) => {
  let result = [];
  for (const [key, value] of Object.entries(selectedResources)) {
    // value is a boolean
    if (value) {
      // take advantage of resource list being indexed from 0
      // remove unnecessary keys:values
      const currentEn = { ...englishResourceList[key] };
      const currentEs = { ...spanishResourceList[key] };

      delete currentEn.alias;
      delete currentEs.alias;

      delete currentEn.id;
      delete currentEs.id;

      if (currentEs.titleEn) delete currentEs.titleEn;
      if (currentEs.titleEs) delete currentEs.titleEs;
      if (currentEs.bodyEn) delete currentEs.bodyEn;
      if (currentEs.bodyEs) delete currentEs.bodyEs;

      delete currentEn.shouldRedirect;
      delete currentEs.shouldRedirect;
      console.log(currentEs);
      currentEn.bodyEs = currentEs.body;
      currentEn.titleEs = currentEs.title;

      result.push(currentEn);
    }
  }
  console.log(result, selectedResources);
  return result;
};
