import Dashboard from "views/Dashboard/Dashboard.jsx";
import PatientHealthQuestionaire from "../pages/patient-health-question";
import ZaritBurdenQuestionaire from "../pages/zarit-burden-question";
import PositiveAspectsCaregiving from "../pages/positive-aspects-caregiving";
import ActivitiesDailyLiving from "../pages/activities-daily-living";
import TrackBehaviorChanges from "../pages/track-behavior-changes";
import Videos from "../pages/videos";
import Resources from "../pages/resources";

import ProviderRoutes from "./providerRoutes";
import PhqResponse from "../pages/patient-health-question/results/PhqResponse";
import ZaritResponse from "../pages/zarit-burden-question/results/ZaritResponse";
import PaocResponse from "../pages/positive-aspects-caregiving/results/PaocResponse";
import DailyLivingResponse from "../pages/activities-daily-living/results/DailyLivingResponse";
import TrackBehaviorResponse from "../pages/track-behavioral-changes/results/TrackBehaviorResponse";
import AdminRoutes from "./admin.routes";
import Phq2PastSubmissions from "../pages/patient-health-question/past-submissions/Phq2PastSubmissions";
import ZaritPastSubmissions from "../pages/zarit-burden-question/past-submissions/ZaritPastSubmissions";
import PaocPastSubmissions from "../pages/positive-aspects-caregiving/past-submissions/PaocPastSubmissions";
import PastDailyLivingSubmissions from "../pages/activities-daily-living/past-submissions/DailyLivingSubmissions";
import TrackBehaviorSubmissions from "../pages/track-behavioral-changes/past-submissions/TrackBehaviorSubmissions";
import ContactUs from "../pages/Contact.tsx";
import { CaregiverProfile } from "pages/caregiver/CaregiverProfile";

/**
 * The CareGiver assessmet routes
 */
export const caregiverAssessments = [
  {
    key: 4,
    path: "/phq",
    name: "Feeling Sad or Blue?",
    icon: "nc-icon nc-single-copy-04",
    component: PatientHealthQuestionaire
  },

  {
    key: 5,
    path: "/zarit",
    name: "Feeling overwhelmed?",
    icon: "nc-icon nc-single-copy-04",
    component: ZaritBurdenQuestionaire
  },
  {
    key: 6,
    path: "/paoc",
    name: "Take the Caregiving Test To See How You Are?",
    icon: "nc-icon nc-single-copy-04",
    component: PositiveAspectsCaregiving
  }
];

/**
 * The CareGiver Patient assessment routes
 */
export const patientAssessments = [
  {
    key: 7,
    path: "/activities-daily-living",
    name: "Activities of Daily Living",
    icon: "nc-icon nc-single-copy-04",
    component: ActivitiesDailyLiving
  },
  {
    key: 8,
    path: "/tracking-behavioral-changes",
    name: "Keeping Track of Behavior",
    icon: "nc-icon nc-single-copy-04",
    component: TrackBehaviorChanges
  }
];

export const ViewPastAssessments = [
  {
    path: "/phq/view",
    name: "View Past PHQ2",
    component: Phq2PastSubmissions
  },
  {
    path: "/zarit/view",
    name: "View Past Zarit",
    component: ZaritPastSubmissions
  },
  {
    key: 6,
    path: "/paoc/view",
    name: "View Past PostiveAspect",
    component: PaocPastSubmissions
  },
  {
    key: 7,
    path: "/activities-daily-living/view",
    name: "View Past Activities of Daily Living",
    component: PastDailyLivingSubmissions
  },
  {
    key: 8,
    path: "/tracking-behavioral-changes/view",
    name: "View Past Keeping Track of Behavioral",
    component: TrackBehaviorSubmissions
  }
];

/**
 * The Dashboard routes that should appear for everyone
 */

export const everyoneRoutes = [
  {
    key: 0,
    path: "/dashboard",
    name: "Home",
    icon: "nc-icon nc-bank",
    component: Dashboard
  },
  {
    key: 1,
    path: "/contact",
    name: "Chat With Us",
    icon: "nc-icon nc-bank",
    component: ContactUs
  },
  {
    key: 2,
    path: "/Resources",
    name: "Resources",
    icon: "nc-icon nc-bank",
    component: Resources
  },
  {
    key: 3,
    path: "/Videos",
    name: "Videos",
    icon: "nc-icon nc-bank",
    component: Videos
  }
];

/**
 * This should include all the routes the user can navigate to
 */
const dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard
  },
  {
    path: "/phq/response",
    name: "Patient Health Questionaire - Results",
    icon: "",
    component: PhqResponse
  },
  {
    path: "/zarit/response",
    name: "Are You Feeling Overwhelemed? - Results",
    icon: "",
    component: ZaritResponse
  },
  {
    path: "/paoc/response",
    name: "Take the Caregiving Test To See How You Are? - Results",
    icon: "",
    component: PaocResponse
  },
  {
    path: "/activities-daily-living/response",
    name: "Activities of Daily Living - Results",
    icon: "",
    component: DailyLivingResponse
  },
  {
    path: "/tracking-behavioral-changes/response",
    name: "Keeping Track of Behavioral Changes - Results",
    icon: "",
    component: TrackBehaviorResponse
  },
  {
    path: "/dashboard/profile",
    name: "Profile",
    icon: null,
    component: CaregiverProfile
  }
  // { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];
export default [
  ...dashRoutes,
  ...patientAssessments,
  ...caregiverAssessments,
  ...ProviderRoutes,
  ...everyoneRoutes,
  ...AdminRoutes,
  ...ViewPastAssessments
];
