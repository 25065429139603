import React, { useState } from "react";
import { Row, Col, Button, message } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { postReminder } from "../../../../../modules/refrigeratordoor/utils";

const AddReminderSchemaOld = Yup.object().shape({
  text: Yup.string().required("Content is required."),
  name: Yup.string().required("Name is required.")
});

const AddReminderSchema = Yup.object().shape({
  text: Yup.string().required("Content is required.")
});

const AddReminder = props => {
  const [startValue, setStartValue] = useState(new Date());
  const { i18n, lang } = useSelector(state => state.LangReducer);

  const disabledEndDate = endValue => {
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  };

  return (
    <div>
      <Row>
        <Col span={24}>
          <Formik
            initialValues={{
              text: "",
              name: ""
            }}
            validationSchema={AddReminderSchema}
            onSubmit={values => {
              postReminder(values)
                .then(data => {
                  message.success("Success creating Reminder");
                  props.toggleForm();
                })
                .catch(err => {
                  message.error("Error creating Reminder");
                });
            }}
            validateOnBlur={false}
            validateOnChange={false}
            render={({ handleSubmit, errors }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <h2>{i18n[lang].RefrigeratorDoor.ReminderText}</h2>
                  <Form.Item
                    name="text"
                    label={lang === "en" ? "Content" : "El Contendido"}
                  >
                    <Input.TextArea name="text" />
                  </Form.Item>
                  <Row type="flex" justify="start">
                    <Col>
                      <Button htmlType="submit">
                        {i18n[lang].RefrigeratorDoor.AddReminder}
                      </Button>
                    </Col>
                    <Col style={{ marginLeft: "8px" }}>
                      <Button onClick={() => props.toggleForm()}>
                        {lang === "en" ? "Cancel" : "Cancelar"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AddReminder;
