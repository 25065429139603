import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import dashboardRoutes from "../routes/dashboard.js";

import {
  getUserAccount,
  logout
} from "../utils/apis/Authentication/auth.service";
import { Layout, Icon, Row } from "antd";
import Sidebar2 from "../components/Sidebar/Sidebar2";
import { withGetScreen } from "react-getscreen";
import { connect } from "react-redux";
import { setLang } from "../modules/translation/actions";
import { string, object, func, bool } from "prop-types";
import TranslationButtons from "./fulllayout/TranslationButtons";
import styled from "styled-components";
import { NoMatch } from "./NoMatch";

const StyledHeader = styled.div`
  display: ${props => (props.isSpanish ? "flex" : "flex")};
  justify-content: space-between;
`;

const { Header,  Content } = Layout;

const breakpointsConfig = {
  mobileLimit: 480,
  tabletLimit: 1025,
  shouldListenOnResize: true
};

class Fulllayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sideBarCollapsed: false
    };
  }
  componentDidMount() {
    // If user doesn't have a session, redirect him to the login page
    const userInfo = getUserAccount();
    if (userInfo === false || userInfo.authenticated === null) {
      this.props.history.push("/auth/login");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  toggle = () => {
    this.setState({
      sideBarCollapsed: !this.state.sideBarCollapsed
    });
  };

  logout = async () => {
    // Track the user using the logout button
    await logout().catch(() => console.log("error logging out"));

    //clear the sessionStorage to remove the jwt
    sessionStorage.clear();
    // redirect to login
    this.props.history.push("/auth/login");
  };

  /**
   * Collapses the sidebar when the viewport is equal or less than
   * the breakpoint defined for the Sider breakpoint prop
   */
  handleBreakpoint = inBreakpoint => {
    if (inBreakpoint) {
      this.setState(() => ({ sideBarCollapsed: true }));
    } else {
      this.setState(() => ({ sideBarCollapsed: false }));
    }
  };

  handleCollapse = () => {
    this.setState({ sideBarCollapsed: true });
  };

  /**
   * Might not be necessary
   * Useful for when the sidebar doesn't extend all the way to cover the screen
   * completely and the user touches the main interface,
   * it would collapse the side menu
   */
  handleLayoutClick = e => {
    // e.preventDefault();

    if (this.state.sideBarCollapsed === false && this.props.isMobile()) {
      this.setState({
        sideBarCollapsed: true
      });
    }
  };

  // Sets the current lang to use in Lang Reducer
  handleLangClick = lang => {
    this.props.setLang(lang);
  };

  antdLayout = () => {
    const { i18n, lang } = this.props;

    return (
      <Layout style={{ minHeight: "100vh", position: "relative" }}>
        <Sidebar2
          location={this.props.location}
          collapsed={this.state.sideBarCollapsed}
          handleBreakpoint={this.handleBreakpoint}
          isMobile={this.props.isMobile}
          isTablet={this.props.isTablet}
          handleCollapse={this.handleCollapse}
          history={this.props.history}
          langDict={i18n[lang]}
        />
        <Layout onClick={this.handleLayoutClick}>
          <Header
            style={{
              background: "#fff",
              padding: 0,
              position: "fixed",
              zIndex: 1,
              width: "100%"
            }}
          >
            <StyledHeader isSpanish={lang === "es"}>
              <Row>
                <Icon
                  className="trigger"
                  type={
                    this.state.sideBarCollapsed ? "menu-unfold" : "menu-fold"
                  }
                  onClick={this.toggle}
                  style={{
                    fontSize: "1.4em",
                    paddingLeft: "15px"
                  }}
                />
              </Row>
              <Row>
                <TranslationButtons
                  isTakingAssessment={this.props.isTakingAssessment}
                  sideBarCollapsed={this.state.sideBarCollapsed}
                  i18n={i18n}
                  lang={lang}
                  handleLogout={this.logout}
                  handleLangClick={this.handleLangClick}
                />
              </Row>
            </StyledHeader>
          </Header>
          <Content
            // eslint-disable-next-line react/no-string-refs
            ref="mainPanel"
            style={{ marginTop: "64px", padding: "15px" }}
          >
            <Switch>
              {dashboardRoutes.map((prop, key) => {
                if (prop.redirect) {
                  return (
                    <Redirect from={prop.path} to={prop.pathTo} key={key} />
                  );
                }
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key}
                    exact
                  />
                );
              })}
              <Route component={NoMatch} />
            </Switch>
          </Content>
        </Layout>
      </Layout>
    );
  };
  render() { return this.antdLayout(); }
}

const mapState = state => {
  return {
    i18n: state.LangReducer.i18n,
    lang: state.LangReducer.lang,
    isTakingAssessment: state.LangReducer.isTakingAssessment
  };
};

Fulllayout.propTypes = {
  i18n: object,
  lang: string,
  setLang: func,
  history: object,
  isMobile: func,
  isTakingAssessment: bool
};

const mapDispatch = {
  setLang
};

export default connect(
  mapState,
  mapDispatch
)(withGetScreen(Fulllayout, breakpointsConfig));
