import React, { Component } from "react";
import { connect } from "react-redux";
import { GetZaritSubmissions } from "../../../modules/zaritAssessment/operations";
import SurveySubmissions from "../../../components/SurveySubmissions";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import CustomButton from "../../../components/CustomButton/CustomButton";

class ZaritPastSubmissions extends Component {
  state = {
    displayedSubmissions: [],
    leftIndex: -1,
    rightIndex: -1
  };

  /**
   * Allows the user to shift through the submissions
   * Children will take care of sending new left and right indeces
   */
  handleIndexShift = (leftIndex, rightIndex) =>
    this.setState({ leftIndex, rightIndex });

  handleGoBack = () => this.props.history.goBack();

  componentDidMount() {
    this.props.getSubmissions().then(res => {
      const { submissions } = this.props;
      const length = submissions.length;
      let latest = [];
      let leftIndex = -1;
      let rightIndex = -1;
      if (length > 2) {
        latest = submissions.slice(length - 3);
        leftIndex = length - 3;
        rightIndex = length - 1;
      } else if (length === 2) {
        latest = submissions.slice(length - 2);
        leftIndex = length - 2;
        rightIndex = length - 1;
      } else if (length === 1) {
        latest = submissions.slice(length - 1);
        leftIndex = length - 1;
        rightIndex = length - 1;
      }

      this.setState({
        displayedSubmissions: latest,
        leftIndex,
        rightIndex
      });
    });
  }
  render() {
    const { leftIndex, rightIndex } = this.state;
    const { loading, submissions, i18n, lang } = this.props;

    if (loading) {
      // Display the spinner
      return <div>Loading</div>;
    } else {
      // No longer Loading
      // if fetched submissions length greater than 0 and is different from default
      if (submissions.length > 0 && submissions[0].id !== -1) {
        return (
          <SurveySubmissions
            submissions={submissions}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            handleIndexShift={this.handleIndexShift}
          >
            <>
              <h4>{i18n[lang].PrevAssessments.Title}</h4>
              <p>{i18n[lang].PrevAssessments.Instructions}</p>
            </>
          </SurveySubmissions>
        );
      } else {
        // else display message indicating there are no submissions
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ height: "100vh" }}
          >
            <Col>
              <h3>{i18n[lang].PrevAssessments.NoneFound}</h3>
              <CustomButton block onClick={this.handleGoBack} type="primary">
                {i18n[lang].PrevAssessments.GoBack}
              </CustomButton>
            </Col>
          </Row>
        );
      }
    }
  }
}

const mapDispatch = {
  getSubmissions: GetZaritSubmissions
};

const mapState = state => ({
  submissions: state.zarit.submissions,
  loading: state.zarit.loading,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

ZaritPastSubmissions.propTypes = {
  getSubmissions: PropTypes.func,
  submissions: PropTypes.array,
  i18n: PropTypes.object,
  lang: PropTypes.string,
  loading: PropTypes.bool,
  history: PropTypes.func
};

export default connect(
  mapState,
  mapDispatch
)(ZaritPastSubmissions);
