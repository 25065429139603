import types from "./types";
/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const submitTrackBehaviorAction = data => ({
  type: types.TrackBehavior_SUBMIT_SUCCESS,
  payload: {
    loading: false,
    score: data.score,
    showResponse: true
  }
});

export const trackBehaviorRequestInitAction = message => ({
  type: types.TrackBehavior_API_REQUEST_INIT,
  payload: {
    loading: true,
    message
  }
});

export const getTrackBehaviorAssessmentQuestionsAction = response => ({
  type: types.TrackBheavior_GET_QUESTIONS,
  payload: {
    questions: response.data,
    message: response.message,
    loading: false
  }
});

export const trackBehaviorFailAction = message => ({
  type: types.TrackBehavior_FAIL,
  payload: {
    loading: false,
    message
  }
});

export const viewTrackBehaviorAction = () => ({
  type: types.TrackBehavior_VIEW,
  payload: {
    showResponse: false,
    score: 0
  }
});

export const viewTrackBehaviorResultsAction = () => ({
  type: types.TrackBehavior_VIEW_RESULTS,
  payload: {}
});

export const routeChangeTrackBehaviorAction = () => ({
  type: types.TrackBehavior_ROUTE_CHANGE
});

export const GetTrackBehaviorSubmissionsAction = message => ({
  type: types.TrackBehavior_GET_PAST_SUBMISSIONS,
  payload: {
    loading: true,
    message
  }
});

export const SetTrackBehaviorSubmissionsAction = (data, message) => ({
  type: types.TrackBehavior_SET_PAST_SUBMISSIONS,
  payload: {
    submissions: data,
    message,
    loading: false
  }
});
