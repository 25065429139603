import React from "react";
// import { Row } from "antd";
import { Row, Col, Button } from "antd";
import ZaritInstructions from "./zarit-burden-question/ZaritInstructions";
import ZaritAssessment from "./zarit-burden-question/ZaritAssessment";
import { bindActionCreators } from "redux";
import {
  submitZaritAssessment,
  getZaritAssessment
} from "../modules/zaritAssessment/operations";
import { connect } from "react-redux";
import CustomButton from "../components/CustomButton/CustomButton";

/**
 * Feeling Overwhelemed
 */
class ZaritBurdenQuestionaire extends React.Component {
  state = {
    showWizard: false
  };
  componentDidMount() {
    this.props.getZaritAssessment();
  }

  handleToggleWizard = e => {
    e.preventDefault();

    this.setState({
      showWizard: true
    });
  };

  handleShowPastAssessments = e => {
    e.preventDefault();

    this.props.history.push("/zarit/view");
  };

  handleSubmit = (values, totalScore) => {
    this.props.submitZaritAssessment(values, totalScore, this.props.history);
  };
  render() {
    const { i18n, lang } = this.props;

    return (
      <div
        className="content"
        style={
          this.state.showWizard
            ? {}
            : {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
        }
      >
        <Row style={this.state.showWizard ? {} : { maxWidth: "600px" }}>
          {!this.state.showWizard && (
            <>
              <Col md={24}>
                <Row style={{ textAlign: "center" }}>
                  <ZaritInstructions />
                </Row>
              </Col>

              <Col span={24}>
                <CustomButton
                  type="primary"
                  onClick={this.handleToggleWizard}
                  block
                >
                  {i18n[lang].Assessments.BeginTest}
                </CustomButton>
                <Button
                  type="secondary"
                  onClick={this.handleShowPastAssessments}
                  block
                >
                  {i18n[lang].Assessments.ShowSubmissions}
                </Button>
              </Col>
            </>
          )}
          <Col>
            {this.state.showWizard && (
              <ZaritAssessment handleSubmit={this.handleSubmit} />
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatchToProps = dispatch => {
  return Object.assign(
    {},
    dispatch,
    bindActionCreators({ submitZaritAssessment, getZaritAssessment }, dispatch)
  );
};

export default connect(
  mapState,
  mapDispatchToProps
)(ZaritBurdenQuestionaire);
