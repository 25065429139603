import types from "./types";

export const setLang = lang => ({
  type: types.Lang_SET,
  payload: {
    lang
  }
});

export const TakeAssessmentAction = () => ({
  type: types.User_TAKING_ASSESSMENT,
  payload: {}
});

export const NotTakingAssessmentAction = () => ({
  type: types.User_NOT_TAKING_ASSESSMENT,
  payload: {}
});
