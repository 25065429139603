import React from "react";
import {
  Row,
  Col,
  Button,
  Input as AntInput,
  message,
  Upload,
  Icon
} from "antd";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Form, Input } from "formik-antd";
import { CreateProvider } from "../../../utils/apis/Admin/admin.service";
import avatarSvg from "../../../assets/img/avatars/avatar.svg";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { History } from "history";

const InputGroup = AntInput.Group;

function beforeUpload(file) {
  const isJPG = file.type === "image/jpeg";
  if (!isJPG) {
    message.error("You can only upload JPG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJPG && isLt2M;
}

const ProviderSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(2, "Too short")
    .max(50, "First name too long!")
    .required("First Name Required"),
  lastname: Yup.string()
    .min(2, "Too short")
    .max(50, "Last name too long!")
    .required(" Last Name Required"),

  email: Yup.string()
    .email("Invalid Email")
    .required("Email Required")
});

interface RegisterProviderValues {
  email: string;
  firstname: string;
  lastname: string;
  picture: string;
}

interface Props {
  history: History;
}

const RegisterProvider: React.SFC<Props> = ({ history }) => {
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <h2>Register Provider Page</h2>
        </Col>
      </Row>
      <Row>
        <Formik
          initialValues={{
            email: "",
            firstname: "",
            lastname: "",
            picture: ""
          }}
          validationSchema={ProviderSchema}
          onSubmit={(
            values: RegisterProviderValues,
            formikbag: FormikHelpers<RegisterProviderValues>
          ) => {
            const { setSubmitting } = formikbag;

            setSubmitting(true);
            if (!values.picture || values.picture === "") {
              values.picture = avatarSvg;
            }

            CreateProvider(values)
              .then(() => {
                setSubmitting(false);

                history.push("/dashboard");

                return "13";
              })
              .catch(err => {
                setSubmitting(false);
                if (err.data.code === 400) {
                  // set the error
                  message.error(err.data.data.err[0].msg, 3);
                }
              });
          }}
          render={({ handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <InputGroup>
                <Row>
                  <Col>
                    <Form.Item label="First Name" name="firstname">
                      <Input name="firstname" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item label="Last Name" name="lastname">
                      <Input name="lastname" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item label="E-Mail" name="email">
                      <Input name="email" />
                    </Form.Item>
                  </Col>
                </Row>
              </InputGroup>
              <Form.Item label="Provider Photo" name="photo">
                <Upload
                  name="photo"
                  customRequest={dummyRequest}
                  beforeUpload={beforeUpload}
                  onChange={e =>
                    this.handlePhotoChange(e, setFieldValue, "photo")
                  }
                >
                  <Button>
                    <Icon type="upload" /> Click to Upload
                  </Button>
                </Upload>
              </Form.Item>
              {/* <Form.Item label="Provider Location">
                  <Select name="location">
                    <Option value="Miami Jewish">Miami Jewish</Option>
                    <Option value="ASU Hospital">
                      Alabama State University Hospital
                    </Option>
                  </Select>
                </Form.Item> */}
              <CustomButton type="primary" htmlType="submit">
                Register
              </CustomButton>
            </Form>
          )}
        />
      </Row>
    </>
  );
};

export default RegisterProvider;
