import React from "react";
import { Row, Col, Card, Tabs } from "antd";
import DashboardAvatar from "../../../components/DashboardAvatar/DashboardAvatar";

import DudePhoto from "../../../assets/img/faces/ayo-ogunseinde-2.jpg";

import ElderlyWoman from "../../../assets/img/faces/elderly_woman.jpg";
import DefaultAvatar from "../../../assets/img/avatars/avatar.svg";
import PatientContent from "./ProviderViewPatient/PatientContent";
import { connect } from "react-redux";

const { TabPane } = Tabs;

/**
 * Root Component of Provider Home Screen
 *
 * Manages the content of the Caregiver avatar, Provider Avatar
 * And the Caregiver/Patient forms
 *
 */
class ProviderViewPatient extends React.Component {
  render() {
    const {
      caregiverLast,
      caregiverFirst,
      patientFirst,
      patientLast,
      patientPicture,
      caregiverPicture
    } = this.props;

    const setAvatar = picturePath => {
      if (picturePath === "") {
        return DefaultAvatar;
      } else {
        return picturePath;
      }
    };

    return (
      <>
        <Row>
          <Col
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <DashboardAvatar
              title="Patient"
              picturePath={setAvatar(patientPicture)}
              name={`${patientFirst} ${patientLast}`}
            />
          </Col>
          <Col
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <DashboardAvatar
              title="Caregiver"
              picturePath={setAvatar(caregiverPicture)}
              name={`${caregiverFirst} ${caregiverLast}`}
            />
          </Col>
        </Row>

        <PatientContent />
      </>
    );
  }
}

const mapState = state => ({
  patientFirst: state.ProviderHome.currentcombo.patient.firstname,
  patientLast: state.ProviderHome.currentcombo.patient.lastname,
  patientPicture: state.ProviderHome.currentcombo.patient.picture,
  caregiverFirst: state.ProviderHome.currentcombo.caregiver.firstname,
  caregiverLast: state.ProviderHome.currentcombo.caregiver.lastname,
  caregiverPicture: state.ProviderHome.currentcombo.caregiver.picture
});

export default connect(mapState)(ProviderViewPatient);
