import types from "./types";
import produce, { isDraft } from "immer";
import { IProviderState } from "./interfaces";
import { IAction } from "../../interfaces/modules";
const defaultState: IProviderState = {
  patientcombos: [],
  message: "",
  combolist: [],
  currentcombo: {
    caregiver: {
      firstname: "",
      lastname: "",
      phone: "",
      picture: "",
    },
    patient: {
      firstname: "",
      lastname: "",
      picture: "",
      relationship: "",
      gender: "",
      birthday: "",
    },
  },
  patientId: -1,
  caregiverId: -1,
};

const ProviderHomeReducer = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case types.GET_PATIENTS_SUCCESS: {
      return produce(state, (draft) => {
        draft.patientcombos = action.payload.patientcombos;
        draft.combolist = action.payload.patientcombos;
        draft.message = action.payload.message;
      });
    }

    case types.GET_PATIENTS_FAIL: {
      return produce(state, (draft) => {
        draft.patientcombos = action.payload.data;
        draft.message = action.payload.message;
      });
    }

    case types.GET_PATIENTS: {
      return produce(state, (draft) => {
        draft.patientcombos = [];
        draft.message = "Fetching patients";
      });
    }

    case types.SELECT_COMBO_LIST: {
      return produce(state, (draft) => {
        let combolistTwo = state.patientcombos.filter((combo) => {
          return combo.patient.pid === action.payload.key;
        });
        // combolistTwo = combolistTwo.map(combo => combo.patient);
        draft.combolist = combolistTwo;
      });
    }

    case types.RESET_COMBO_LIST: {
      return produce(state, (draft) => {
        draft.combolist = state.patientcombos;
      });
    }

    case types.SET_CURRENT_COMBO: {
      return produce(state, (draft) => {
        draft.currentcombo = action.payload.combo;
      });
    }

    case types.RESET_CURRENT_COMBO: {
      return produce(state, (draft) => {
        draft.currentcombo = defaultState.currentcombo;
      });
    }

    case types.SET_CAREGIVER_AND_PATIENT_ID: {
      return produce(state, (draft) => {
        draft.patientId = action.payload.patientId;
        draft.caregiverId = action.payload.caregiverId;
      });
    }

    case types.CLEAR_CAREGIVER_AND_PATIENT_ID: {
      return produce(state, (draft) => {
        draft.patientId = -1;
        draft.caregiverId = 1;
      });
    }

    default:
      return state;
  }
};

export default ProviderHomeReducer;
