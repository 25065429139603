/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

import types, { AuthAction } from "./types";

export const loginSuccessAction = (data): AuthAction => ({
  type: types.LOGIN_SUCCESS,
  payload: {}
});

export const startLoginAction = (): AuthAction => ({
  type: types.LOGIN_START,
  payload: {
    loading: true
  }
});

export const forgotPasswordAction = (): AuthAction => ({
  type: types.FORGOT_PASSWORD,
  payload: {
    loading: true
  }
});

export const forgotPasswordSuccessAction = (): AuthAction => ({
  type: types.FORGOT_PASSWORD_SUCCESS,
  payload: {
    loading: false
  }
});

export const forgotPasswordFailAction = (): AuthAction => ({
  type: types.FORGOT_PASSWORD_FAIL,
  payload: {
    loading: false
  }
});

export const changePasswordAction = (): AuthAction => ({
  type: types.CHANGE_PASSWORD,
  payload: {
    loading: true
  }
});

export const changePasswordSuccessAction = (): AuthAction => ({
  type: types.CHANGE_PASSWORD_SUCCESS,
  payload: {
    loading: false
  }
});

export const changePasswordFailAction = (): AuthAction => ({
  type: types.CHANGE_PASSWORD_FAIL,
  payload: {
    loading: false
  }
});

export default { startLoginAction };
