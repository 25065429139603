/**
 * Selectors are part of the public interface of a module. Selector functions take a slice of the applicaiton state and
 * return some data based on that. They never introduce any changes to the application state.
 */

export const isProvider = state => {
  return state.role === "PROVIDER";
};

export const isCaregiver = state => {
  return state.role === "CAREGIVER";
};

export default {
  isProvider,
  isCaregiver
};
