import * as React from "react";
import { useState } from "react";
import { Row, Col, Button } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import CustomButton from "../../components/CustomButton/CustomButton";
import styled from "styled-components";
import { useSelector } from "react-redux";
import TranslationButtons from "../../components/TranslationButtons/index";

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const Content = styled.div`
  max-width: 400px;
  @media (max-width: 400px) {
    height: 100vh;
    margin: 0;
    width: 100vw;
  }

  @media (max-width: 575px and min-width: 401px) {
    height: 75vh;
    margin: 0;
    width: 95vw;
  }
`;

const ForgotPasswordForm = props => {
  const [submitted, setSubmitted] = useState(false);
  const lang = useSelector(state => state.LangReducer.lang);
  const i18n = useSelector(state => state.LangReducer.i18n);

  if (!submitted) {
    return (
      <>
        <TranslationButtons />
        <Formik
          initialValues={{ email: "" }}
          onSubmit={(values, formikBag) => {
            props.requestPasswordReset(values.email);
            setSubmitted(true);
          }}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <h5>{i18n[lang].ForgotPassword.ForgotForm.Questions}</h5>
                  <p>{i18n[lang].ForgotPassword.ForgotForm.Instruction}</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    label={lang === "en" ? "Email" : "Correo Electronico"}
                    name="email"
                  >
                    <Input name="email" />
                  </Form.Item>
                  <FormFields>
                    <CustomButton type="primary" htmlType="submit">
                      {i18n[lang].ForgotPassword.Buttons.Submit}
                    </CustomButton>
                    <Button onClick={props.goToLoginScreen}>
                      {i18n[lang].ForgotPassword.Buttons.Home}
                    </Button>
                  </FormFields>
                </Col>
              </Row>
            </Form>
          )}
        />
      </>
    );
  } else {
    return (
      <Content>
        <TranslationButtons />
        <Row>
          <Col>
            <h5>{i18n[lang].ForgotPassword.ForgotForm.Questions}</h5>
            <p>{i18n[lang].ForgotPassword.ForgotForm.Response}</p>
          </Col>
        </Row>
        <CustomButton block type="primary" onClick={props.goToLoginScreen}>
          {i18n[lang].ForgotPassword.Buttons.Home}
        </CustomButton>
      </Content>
    );
  }
};

export default ForgotPasswordForm;
