/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const LOGIN_SUCCESS = "careheroes/auth/LOGIN_SUCCESS";
const LOGIN_FAIL = "careheroes/auth/LOGIN_FAIL";
const LOGIN_START = "careheroes/auth/LOGIN_START";
const FORGOT_PASSWORD = "careheroes/auth/FORGOT_PASSWORD";
const FORGOT_PASSWORD_SUCCESS = "careheroes/auth/FORGOT_PASSWORD_SUCCESS";
const FORGOT_PASSWORD_FAIL = "careheroes/auth/FORGOT_PASSWORD_FAIL";
const CHANGE_PASSWORD = "careheroes/auth/CHANGE_PASSWORD";
const CHANGE_PASSWORD_SUCCESS = "careheroes/auth/CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAIL = "careheroes/auth/CHANGE_PASSWORD_FAIL";

export interface AuthAction {
  readonly type: string;
  payload?: {
    role?: string;
    isAuthenticated?: boolean;
    message?: string;
    loading?: boolean;
  };
}

// interface LoginSuccessAction {
//   type: typeof LOGIN_SUCCESS;
//   payload: {
//     role: string;
//     isAuthenticated: boolean;
//   };
// }

// interface LoginFailAction {
//   type: typeof LOGIN_FAIL;
//   payload: {
//     isAuthenticated: boolean;
//     loading: boolean;
//     message: string;
//   };
// }

// interface LoginAction {
//   type: typeof LOGIN_SUCCESS;
//   payload: {
//     role: string;
//     isAuthenticated: boolean;
//   };
// }

// export type AuthActionTypes =
//   | LoginSuccessAction
//   | LoginAction
//   | LoginFailAction;

export default {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL
};
