import request from "../../utils/apis/request";

// =============================================================================
// Utility Functions and Helpers Related to the Tracking Behavioral Survey
// =============================================================================

// -----------------------------------------------------------------------------
// API Calls
// -----------------------------------------------------------------------------
/**
 * Makes a request to the surveys api for the questions of the Tracking Behavioral Changes assessments
 */
export const getTrackBehaveSurvey = () => {
  return request({
    method: "get",
    url: "/api/surveys/track-behavioral-changes"
  });
};

/**
 * Sends a submission of the Tracking Behavioral Changes assessment to the surveys api
 */
export const postTrackBehaveSurvey = data => {
  return request({
    method: "post",
    url: "/api/surveys/track-behavioral-changes",
    data
  });
};

export const getTrackBehaveSubmissions = userIdPayload => {
  if (userIdPayload) {
    return request({
      url: "/api/surveys/submissions/track-behavioral-changes",
      method: "post",
      data: userIdPayload
    });
  } else
    return request({
      url: "/api/surveys/submissions/track-behavioral-changes",
      method: "post"
    });
};

// -----------------------------------------------------------------------------
// Helpers
// -----------------------------------------------------------------------------

/**
 * For viewing the previous submissions of this survey
 * The responses have to be color coded.
 *
 * As a result, the response is parsed through a switch/case to determine what styling
 * to return. Default case is for every other survey that interacts with this function to do nothing.
 * @param {*} response
 */
export const setBorderStylingFor = response => {
  let green = "#00A54F";
  let yellow = "#FEF200";
  let yellowgreen = "#8EC63F";
  let orange = "#F8921C";
  let red = "#ED1B24";

  let styling = {};

  switch (response) {
    case "Not at all":
      styling = {
        border: `2px solid black`,
        height: 90,
        backgroundColor: green
      };
      break;
    case "A Little":
      styling = {
        border: `2px solid black`,
        height: 90,
        backgroundColor: yellowgreen
      };
      break;
    case "Moderately":
      styling = {
        border: `2px solid black`,
        height: 90,
        backgroundColor: yellow
      };
      break;
    case "Very Much":
      styling = {
        border: `2px solid black`,
        height: 90,
        backgroundColor: orange
      };
      break;
    case "Extremely":
      styling = {
        border: `2px solid black`,
        height: 90,
        backgroundColor: red
      };
      break;
    case "N/A":
      styling = {
        backgroundColor: "#d3d3d3",
        height: 90,
        border: "0.5px dotted black"
      };
      break;
    default:
      styling = {
        height: 90
      };
  }

  /** Make the text appear in the middle */
  styling.display = "flex";
  styling.alignItems = "center";
  styling.justifyContent = "center";

  return styling;
};

/**
 * For viewing the previous submissions of this survey
 * The responses have to be color coded.
 *
 * As a result, the response is parsed through a switch/case to determine what styling
 * to return. Default case is for every other survey that interacts with this function to do nothing.
 * @param {*} response
 */
export const setBorderStylingForMobile = response => {
  let green = "#00A54F";
  let yellow = "#FEF200";
  let yellowgreen = "#8EC63F";
  let orange = "#F8921C";
  let red = "#ED1B24";

  let styling = {};

  switch (response) {
    case "Not at all":
      styling = {
        border: `2px solid ${green}`
      };
      break;
    case "A Little":
      styling = {
        border: `2px solid ${yellowgreen}`
      };
      break;
    case "Moderately":
      styling = {
        border: `2px solid ${yellow}`
      };
      break;
    case "Very Much":
      styling = {
        border: `2px solid ${orange}`
      };
      break;
    case "Extremely":
      styling = {
        border: `2px solid ${red}`
      };
      break;
    case "N/A":
    default:
      styling = {};
  }

  /** Make the text appear in the middle */
  // styling.display = "flex";
  // styling.alignItems = "center";
  // styling.justifyContent = "center";

  return styling;
};
