import React from "react";
import { Row, Col } from "antd";
import { setBorderStylingFor } from "../../modules/trackBehaviorAssessment/utils";
import { string, array, number } from "prop-types";
import styled from "styled-components";
import { SplitLongWords } from "../../utils/transfomers/strings";
import { LastColumn, MiddleColumn } from "./surveyResponses/Columns";
import { useSelector } from "react-redux";

const Container = styled(Col)`
  text-align: center;
`;

export const DateLabel = styled.div`
  border-bottom: 2px solid black;
  font-weight: 750;
  margin-bottom: 0px;
  height: 25px;
`;

export const Response = styled.div`
  border-bottom: 2px solid black;
  min-height: 90px;
  height: 90px;
`;

export const Text = styled.div`
  font-size: 0.95rem;
  font-weight: 550;
`;

export const SurveyResponses = props => {
  const { surveyName, submissions, rightIndex, leftIndex } = props;

  const lang = useSelector(state => state.LangReducer.lang);

  const isSurveyDailyLiving = name => name === "DailyLiving";

  // Split the words with / in the responses
  submissions[leftIndex].question_submission.forEach(s => {
    s.response = SplitLongWords(s.response);
  });

  return (
    <Container md={isSurveyDailyLiving(surveyName) ? 15 : 13} lg={15}>
      <Row>
        <Col
          span={isSurveyDailyLiving(surveyName) ? 12 : 8}
          style={{ border: "2px solid black" }}
        >
          <DateLabel>
            {submissions[leftIndex].createdAt.format("MM-DD-YYYY")}
          </DateLabel>
          {submissions[leftIndex].question_submission.map((s, index) => (
            <Response key={index}>
              <Text style={setBorderStylingFor(s.response)}>
                {lang === "en" ? s.response : s.response_es}
              </Text>
            </Response>
          ))}
        </Col>
        {/* This is the third column that displays if 
        the length of submissions is greater than 2 and 
        the survey is TrackBehavior */}
        {submissions.length > 2 && surveyName !== "DailyLiving" && (
          <MiddleColumn submissions={submissions} leftIndex={leftIndex} />
        )}
        {/* This is the second column that displays if the 
        length of submissions is greater than 1 */}
        {submissions.length > 1 && (
          <LastColumn
            isDailySurvey={isSurveyDailyLiving(surveyName)}
            submissions={submissions}
            rightIndex={rightIndex}
          />
        )}
      </Row>
    </Container>
  );
};

SurveyResponses.propTypes = {
  submissions: array.isRequired,
  leftIndex: number.isRequired,
  rightIndex: number.isRequired,
  surveyName: string.isRequired
};
