import types from "./types";
import produce from "immer";

const defaultState = {
  todos: [],
  loading: true,
  message: ""
};

const TodoPrescriptionReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.TodoPrescription_FAIL:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.TodoPrescription_COMPLETE_TODO:
      return produce(state, draft => {
        draft.message = action.payload.message;
      });
    case types.TodoPrescription_GET_TODOS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
        draft.todos = action.payload.todos;
      });
    case types.TodoPrescription_SUCCESS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
        draft.message = action.payload.message;
      });
    case types.TodoPrescription_SUBMIT_TODO:
      return produce(state, draft => {
        draft.loading = action.payload.loading;
      });
    case types.TodoPrescription_SET_TODOS:
      return produce(state, draft => {
        draft.loading = action.payload.loading;

        // calculate for each todo returned, how many submissions have been
        // already submitted for it
        action.payload.todos.forEach(todo => {
          todo.submissionCount = todo.todo_submissions.length;
        });

        // discard all todos that have been completed
        draft.todos = action.payload.todos.filter(
          todo => todo.submissionCount < 1
        );
      });
    case types.TodoPrescription_REMOVE_TODO:
      return produce(state, draft => {
        draft.todos = draft.todos.filter(todo => todo.id !== action.payload.id);
      });
    default:
      return state;
  }
};

export default TodoPrescriptionReducer;
