import React from "react";
import { Row, Col, Input, List, Icon, Select, Spin } from "antd";
import styled from "styled-components";
import ElderlyWoman from "../../../../assets/img/faces/elderly_woman.jpg";
import ElderlyMan from "../../../../assets/img/faces/ElderlyMan.jpg";
import { connect } from "react-redux";
import Base64Image from "../../../../components/Base64Image/Base64Image";
import {
  SelectComboListAction,
  ResetComboListAction
} from "../../../../modules/providerHome/actions.ts";

const StyledItem = styled(List.Item)`
  :hover {
    background-color: rgb(250, 250, 250);
  }
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const { Option } = Select;

class PatientComboList extends React.Component {
  state = {
    dataSource: [],
    combolist: [],
    data: [],
    value: [],
    fetching: false
  };

  componentDidMount() {
    this.setState({
      filteredSources: this.props.items
    });
  }

  /**
   * When value is undefined or null, the state.value will be set to an empty array
   * Also, under the same conditions state.combolist will be assigned state.datasource
   * since it signifies the search field has been cleared.
   *
   * Otherwise just set state.value value
   */
  handleChange = value => {
    const valueExist = value ? true : false;
    if (!valueExist) {
      // Reset the Combo List on Cancel
      this.props.ResetComboListAction();

      value = [];
      this.setState({
        value: value,
        data: [],
        fetching: false
      });
    } else {
      this.setState({
        value: value,
        data: [],
        fetching: false
      });
    }
  };

  /**
   * Sets up the list of users to select from
   * Called after typing in a letter
   */
  fetchUser = () => {
    // console.log("fetchUser triggered", value, this.state);
    this.setState({ data: [], fetching: true });

    // iterate through the list of patient combos
    // create the text & value objects to later map Option
    // tags around
    const data = this.state.dataSource.map(combo => ({
      text: `${combo.name}`,
      value: combo.id
    }));

    // console.log(this.props.list);

    let dataTwo = null;

    // From the list of patient combos
    // parse out only the patient objects
    dataTwo = this.props.list.map(combo => combo.patient);
    /**
     * pid
     * picture
     * lastname
     * gender
     * firstname
     * birthday
     */
    dataTwo = dataTwo.map(d => ({
      text: `${d.firstname} ${d.lastname}`,
      value: d.pid
    }));

    this.setState({ data: dataTwo, fetching: false });
  };

  /**
   * Filters the content of the search field
   */
  handleFilter = (input, option) => {
    // console.log("handleFilter", input, option);

    return (
      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  };

  /**
   * When the Select component invokes this method,
   * it will filter the main combolist with the id (key) of the patient
   *
   * key: pid of patient
   * label: "First" & "Last" names of patient
   */
  handleSelect = ({ key, label }) => {
    this.props.SelectComboListAction(key, label);

    const combolist = this.state.combolist.filter(combo => {
      return combo.id === key;
    });

    let combolistTwo = this.props.list.filter(combo => {
      return combo.patient.pid === key;
    });

    // parse out the caregiver
    combolistTwo = combolistTwo.map(combo => combo.patient);

    this.setState({
      combolist
    });
  };

  render() {
    const { data, value, fetching } = this.state;
    const { handlePatientClick } = this.props;

    return (
      <>
        <Row>
          <Col span={24}>
            <h3>Search for Patient:</h3>
            <Select
              mode="default"
              showSearch
              showArrow={false}
              labelInValue
              optionFilterProp="children"
              value={value}
              filterOption={this.handleFilter}
              placeholder="Select users"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              onSearch={this.fetchUser}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
              allowClear
              style={{ width: "100%" }}
            >
              {data.map(d => {
                return (
                  <Option key={d.value} value={d.value}>
                    {d.text}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </Row>
        <Row>
          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              onChange: page => {
                console.log(page);
              },
              pageSize: 4
            }}
            dataSource={this.props.combolist}
            renderItem={item => (
              <StyledItem
                style={{
                  cursor: "pointer"
                }}
                onClick={() => handlePatientClick(item)}
                key={item.caregiver.id}
                extra={
                  <Row type="flex">
                    <Col>
                      {/* <img
                        width={272}
                        height={168}
                        alt="logo"
                        src={item.avatar}
                      /> */}
                      <AvatarContainer>
                        <Base64Image
                          width={272}
                          height={168}
                          alt="Patient Picture"
                          src={item.patient.picture}
                        />
                        <p>{`${item.patient.firstname} ${item.patient.lastname}`}</p>
                      </AvatarContainer>
                    </Col>
                    <Col span={0}>
                      {/* <img
                        width={272}
                        alt="logo"
                        src="https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"
                      /> */}
                      <Base64Image
                        width={272}
                        height={168}
                        alt="Caregiver Picture"
                        src={item.caregiver.picture}
                      />
                      <p>{`${item.caregiver.firstname} ${item.caregiver.lastname}`}</p>
                    </Col>
                  </Row>
                }
              >
                <List.Item.Meta
                  title={
                    <h3>
                      <span style={{ marginRight: 10 }}>Patient:</span>
                      {`${item.patient.firstname} ${item.patient.lastname}`}
                    </h3>
                  }
                  description={
                    <h4>
                      <span style={{ marginRight: 10 }}>Caregiver:</span>
                      {`${item.caregiver.firstname} ${item.caregiver.lastname}`}
                    </h4>
                  }
                />
                {/* {item.content} */}
              </StyledItem>
            )}
          />
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  patientcombos: state.ProviderHome.patientcombos,
  combolist: state.ProviderHome.combolist
});

const mapDispatch = {
  SelectComboListAction,
  ResetComboListAction
};

export default connect(mapStateToProps, mapDispatch)(PatientComboList);
