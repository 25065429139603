import React from "react";
import { connect } from "react-redux";

class TrackBehaviorInstructions extends React.Component {
  render() {
    const { i18n, lang } = this.props;
    return (
      <div>
        <h2>{i18n[lang].TrackBehaviorQ.Title}</h2>
        <h3>{i18n[lang].Assessments.LanguageInstructions}</h3>
      </div>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapState)(TrackBehaviorInstructions);
