/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const Sidemenu_SET_INDEX = "careheroes/Sidemenu/SET_INDEX";

export default {
  Sidemenu_SET_INDEX
};
