import React from "react";
import { Row, Col, Button } from "antd";
import * as Yup from "yup";
import { Form, InputNumber } from "formik-antd";
import { Formik } from "formik";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import { connect } from "react-redux";
// import { PostWeight } from "../../../../modules/caregiver/operations";
import { caregiverHomeOperations } from "../../../../modules/caregiver";

/**
 * Caregiver logs the weight of their patient with this
 * form to the user service
 */
class SubmitWeightForm extends React.Component {
  render() {
    const { i18n, lang } = this.props;

    const validation = i18n[lang].WeightMetric.Validation;

    // TODO: translate to spanish
    const SubmitWeighSchema = Yup.object().shape({
      weight: Yup.number()
        .moreThan(0, `${validation.MoreThan}`)
        .required(`${validation.Required}`)
    });

    return (
      <Row>
        <Col>
          <h5>{i18n[lang].WeightMetric.EnterPatientWeight} (lb):</h5>
        </Col>
        <Col span={24}>
          <Formik
            initialValues={{
              weight: 0
            }}
            validationSchema={SubmitWeighSchema}
            onSubmit={async ({ weight }, formikBag) => {
              const { patientId, PostWeight } = this.props;

              // Submit the recorded weight
              const status = await PostWeight(patientId, weight);
              this.props.updateWeight();
              this.props.hideForm();
            }}
            render={({ handleSubmit, errors }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <Form.Item
                    validateStatus={errors && errors.weight ? "error" : null}
                    help={errors && errors.weight}
                  >
                    <InputNumber name="weight" min={0} autoFocus={true} />
                  </Form.Item>
                  <Row>
                    <Col span={8}>
                      <CustomButton type="primary" htmlType="submit">
                        {i18n[lang].WeightMetric.LogWeight}
                      </CustomButton>
                    </Col>
                  </Row>
                </Form>
                <Row style={{ marginTop: "15px" }}>
                  <Col span={10}>
                    <Button block onClick={() => this.props.hideForm()}>
                      {i18n[lang].WeightMetric.Cancel}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          />
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  patientId: state.CaregiverReducer.patientcombos[0].pid,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatch = {
  PostWeight: caregiverHomeOperations.PostWeight
};

export default connect(mapState, mapDispatch)(SubmitWeightForm);
