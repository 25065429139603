import request from "../../utils/apis/request";

/**
 * Sends to the User Service an object of fields to updated.
 *
 * Returns the updated user object
 */

type IUpdate = {
  firstname: string;
  lastname: string;
  picture: string;
};
export const UpdateUser = (UserUpdate: IUpdate, id: number) => {
  return request({
    method: "PUT",
    url: "/api/users/" + id,
    data: UserUpdate
  });
};
