import React from "react";
import { Row, Col, Button } from "antd";
import styled from "styled-components";

const CenterV = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NoPatientsFound = ({ handleRegister }) => {
  return (
    <Row type="flex" justify="center">
      <Col>
        <Row>
          <CenterV>
            <h3>No Patient Records Found</h3>
            <h4>Register a Patient</h4>
            <Button onClick={handleRegister}>Register</Button>
          </CenterV>
        </Row>
      </Col>
    </Row>
  );
};
