import React from "react";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

const AdminHome = () => {
  return (
    <>
      <Row>
        <Col>
          <h2>Admin Home</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Register Provider</h3>
        </Col>
        <Col>
          <Link to="/register/provider">
            <Button>Register</Button>
          </Link>
        </Col>
      </Row>
    </>
  );
};

export default AdminHome;
