/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const DailyLiving_SUBMIT = "careheroes/assessments/DailyLiving_SUBMIT";
const DailyLiving_SUCCESS = "careheroes/assessments/DailyLiving_SUCCESS";
const DailyLiving_FAIL = "careheroes/assessments/DailyLiving_FAIL";
const DailyLiving_VIEW = "careheroes/assessments/DailyLiving_VIEW";
const DailyLiving_VIEW_RESULTS =
  "careheroes/assessments/DailyLiving_VIEW_RESULTS";
const DailyLiving_ROUTE_CHANGE =
  "carehereoes/assessments/DailyLiving_ROUTE_CHANGE";
const DailyLiving_GET_QUESTIONS =
  "careheroes/assessments/DailyLiving_GET_QUESTIONS";
const DailyLiving_GET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/DailyLiving_GET_PAST_SUBMISSIONS";
const DailyLiving_SET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/DailyLiving_SET_PAST_SUBMISSIONS";

export default {
  DailyLiving_SUBMIT,
  DailyLiving_VIEW,
  DailyLiving_VIEW_RESULTS,
  DailyLiving_ROUTE_CHANGE,
  DailyLiving_SUCCESS,
  DailyLiving_FAIL,
  DailyLiving_GET_QUESTIONS,
  DailyLiving_GET_PAST_SUBMISSIONS,
  DailyLiving_SET_PAST_SUBMISSIONS
};
