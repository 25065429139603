import React from "react";
import { Row, Col } from "antd";
import DailyLivingTable from "./DailyLiving/DailyLivingTable";

class DailyLivingReport extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col>
            <h3>Daily Living Report</h3>
            <h5>
              The caregiver was asked about the problems their patient has with
              tasks in their daily living. The below shows what problems the
              caregiver reported.
            </h5>
          </Col>
          <Col>
            <DailyLivingTable />
          </Col>
        </Row>
      </>
    );
  }
}

export default DailyLivingReport;
