/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const TodoPrescription_GET_TODOS = "careheroes/todo/TodoPrescription_GET_TODOS";
const TodoPrescription_SET_TODOS = "careheroes/todo/TodoPrescription_SET_TODOS";
const TodoPrescription_SUBMIT_TODO =
  "careheroes/todo/TodoPrescription_SUBMIT_TODO";
const TodoPrescription_SUCCESS = "careheroes/todo/TodoPrescription_SUCCESS";
const TodoPrescription_FAIL = "careheroes/todo/TodoPrescription_FAIL";
const TodoPrescription_COMPLETE_TODO =
  "careheroes/todo/TodoPrescription_COMPLETE_TODO";
const TodoPrescription_REMOVE_TODO =
  "careheroes/todo/TodoPrescription_REMOVE_TODO";

export default {
  TodoPrescription_GET_TODOS,
  TodoPrescription_SUBMIT_TODO,
  TodoPrescription_SUCCESS,
  TodoPrescription_FAIL,
  TodoPrescription_COMPLETE_TODO,
  TodoPrescription_SET_TODOS,
  TodoPrescription_REMOVE_TODO
};
