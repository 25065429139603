import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import authRoutes, { allowedAuthRoutes } from "../routes/authroutes.js";
import { getUserAccount } from "../utils/apis/Authentication/auth.service.js";

class Blanklayout extends React.Component {
  isAllowedRoute(path) {
    let flag = false;

    allowedAuthRoutes.forEach(item => {
      if (path.indexOf(item.path) > -1) {
        flag = true;
      }
    });

    return flag;
  }

  componentDidMount() {
    const userInfo = getUserAccount();

    if (
      userInfo === null &&
      this.isAllowedRoute(window.location.pathname) === false
    ) {
      this.props.history.push("/auth/login");
    }
  }

  render() {
    return (
      <div>
        <Switch>
          {authRoutes.map((prop, key) => {
            if (prop.redirect)
              return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
            return (
              <Route path={prop.path} component={prop.component} key={key} />
            );
          })}
        </Switch>
      </div>
    );
  }
}
export default Blanklayout;
