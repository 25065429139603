import React from "react";
import { Row, Col, Card } from "antd";
import Reminders from "./refrigerator-door/Reminders.tsx";
import ContactInfo from "./refrigerator-door/ContactInfo.tsx";
import { connect } from "react-redux";

class RefrigeratorDoor extends React.Component {
  render() {
    const { i18n, lang } = this.props;
    const translation = i18n[lang];

    return (
      <Row>
        <Card style={{ margin: "8px 0" }}>
          <Col span={24}>
            <h4>{translation.RefrigeratorDoor.Heading}</h4>
            <hr />
          </Col>
          <Col span={24}>
            <Reminders />
          </Col>
        </Card>
        <Card>
          <Col span={24}>
            <ContactInfo />
          </Col>
        </Card>
      </Row>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapState)(RefrigeratorDoor);
