import React from "react";
import { Row, Col, Card, Spin } from "antd";

import { connect } from "react-redux";
import SubmitWeightForm from "./weight-tracking/SubmitWeightForm";
// import { GetWeights } from "../../../modules/caregiver/operations";
import CustomButton from "../../../components/CustomButton/CustomButton";
import WeightTrackingTable from "./weight-tracking/WeightTrackingTable";
import { WeightTrackingChart } from "../../../components/WeightTrackingChart";
import { caregiverHomeOperations } from "modules/caregiver";

class WeightTracking extends React.Component {
  state = {
    isLoggingWeight: false,
    loading: true,
    recordCount: 0,
    showTable: false
  };

  toggleTable = () =>
    this.setState(prevState => ({ showTable: !prevState.showTable }));

  toggleForm = () =>
    this.setState(prevState => ({
      isLoggingWeight: !prevState.isLoggingWeight
    }));

  hideForm = () => {
    this.setState({ isLoggingWeight: false, showTable: false });
  };

  componentDidMount() {
    //fetch the patient's weight and load into redux state
    this.props.GetPatientWeight(this.props.patientId).then(() => {
      // no longer loading, determine if user has tracked weight before
      this.setState({ loading: false, recordCount: this.props.weights.length });
    });
  }

  fetchWeight = () => {
    // fetch the patients weight and load into redux state
    this.props.GetPatientWeight(this.props.patientId).then(() => {
      this.setState({ recordCount: this.props.weights.length });
    });
  };

  render() {
    const { i18n, lang } = this.props;
    const translation = i18n[lang];

    return (
      <>
        {this.state.recordCount < 1 && (
          <Card>
            <Row>
              <Col>
                <h4>
                  {lang === "en"
                    ? "No Previously Logged Weight"
                    : "Sin peso registrado previamente"}
                </h4>
                <p>
                  {lang === "en"
                    ? "Please enter today's weight of your patient to view the graph."
                    : "Ingrese el peso actual de su paciente para ver el gráfico."}
                </p>
                <SubmitWeightForm
                  hideForm={this.hideForm}
                  updateWeight={this.fetchWeight}
                />
              </Col>
            </Row>
          </Card>
        )}
        {this.state.recordCount > 0 && (
          <Card>
            {this.state.isLoggingWeight ? (
              <SubmitWeightForm
                updateWeight={this.fetchWeight}
                hideForm={this.hideForm}
              />
            ) : (
                <Row>
                  <Col xs={24} sm={24} style={{ marginBottom: "15px" }}>
                    <h4>{translation.WeightMetric.Instructions}</h4>
                    {this.state.loading ? (
                      <Spin size="large" />
                    ) : (
                        <Row>
                          {/* on mobile show the table */}
                          <Col xs={24} sm={0}>
                            <WeightTrackingTable weightData={this.props.weights} />
                          </Col>
                          {/* on everything bigger show the scatterplot */}
                          <Col xs={0} sm={24}>
                            {this.state.showTable ? (
                              <WeightTrackingTable />
                            ) : (
                                <WeightTrackingChart data={this.props.weights} />
                              )}
                          </Col>
                        </Row>
                      )}
                  </Col>
                  <Col xs={24} sm={12}>
                    <Row type="flex" justify="space-around">
                      <Col xs={24} sm={10}>
                        <CustomButton onClick={this.toggleForm}>
                          {i18n[lang].WeightMetric.LogWeight}
                        </CustomButton>
                      </Col>
                      <Col xs={0} sm={10}>
                        <CustomButton onClick={this.toggleTable}>
                          {this.state.showTable
                            ? `${i18n[lang].WeightMetric.ShowChart}`
                            : `${i18n[lang].WeightMetric.ShowTable}`}
                        </CustomButton>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
          </Card>
        )}
      </>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang,
  patientId: state.CaregiverReducer.patientcombos[0].pid,
  weights: state.CaregiverReducer.patientWeightTrack
});

const mapDispatch = {
  GetPatientWeight: caregiverHomeOperations.GetWeights
};

export default connect(mapState, mapDispatch)(WeightTracking);
