import React from "react";
import { Row, Col, Divider, Collapse } from "antd";
import styled from "styled-components";
import { number, array, string, object } from "prop-types";
import { connect } from "react-redux";
import { SplitLongWords } from "../../utils/transfomers/strings";
import { setBorderStylingForMobile } from "../../modules/trackBehaviorAssessment/utils";

// ANTD Imports
const { Panel } = Collapse;

const QuestionText = styled.div`
  font-size: 1rem;
  height: 3rem;
`;

const ResponseText = styled.div`
  font-size: 1rem;
`;

const Label = styled.div`
  font-weight: 550;
`;

/**
 * Renders the Daily Living Survey Submissions in Mobile
 * For the Provider View of a Patient
 */
class SurveySubmissionsMobile extends React.Component {
  render() {
    const { submissions, submissionsLen, i18n, lang } = this.props;

    // transform the text of the submissions
    submissions.forEach(submission => {
      submission.question_submission.map(data => {
        data.text = SplitLongWords(data.text);
      });
    });

    return (
      <>
        <Row>
          <Col>{this.props.instructions}</Col>
        </Row>
        <Row>
          <Collapse>
            {submissionsLen > 0 &&
              submissions.map(submission => (
                <Panel
                  key={submission.id}
                  header={`
              ${
                    i18n[lang].PrevAssessments.DateCreated
                    } ${submission.createdAt.format("MM-DD-YYYY")}
              `}
                >
                  {submission.question_submission.map(data => (
                    <div key={data.id}>
                      <Row>
                        <Col xs={24}>
                          <Label>
                            {lang === "en" ? "Survey Question" : "Pregunta"}
                          </Label>
                          <QuestionText>{data.text}</QuestionText>
                        </Col>
                        <Col xs={24}>
                          <Label>
                            {lang === "en" ? "Question Response" : "Respuesta"}
                          </Label>
                          <div style={setBorderStylingForMobile(data.response)}>
                            {lang === "en" ? data.response : data.response_es}
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </div>
                  ))}
                </Panel>
              ))}
          </Collapse>
        </Row>
      </>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});
const mapDispatch = {};

export default connect(mapState, mapDispatch)(SurveySubmissionsMobile);

SurveySubmissionsMobile.propTypes = {
  submissionsLen: number,
  submissions: array,
  lang: string,
  i18n: object
};
