import types, { CaregiverActions } from "./types";

/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */
export const GetPatientsSuccess = (payload): CaregiverActions => ({
  type: types.GET_PATIENTS_SUCCESS,
  payload: {
    patientcombos: payload.data,
    message: payload.message
  }
});

export const GetPatientsFailure = (err): CaregiverActions => ({
  type: types.GET_PATIENTS_FAIL,
  payload: {
    message: err.message
  }
});

export const GetProviderAction = (payload): CaregiverActions => ({
  type: types.GET_PROVIDER,
  payload: {
    provider: payload.data,
    message: "Provider found."
  }
});

export const GetProviderActionFail = (): CaregiverActions => ({
  type: types.GET_PROVIDER_FAIL,
  payload: {}
});

/**
 * Weight GET & POST related actions
 */
export const GetPatientWeightAction = (): CaregiverActions => ({
  type: types.GET_PATIENT_WEIGHT,
  payload: {}
});

export const GetWeightSuccessAction = (weights): CaregiverActions => ({
  type: types.GET_PATIENT_WEIGHT_SUCCESS,
  payload: {
    weights
  }
});

export const GetWeightFailAction = (error): CaregiverActions => ({
  type: types.GET_PATIENT_WEIGHT_FAIL,
  payload: { error }
});

export const PostWeightAction = (): CaregiverActions => ({
  type: types.POST_PATIENT_WEIGHT,
  payload: {}
});

export const PostWeightSuccessAction = (record): CaregiverActions => ({
  type: types.POST_PATIENT_WEIGHT_SUCCESS,
  payload: {
    record
  }
});

export const PostWeightFailAction = (error): CaregiverActions => ({
  type: types.POST_PATIENT_WEIGHT_FAIL,
  payload: {
    error
  }
});

export const DeleteWeightAction = (): CaregiverActions => ({
  type: types.DELETE_PATIENT_WEIGHT,
  payload: {}
});

export const DeleteWeightSuccessAction = (id): CaregiverActions => ({
  type: types.DELETE_PATIENT_WEIGHT_SUCCESS,
  payload: {
    id
  }
});

export const DeleteWeightFailAction = (error): CaregiverActions => ({
  type: types.DELETE_PATIENT_WEIGHT_FAIL,
  payload: {
    error
  }
});
