/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const GET_PATIENTS = "careheroes/dashboard/caregiver/GET_PATIENTS";
const GET_PATIENTS_SUCCESS =
  "careheroes/dashboard/caregiver/GET_PATIENTS_SUCCESS";
const GET_PATIENTS_FAIL = "careheroes/dashboard/caregiver/GET_PATIENTS_FAIL";
const GET_PROVIDER = "careheroes/dashboard/caregiver/GET_PROVIDER";
const GET_PROVIDER_FAIL = "careheroes/dashboard/caregiver/GET_PROVIDER_FAIL";
const GET_PATIENT_WEIGHT = "careheroes/dashboard/caregiver/GET_PATIENT_WEIGHTS";
const GET_PATIENT_WEIGHT_SUCCESS =
  "careheroes/dashboard/caregiver/GET_PATIENT_WEIGHT_SUCCESS";
const GET_PATIENT_WEIGHT_FAIL =
  "careheroes/dashboard/caregiver/GET_PATIENT_WEIGHT_FAIL";
const POST_PATIENT_WEIGHT =
  "careheroes/dashboard/caregiver/POST_PATIENT_WEIGHT";
const POST_PATIENT_WEIGHT_SUCCESS =
  "careheroes/dashboard/caregiver/POST_PATIENT_WEIGHT_SUCCESS";
const POST_PATIENT_WEIGHT_FAIL =
  "careheroes/dashboard/caregiver/POST_PATIENT_WEIGHT_FAIL";
const DELETE_PATIENT_WEIGHT =
  "careheroes/dashboard/caregiver/DELETE_PATIENT_WEIGHT";
const DELETE_PATIENT_WEIGHT_SUCCESS =
  "careheroes/dashboard/caregiver/DELETE_PATIENT_WEIGHT_SUCCESS";
const DELETE_PATIENT_WEIGHT_FAIL =
  "careheroes/dashboard/caregiver/DELETE_PATIENT_WEIGHT_FAIL";

type provider = {
  firstname: string;
  lastname: string;
  phone: string;
};

type patientcombo = {
  firstname: string;
  lastname: string;
  picture: string;
};

export interface CaregiverState {
  patientcombos: patientcombo[];
  message: string;
  error: string;
  provider: provider;
  patientWeightTrack: any[];
  loading: boolean;
}

/**
 * Not the right way to type Actions but I believe current version used of
 * typescript and immer does not allow TS Union types to be used.
 */
export interface CaregiverActions {
  type: string;
  payload: {
    patientcombos?: patientcombo[];
    message?: string;
    provider?: provider;
    weights?: any;
    error?: string;
    record?: any;
    id?: number;
    data?: any;
  };
}

export default {
  GET_PATIENTS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  GET_PROVIDER,
  GET_PROVIDER_FAIL,
  GET_PATIENT_WEIGHT,
  GET_PATIENT_WEIGHT_SUCCESS,
  GET_PATIENT_WEIGHT_FAIL,
  POST_PATIENT_WEIGHT,
  POST_PATIENT_WEIGHT_SUCCESS,
  POST_PATIENT_WEIGHT_FAIL,
  DELETE_PATIENT_WEIGHT,
  DELETE_PATIENT_WEIGHT_FAIL,
  DELETE_PATIENT_WEIGHT_SUCCESS
};
