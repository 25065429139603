import {
  GetPatientsSuccess,
  GetPatientsFailure,
  ResetComboAction,
  SetComboAction,
  SetPatientComboIDs,
  ClearPatientComboIDs,
} from "./actions";
import { getAllPatients } from "./utils";
import { message } from "antd";
import { inDebug } from "../../utils/buildmodes";

/**
 * To represent chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

export const GetProviderPatients = () => {
  return (dispatch) => {
    // Request from the backend the Provider's Patients
    getAllPatients()
      .then((payload) => {
        if (inDebug()) {
          message.success(payload.message);
        }
        dispatch(GetPatientsSuccess(payload));
      })
      .catch((error) => {
        // dispatch error
        if (inDebug()) {
          message.error(error.message);
        }
        dispatch(GetPatientsFailure(error));
      });
  };
};

export default {
  GetProviderPatients,
  SetComboAction,
  ResetComboAction,
  SetPatientComboIDs,
  ClearPatientComboIDs,
};
