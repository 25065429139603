import types from "./types";
/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const getTodosAction = (todos, message) => ({
  type: types.TodoPrescription_GET_TODOS,
  payload: {
    message,
    loading: true,
    todos
  }
});

export const submitTodoAction = () => ({
  type: types.TodoPrescription_SUBMIT_TODO,
  payload: {
    loading: true
  }
});

export const setTodosAction = todos => ({
  type: types.TodoPrescription_SET_TODOS,
  payload: {
    loading: false,
    todos
  }
});

export const removeTodoAction = id => ({
  type: types.TodoPrescription_REMOVE_TODO,
  payload: {
    id
  }
});

export const todoSuccessAction = message => ({
  type: types.TodoPrescription_SUCCESS,
  payload: {
    loading: false,
    message
  }
});

export const todoFailAction = message => ({
  type: types.TodoPrescription_FAIL,
  payload: {
    loading: false,
    message
  }
});

export const todoCompleteAction = message => ({
  type: types.TodoPrescription_COMPLETE_TODO,
  payload: {
    message
  }
});
