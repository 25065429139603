import {
  getTodosAction,
  submitTodoAction,
  todoSuccessAction,
  todoFailAction,
  setTodosAction,
  removeTodoAction
} from "./actions";

import {
  postPrescriptionTodo,
  getAllPrescriptionTodo,
  postPrescriptionTodoSubmission,
  postPageVisitOfPrescriptionTodo
} from "./utils";
import { message } from "antd";
/**
 * To represetn chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

export const createNewPrescriptionTodo = prescription => {
  return dispatch => {
    // set loading state to true
    dispatch(submitTodoAction);

    return postPrescriptionTodo(prescription)
      .then(response => {
        const { data, message: msg } = response;

        message.success(msg, 2.5);

        dispatch(todoSuccessAction);

        return true;
      })
      .catch(() => {
        dispatch(todoFailAction("Failed to submit a new prescription todo."));
        return false;
      });
  };
};

export const fetchPrescriptionTodos = (id, cb) => {
  return dispatch => {
    // set loading state to true
    dispatch(getTodosAction);

    return getAllPrescriptionTodo(id)
      .then(response => {
        const { data } = response;
        dispatch(setTodosAction(data));

        // invoke the optional callback, use it
        // to set some boolean that is taking care of
        // loading some status
        if (cb !== null && cb !== undefined) {
          cb(true);
        }
        return true;
      })
      .catch(() => {
        dispatch(todoFailAction("Failed to fetch prescriptions for user"));
      });
  };
};

/**
 *
 * Completes a Todo from CareHeroes RX
 * @param {number} id todo id
 * @param {string} type resource type
 * @param {string} url resource or survey url
 */
export const submitPrescriptionTodo = (id, type) => {
  return dispatch => {
    // set loading state to true
    dispatch(submitTodoAction);

    return postPrescriptionTodoSubmission(id)
      .then(() => {
        dispatch(todoSuccessAction);

        dispatch(removeTodoAction(id));
        return true;
      })
      .catch(() => {
        dispatch(todoFailAction("Failed to submit prescription todo for user"));
      });
  };
};

export const createPageVisit = (id, url) => {
  const payload = {
    visitedBy: id,
    url
  };
  return dispatch => {
    return postPageVisitOfPrescriptionTodo(payload)
      .then(response => {
        const { data, message } = response;
        return true;
      })
      .catch(() => {
        dispatch(todoFailAction("Failed to post page visit for todo"));
      });
  };
};

export default { createNewPrescriptionTodo, fetchPrescriptionTodos };
