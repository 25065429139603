import styled from "styled-components";
import { Col } from "antd";

export const SectionLabel = styled(Col)`
  display: flex;
  justify-content: center;

  @media (min-width: 768px) {
  }
`;

export const AddReminder = styled(Col)`
  display: flex;
  justify-content: center;
`;
