import { Avatar, Col, Row } from "antd";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import defaultAvatarSvg from "../../../assets/img/avatars/avatar.svg";
import { SetComboAction } from "../../../modules/providerHome/actions.ts";
import providerOps, {
  GetProviderPatients
} from "../../../modules/providerHome/operations.ts";
import { checkIfPatientCombosExist } from "../../../modules/providerHome/selectors.ts";
import { NoPatientsFound } from "./ProviderHome/NoPatientsFound";
import PatientComboList from "./ProviderHome/PatientComboList";
import { AccountControl } from "./ProviderHome/AccountControls.tsx";

class ProviderHome extends PureComponent {
  state = {
    patientcombos: [],
    message: "",
    loading: false,
    editMode: false
  };
  componentDidMount() {
    //Redux Way
    this.props.GetProviderPatients();
  }

  handleRegister = () => {
    this.props.history.push("/register");
  };

  handleAddProvider = () => {
    this.props.history.push("/dashboard/provider/register");
  };

  handleToggleEditMode = () => {
    this.setState(prevState => ({ editMode: !prevState.editMode }));
  };

  handlePatientClick = item => {
    // Not all Fields are used by ViewPatient, some are
    // needed for EditPatient component
    const combo = {
      caregiver: {
        firstname: item.caregiver.firstname,
        lastname: item.caregiver.lastname,
        picture: item.caregiver.picture,
        phone: item.caregiver.phone
      },
      patient: {
        firstname: item.patient.firstname,
        lastname: item.patient.lastname,
        picture: item.patient.picture,
        birthday: item.patient.birthday,
        gender: item.patient.gender,
        relationship: item.patient.relationship
      }
    };

    // Pass to the reducer the combo to display in the
    // next page
    this.props.SetComboAction(combo);

    // Keep track of their ID, needed for the next related routes
    this.props.SetPatientComboIDs(item.caregiver.id, item.patient.pid);

    // TODO: do this the redux way
    // DELETE THIS AFTER TESTING
    // const pId = JSON.stringify(item.patient.pid);
    // const cId = JSON.stringify(item.caregiver.id);
    // sessionStorage.setItem("cId", cId);
    // sessionStorage.setItem("pId", pId);

    // if in edit mode, go to edit caregiver patient combo page
    if (this.state.editMode) {
      this.props.history.push("/dashboard/provider/patient/edit");
    } else {
      // else go here
      this.props.history.push("/dashboard/provider/patient");
    }
  };

  render() {
    const setAvatar = () => {
      if (this.props.picture === "") {
        return defaultAvatarSvg;
      }

      return this.props.picture;
    };
    return (
      <>
        <Row style={{ marginBottom: 25 }}>
          <Row type="flex" justify="space-between" align="middle">
            <Col xs={0} md={6}>
              <Avatar size={164} src={setAvatar()} />
            </Col>
            <Col xs={24} md={12}>
              {!this.state.editMode && (
                <h2>Hi {`${this.props.firstname} ${this.props.lastname}`}</h2>
              )}
              {this.state.editMode && (
                <h2>Select Patient & Caregiver to Edit</h2>
              )}
            </Col>
            <Col
              xs={24}
              md={6}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              {checkIfPatientCombosExist(this.props.ProviderHome) && (
                <AccountControl
                  handleRegister={this.handleRegister}
                  handleEditPatient={this.handleToggleEditMode}
                  editModeEnabled={this.state.editMode}
                  handleAddProvider={this.handleAddProvider}
                />
              )}
            </Col>
          </Row>
        </Row>
        <Row>
          <Col span={24}>
            {checkIfPatientCombosExist(this.props.ProviderHome) ? (
              <PatientComboList
                handlePatientClick={this.handlePatientClick}
                list={this.props.patientcombos}
              />
            ) : (
                <NoPatientsFound handleRegister={this.handleRegister} />
              )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    ProviderHome: state.ProviderHome,
    patientcombos: state.ProviderHome.patientcombos,
    message: state.ProviderHome.message,
    firstname: state.User.firstname,
    lastname: state.User.lastname,
    picture: state.User.picture
  };
};

const mapDispatch = {
  GetProviderPatients,
  SetComboAction,
  SetPatientComboIDs: providerOps.SetPatientComboIDs
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatch
  )(ProviderHome)
);
