import React, { useState } from "react";
import { Row, Col, Button, message } from "antd";
import { useSelector } from "react-redux";
import { Form, Input } from "formik-antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { postPhoneNumber } from "../../../../../modules/refrigeratordoor/utils";

const AddContactSchema = Yup.object().shape({
  name: Yup.string().required("Contact Name is required"),
  number: Yup.string().required("Contact Phone Number is Required")
});

const AddContact = props => {
  const { i18n, lang } = useSelector(state => state.LangReducer);

  return (
    <div>
      <Formik
        initialValues={{
          name: "",
          number: "",
          notes: ""
        }}
        onSubmit={values => {
          postPhoneNumber(values)
            .then(data => {
              message.success("Success Creating Contact");
              props.toggleForm();
            })
            .catch(err => {
              message.error("Error Adding Contact");
            });
        }}
        validationSchema={AddContactSchema}
        validateOnBlur={false}
        validateOnChange={false}
        render={({ handleSubmit, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <h2>{`${i18n[lang].RefrigeratorDoor.ContactName}`}</h2>
              <Form.Item label="Name" name="name">
                <Input placeholder="Name" name="name" />
              </Form.Item>
              <Form.Item
                label={`${i18n[lang].RefrigeratorDoor.ContactPhoneNumber}`}
                name="number"
              >
                <Input style={{ width: "100%" }} name="number" />
              </Form.Item>
              <Form.Item label="Notes" name="notes">
                <Input.TextArea name="notes" />
              </Form.Item>
              <Row type="flex" justify="start">
                <Col>
                  <Button htmlType="submit">{`${i18n[lang].RefrigeratorDoor.AddContact}`}</Button>
                </Col>
                <Col style={{ marginLeft: "8px" }}>
                  <Button onClick={() => props.toggleForm()}>
                    {lang === "en" ? "Cancel" : "Cancelar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          );
        }}
      />
    </div>
  );
};

export default AddContact;
