import request from "../../utils/apis/request";

/**
 * POST request for creating a new prescription-todo
 */
export const postPrescriptionTodo = data => {
  return request({
    method: "post",
    url: "/api/todos",
    data
  });
};

/**
 * PUT request for updating a prescription-todo
 *
 * id: todoID
 */
export const putPrescriptionTodo = (id, data) => {
  return request({
    method: "put",
    url: `/api/todos/${id}`,
    data
  });
};

/**
 * GET request for a single prescription-todo
 *
 * id: todoID
 */
export const getOnePrescriptionTodo = id => {
  return request({
    method: "get",
    url: `/api/todos/${id}`
  });
};

/**
 * GET request for all prescription-todos of a user
 *
 * id: caregiverID
 */
export const getAllPrescriptionTodo = id => {
  return request({
    method: "get",
    url: `api/todos/users/${id}`
  });
};

/**
 * DELETE request for deleting a single prescription todo
 *
 * id: todoID
 */
export const deletePrescriptionTodo = id => {
  return request({
    method: "delete",
    url: `api/todos/${id}`
  });
};

/**
 * POST request for submitting a prescription todo
 *
 * id: todoID
 */
export const postPrescriptionTodoSubmission = (id, data) => {
  return request({
    method: "post",
    url: `/api/todos/submit/${id}`,
    data
  });
};

/**
 * POST request for tracking a page visit related to a prescription todo
 */
export const postPageVisitOfPrescriptionTodo = data => {
  return request({
    method: "post",
    url: "/api/todos/page-visit",
    data
  });
};

export const formatFrequency = frequency => {
  switch (frequency) {
    case 1:
      return "Once";
    case 2:
      return "Twice";
    case 3:
      return "Three Times";
    case 4:
      return "Four Times";
    case 5:
      return "Five Times";
    default:
      return frequency;
  }
};
