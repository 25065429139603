import types from "./types";
import { IAction } from "../../interfaces/modules";
/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const LoadUser = (user: any): IAction => ({
  type: types.User_LOAD,
  payload: user
});

export const ResetUser = (): IAction => ({
  type: types.User_RESET,
  payload: {}
});
