import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";

class DailyLivingInstructions extends React.Component {
  render() {
    const { i18n, lang } = this.props;
    return (
      <Row>
        <Col span={24}>
          <h2>{i18n[lang].ActivitiesQ.Title}</h2>
          <h3>{i18n[lang].Assessments.LanguageInstructions}</h3>
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapState)(DailyLivingInstructions);
