import React, { useState, useEffect } from "react";

/**
 * Custom Hook for Detecting Window Width
 *
 * Useful for rendering content conditionally based on window size
 * @param mobileMaxWidth
 * @param tabletMaxWidth
 */
const useWindowWidth = (mobileMaxWidth?, tabletMaxWidth?) => {
  const [width, setWidth] = useState(window.innerWidth || 0);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    const handleResizeWidth = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWidth);

    if (width <= (mobileMaxWidth || 468)) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    if (width > (mobileMaxWidth || 468) && width <= (tabletMaxWidth || 768)) {
      setIsTablet(true);
    } else {
      setIsTablet(false);
    }
    return () => {
      window.removeEventListener("resize", handleResizeWidth);
    };
  });

  return {
    isMobile: isMobile,
    isTablet: isTablet,
    width: width
  };
};

export default useWindowWidth;
