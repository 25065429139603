import * as React from "react";
import { useState } from "react";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../components/CustomButton/CustomButton";
import EnglishResetForm from "./change_password_form/EnglishResetForm";
import SpanishResetForm from "./change_password_form/SpanishResetForm";
import TranslationButtons from "components/TranslationButtons";

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Must have at least 8 characters")
    .matches(/(?=.*?[A-Z])/, "Must have at least one upper case.")
    .matches(/(?=.*?[a-z])/, "Must have at least one lowercase letter.")
    .matches(/(?=.*?[0-9])/, "Must have at least 1 digit.")
    .matches(
      /(?=.*?[#?!@$%^&*-])/,
      "Must have at least one of the following special characters #?!@$%^&*-"
    )
    .required("Password Required"),
  confirmpassword: Yup.string()
    .required("Type in your password again.")
    .test("passwords-match", "Passwords do not match.", function(value) {
      return this.parent.password === value;
    })
});
const ChangePasswordForm = ({ handlePasswordChange, handleLogin }) => {
  const [userSubmittedForm, setUserSubmittedForm] = useState<boolean>(false);
  const lang = useSelector(state => state.LangReducer.lang);

  if (!userSubmittedForm) {
    if (lang === "en") {
      return (
        <EnglishResetForm
          handlePasswordChange={handlePasswordChange}
          setUserSubmittedForm={setUserSubmittedForm}
        />
      );
    } else {
      return (
        <SpanishResetForm
          handlePasswordChange={handlePasswordChange}
          setUserSubmittedForm={setUserSubmittedForm}
        />
      );
    }
  } else {
    return (
      <>
        <TranslationButtons />
        <Row>
          <Col>
            {lang === "en" ? "Reset Password" : "Cambiar la contraseña"}
          </Col>
        </Row>
        <Row>
          <Col>
            {lang === "en"
              ? "Click on the button to go back and login."
              : "Haga clic en el botón para regresar e iniciar sesión."}
            <CustomButton block type="primary" onClick={handleLogin}>
              Login
            </CustomButton>
          </Col>
        </Row>
      </>
    );
  }
};

export default ChangePasswordForm;
