import types from "./types";
/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const submitZarit = score => ({
  type: types.ZARIT_SUBMIT,
  payload: {
    score: score,
    showResponse: true
  }
});

export const SubmitZaritSuccessAction = (message, score) => ({
  type: types.ZARIT_SUCCESS,
  payload: {
    score,
    message,
    loading: false,
    showResponse: true
  }
});

export const ZaritFailAction = message => ({
  type: types.ZARIT_FAIL,
  payload: {
    loading: false,
    message
  }
});

export const SetZaritQuestionsAction = (questions, message) => ({
  type: types.ZARIT_SET_QUESTIONS,
  payload: {
    message,
    questions,
    loading: false
  }
});

export const GetZaritQuestionsAction = message => ({
  type: types.ZARIT_GET_QUESTIONS,
  payload: {
    loading: true,
    message: message
  }
});

export const viewZarit = () => ({
  type: types.ZARIT_VIEW,
  payload: {
    showResponse: false,
    score: 0
  }
});

export const viewZaritResults = () => ({
  type: types.ZARIT_VIEW_RESULTS,
  payload: {}
});

export const routeChangeZarit = () => ({
  type: types.ZARIT_ROUTE_CHANGE
});

export const GetZaritSubmissionsAction = message => ({
  type: types.ZARIT_GET_PAST_SUBMISSIONS,
  payload: {
    loading: true,
    message
  }
});

export const SetZaritSubmissionsAction = (data, message) => ({
  type: types.ZARIT_SET_PAST_SUBMISSIONS,
  payload: {
    submissions: data,
    message,
    loading: false
  }
});
