import Fulllayout from "../layouts/fulllayout";
import Blanklayout from "../layouts/blanklayout";
import { NoMatch } from "../layouts/NoMatch";

const indexRoutes = [
  { path: "/auth", name: "Authentication", component: Blanklayout },
  { path: "/", name: "Home", component: Fulllayout },
  { path: "*", name: "404", component: NoMatch }
];

export default indexRoutes;
