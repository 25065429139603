import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { func, number, object, array, string } from "prop-types";

class PaocResponse extends React.Component {
  handleClick = e => {
    e.preventDefault();

    this.props.history.push("/dashboard");
  };

  render() {
    const { i18n, lang } = this.props;

    return (
      <Row type="flex" justify="center">
        <Col span={24} style={{ textAlign: "center" }}>
          <h4>{i18n[lang].AssessmentResponses.Paoc.Response1}</h4>
          {this.props.score >= 27 ? (
            <h5>{i18n[lang].AssessmentResponses.Paoc.Response2_Positive}</h5>
          ) : (
            <h5>{i18n[lang].AssessmentResponses.Paoc.Response2_Negative}</h5>
          )}
          <h5>{i18n[lang].AssessmentResponses.Paoc.Response3}</h5>
          <h5>{i18n[lang].AssessmentResponses.Paoc.Response4}</h5>
          <p>{i18n[lang].AssessmentResponses.Buttons.Instructions}</p>
        </Col>
        <Col>
          <CustomButton onClick={this.handleClick}>
            {i18n[lang].AssessmentResponses.Buttons.Label}
          </CustomButton>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  score: state.PositiveAspectsReducer.score,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

PaocResponse.propTypes = {
  i18n: array.isRequired,
  lang: string.isRequired,
  score: number,
  history: func.isRequired
};

export default connect(mapStateToProps)(PaocResponse);
