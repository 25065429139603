import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { withGetScreen } from "react-getscreen";
import { GetTrackBehaviorSubmissions } from "../../../../../../modules/trackBehaviorAssessment/operations";
import SurveySubmissions from "../../../../../../components/SurveySubmissions";

class BehaviorTable extends React.Component {
  state = {
    displayedSubmissions: [],
    leftIndex: -1,
    rightIndex: -1,
  };

  componentDidMount() {
    //TODO: get this caregiver id via redux
    // const cId = sessionStorage.getItem("cId");
    // const userIdPayload = { uid: JSON.parse(cId) };
    const userIdPayload = { uid: this.props.caregiverId };

    this.props.getSubmissions(userIdPayload).then((res) => {
      const { submissions } = this.props;
      const length = submissions.length;
      let latest = [];
      let leftIndex = -1;
      let rightIndex = -1;
      if (length > 2) {
        latest = submissions.slice(length - 3);
        leftIndex = length - 3;
        rightIndex = length - 1;
      } else if (length === 2) {
        latest = submissions.slice(length - 2);
        leftIndex = length - 2;
        rightIndex = length - 1;
      } else if (length === 1) {
        latest = submissions.slice(length - 1);
        leftIndex = length - 1;
        rightIndex = length - 1;
      }

      this.setState({
        displayedSubmissions: latest,
        leftIndex,
        rightIndex,
      });
    });
  }

  /**
   * Allows the user to shift through the submissions
   * Children will take care of sending new left and right indeces
   */
  handleIndexShift = (leftIndex, rightIndex) =>
    this.setState({ leftIndex, rightIndex });

  render() {
    const { leftIndex, rightIndex } = this.state;
    if (this.props.loading) {
      // Display the spinner
      return <div>Loading</div>;
    } else {
      // No longer Loading
      // if fetched submissions length greater than 0 and is different from default
      if (
        this.props.submissions.length > 0 &&
        this.props.submissions[0].id !== -1
      ) {
        return (
          <SurveySubmissions
            submissions={this.props.submissions}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            handleIndexShift={this.handleIndexShift}
            surveyName='MemoryBehavior'
          >
            <>
              <h4>Previous Submissions:</h4>
              <p>
                The following table displays the data of your previous
                submissions. At most three surveys will be displayed. If there
                exist more, use the arrows to navigate through them.
              </p>
            </>
          </SurveySubmissions>
        );
      } else {
        // else display message indicating there are no submissions
        return (
          <Row type='flex' justify='center' align='middle'>
            <Col>
              <h3>No Previous Submissions Found</h3>
            </Col>
          </Row>
        );
      }
    }
  }
}

const mapState = (state) => ({
  loading: state.TrackBehavior.loading,
  submissions: state.TrackBehavior.submissions,
  caregiverId: state.ProviderHome.caregiverId,
});

const mapDispatch = {
  getSubmissions: GetTrackBehaviorSubmissions,
};

export default connect(
  mapState,
  mapDispatch
)(withGetScreen(BehaviorTable));
