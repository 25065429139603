import LoginPage from "../pages/LoginPage";
import RecoverPassword from "../pages/RecoverPassword";

const authRoutes = [
  {
    path: "/auth/login",
    name: "Login",
    icon: "mdi mdi-account-key",
    component: LoginPage
  },
  {
    path: "/auth/recover-pwd",
    name: "Recover Password",
    icon: "mdi mdi-account-convert",
    component: RecoverPassword
  },
  {
    path: "/auth/change-pwd",
    name: "Recover Password",
    icon: "mdi mdi-account-convert",
    component: null
  }
];

export const allowedAuthRoutes = [
  {
    path: "/auth/login",
    name: "Login",
    icon: "mdi mdi-account-key",
    component: null
  },
  {
    path: "/auth/register",
    name: "Register",
    icon: "mdi mdi-account-plus",
    component: null
  },
  {
    path: "/auth/recover-pwd",
    name: "Recover Password",
    icon: "mdi mdi-account-convert",
    component: null
  },
  {
    path: "/auth/signup/client",
    name: "Client Signup",
    icon: "mdi mdi-account-convert",
    component: null
  }
];
export default authRoutes;
