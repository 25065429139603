import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "antd";

class PaocInstructions extends React.Component {
  render() {
    const { i18n, lang } = this.props;

    return (
      <Row>
        <Col span={24}>
          <h2>
            {lang === "en"
              ? "Are you wondering how you are doing as a Caregiver?"
              : "¿Te preguntas cómo te va como cuidador/a?"}
          </h2>
          <h2> {i18n[lang].PaocQ.Subtitle}</h2>
          <h3>{i18n[lang].Assessments.LanguageInstructions}</h3>
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapState)(PaocInstructions);
