import React from "react";
import CaregiverHome from "../../pages/caregiver/dashboard/CaregiverHome";
import ProviderHome from "../../pages/provider/dashboard/ProviderHome";
import { Col, Row } from "antd";
import { connect } from "react-redux";
import { getUserAccount } from "../../utils/apis/Authentication/auth.service.js";
import AdminHome from "../../pages/admin/AdminHome";
import { Redirect } from 'react-router-dom';


class Dashboard extends React.Component {
  state = {
    role: null
  };

  componentDidMount() {
    const user = getUserAccount();
    if (user) {
      this.setState({
        role: user.role
      });
    } else {
      this.props.history.push("/auth/login");
    }
  }
  render() {
    const DisplayHome = role => {
      switch (role) {
        case "PROVIDER":
          return <ProviderHome />;
        case "CAREGIVER":
          return <CaregiverHome />;
        case "ADMIN":
          return <AdminHome />;
        default:
          return (
            <Redirect
              to={{
                pathname: "/auth/login"
              }} />
          )
      }
    };

    return (
      <div>
        <Row>
          <Col span={24}>{DisplayHome(this.props.auth.role)}</Col>
        </Row>
      </div>
    );
  }
}

/**Redux Config */
const mapStateToProps = state => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(Dashboard);
