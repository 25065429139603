import React, { useState, useEffect } from "react";
import { Row, Col, Card, List, Skeleton } from "antd";
import TaskDescription from "./TaskPrescription/TaskDescription";
import { useSelector, useDispatch } from "react-redux";
import { fetchPrescriptionTodos } from "../../../../modules/todo-prescription/operations";
import { withGetScreen } from "react-getscreen";
import useWindowWith from "../../../../hooks/useWindowWidth";
import { func, string, object, array } from "prop-types";
import { number } from "prop-types";
import { withRouter, useHistory } from "react-router-dom";

/**
 * CareHeroes Rx
 */
const TaskPrescription = () => {
  const [dataFetched, setDataFetched] = useState(false);
  const history = useHistory();

  // custom hook for detecting windowWidth
  const { isMobile } = useWindowWith();
  /**
   * Everything retrieved via useSelector is from Redux
   */
  const patientId = useSelector(
    state => state.CaregiverReducer.patientcombos[0].pid
  );
  const todos = useSelector(state => state.PrescriptionTodoReducer.todos);
  const { i18n, lang } = useSelector(state => state.LangReducer);
  const providerLast = useSelector(
    state => state.CaregiverReducer.provider.lastname
  );
  const dispatch = useDispatch();

  /**
   * Tasks: Fetch all the Tasks for the Caregiver
   */

  useEffect(() => {
    dispatch(fetchPrescriptionTodos(patientId, setDataFetched));
  }, []);

  const handleTitleClick = todo => {
    const { type, url } = todo;

    if (type === "RESOURCE") {
      //push onto the router /Resources
      history.push("/Resources");
    } else if (type === "SURVEY") {
      // push onto the router the survey url
      history.push(url);
    } else if (type === "VIDEO") {
      // push onto the router the videos route
      history.push("/Videos");
    } else if (type === "CHATBOT") {
      history.push("/contact");
    }
  };

  return (
    <Row>
      <Row>
        <Col>
          <h3>{i18n[lang].RxTodoPanel.Instruction}</h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            {!dataFetched && <Skeleton active />}
            {dataFetched && (
              <>
                <h4>{`${i18n[lang].RxTodoPanel.HelpTextPart1} ${providerLast} ${i18n[lang].RxTodoPanel.HelpTextPart2}`}</h4>
                <List
                  itemLayout="vertical"
                  dataSource={todos}
                  renderItem={(todo: any) => (
                    <List.Item
                      key={todo.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                    >
                      <List.Item.Meta
                        title={
                          <div onClick={() => handleTitleClick(todo)}>
                            {lang === "en" ? todo.body : todo.bodyEs}
                          </div>
                        }
                        description={
                          <TaskDescription
                            id={todo.id}
                            url={todo.url}
                            type={todo.type}
                            frequency={todo.frequency}
                            count={todo.submissionCount}
                            description={
                              isMobile
                                ? null
                                : lang === "en"
                                ? todo.title
                                : todo.titleEs
                            }
                            dueDate={todo.dueDate}
                          />
                        }
                      />
                    </List.Item>
                  )}
                />
              </>
            )}
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

export default TaskPrescription;
