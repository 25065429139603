import request from "../../utils/apis/request";

/**
 * Returns an array of objects.
 * Each object has a caregiver object, and 1..N patient objects associated
 * to the caregiver.
 * @param {Array} pids an array of patient ids
 */
export const getAllPatients = () => {
  return request({
    method: "get",
    url: "/api/users/get-patients-provider",
  });
};

/**
 * Updates the patient data
 * firstname, lastname, relationship, birthday, gender, and picture
 * @param {*} patientId
 * returns 204 on success
 */
export const updatePatient = (patientId, patientFields) => {
  return request({
    method: "put",
    url: "/api/users/patients/" + patientId,
    data: patientFields,
  });
};
