import React from "react";
import indexRoutes from "./routes";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import IdleTimer from "react-idle-timer";
import "react-toastify/dist/ReactToastify.css";
import { NoMatch } from "./layouts/NoMatch";
import { logout } from "./utils/apis/Authentication/auth.service";
type AppProps = {
  history: any;
};

class App extends React.Component<AppProps, {}> {

  idleTimer = null;
  /**
   * See timeout prop of component
   * Method invoked after three hours
   */
  onIdle = async () => {
    await logout().catch(() => console.log("err t_logging idle"));
    sessionStorage.clear();
    this.props.history.push("/auth/login");
  };

  render() {
    return (
      <div style={{ minHeight: "100vh" }}>
        {/* 
        //@ts-ignore */}
        <ToastContainer />
        {/* 
        //@ts-ignore */}
        <IdleTimer
          ref={(ref) => (this.idleTimer = ref)}
          element={document}
          onIdle={this.onIdle}
          debounce={25}
          timeout={1000 * 60 * 60 * 3}
        />
        <Switch>
          <Route
            exact
            path="/"
            component={() => <Redirect to="/dashboard" />}
          />
          {indexRoutes.map((prop, key) => {
            return (
              <Route path={prop.path} key={key} component={prop.component} />
            );
          })}
          <Route component={NoMatch} />
        </Switch>
      </div>
    );
  }
}

export default withRouter(App);
