import React, { useState } from "react";
import { Row } from "antd";
import ContactList from "./ContactInfo/ContactList";
import AddContact from "./ContactInfo/AddContact";

const ContactInfo = () => {
  const [addingContact, setAddingContact] = useState(false);

  /**
   * Toggles either the List or the Form to display
   */
  const handlePrescriptionToggle = () => setAddingContact(!addingContact);

  return (
    <Row>
      {addingContact ? (
        <AddContact toggleForm={handlePrescriptionToggle} />
      ) : (
        <ContactList toggleForm={handlePrescriptionToggle} />
      )}
    </Row>
  );
};

export default ContactInfo;
