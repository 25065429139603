import { combineReducers } from "redux";
import authReducer from "./auth";
import phqReducer from "./phqAssessment";
import zaritReducer from "./zaritAssessment";
import TrackBehavior from "./trackBehaviorAssessment";
import ProviderHome from "./providerHome";
import UserReducer from "./user";
import DailyLivingReducer from "./dailyLiving";
import CaregiverReducer from "./caregiver";
import PositiveAspectsReducer from "./positiveAspectsAssessment";
import PrescriptionTodoReducer from "./todo-prescription";
import LangReducer from "./translation";
import SideMenuReducer from "./sidemenu";

export default history =>
  combineReducers({
    auth: authReducer,
    phq: phqReducer,
    zarit: zaritReducer,
    TrackBehavior,
    ProviderHome,
    User: UserReducer,
    DailyLivingReducer,
    CaregiverReducer,
    PositiveAspectsReducer,
    PrescriptionTodoReducer,
    LangReducer,
    SideMenuReducer
  });
