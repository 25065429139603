import React from "react";
// import { Row } from "antd";
import { Row, Col, Button } from "antd";
import { createPageVisit } from "../modules/todo-prescription/operations";
import { connect } from "react-redux";

class Videos extends React.Component {
  handleLinkClick = e => {
    e.preventDefault();
    const url = e.target.href;
    this.props.createPageVisit(this.props.cId, e.target.href);
    window.open(url, "_blank");
  };
  render() {
    const { i18n, lang } = this.props;

    return (
      <div className="content">
        <Row>
          <Col span={24}>
            <h1>{i18n[lang].Videos.PageTitle}</h1>
            <h4>{i18n[lang].Videos.PageDescription}</h4>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <p>{i18n[lang].Videos.VidDescription1}</p>
          </Col>
          <Col span={10}>
            {lang === "en" && (
              <Button
                onClick={this.handleLinkClick}
                target="_blank"
                href="https://mediaweb.fiu.edu/Mediasite/Play/74629881b2c1442b9de5d00ecab45f4d1d"
              >
                {i18n[lang].Videos.ViewVid}
              </Button>
            )}
            {lang === "es" && (
              <Button
                onClick={this.handleLinkClick}
                target="_blank"
                href="https://mediaweb.fiu.edu/Mediasite/Play/5c4f7144d67d49fea0239fcc797a41971d"
              >
                {i18n[lang].Videos.ViewVid}
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <p>{i18n[lang].Videos.VidDescription2}</p>
          </Col>
          <Col span={10}>
            {lang === "en" && (
              <Button
                onClick={this.handleLinkClick}
                target="_blank"
                href="https://mediaweb.fiu.edu/Mediasite/Play/d1e3a023a85a44e183d26463d29b86911d"
              >
                {i18n[lang].Videos.ViewVid}
              </Button>
            )}
            {lang === "es" && (
              <Button
                onClick={this.handleLinkClick}
                target="_blank"
                href="https://mediaweb.fiu.edu/Mediasite/Play/6e1418cb6cb049ae8228ae0c9a286a5d1d"
              >
                {i18n[lang].Videos.ViewVid}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang,
  cId: state.User.id
});

const mapDispatch = {
  createPageVisit
};

export default connect(
  mapState,
  mapDispatch
)(Videos);
