import React from "react";
import { Row, Col, List, Avatar, Button, Spin } from "antd";
import { withGetScreen } from "react-getscreen";
import { connect } from "react-redux";
import {
  fetchRefrigeratorDoorData,
  deletePhoneNumber
} from "../../../../../modules/refrigeratordoor/utils";

class ContactList extends React.Component {
  state = {
    data: [],
    contacts: [],
    dataFetched: false
  };

  /**
   * Removes a Contact defailt from the current list
   *
   *
   */
  removeContact = id => {
    deletePhoneNumber(id)
      .then(number => {
        this.setState(prevState => ({
          contacts: prevState.contacts.filter(r => r.id !== id)
        }));
      })
      .catch(err => console.log("error removing contact"));
  };

  /**
   * Fetch the Reminders from the backend
   */
  componentDidMount() {
    fetchRefrigeratorDoorData(this.props.caregiverId, this.props.patientId)
      .then(response => {
        this.setState({
          contacts: response.data.phone_numbers,
          dataFetched: true
        });
      })
      .catch(err => {
        this.setState({
          dataFetched: true
        });
      });
  }

  render() {
    const { i18n, lang } = this.props;

    return (
      <>
        <Row>
          <Col
            xs={24}
            md={12}
            style={
              this.props.isMobile()
                ? {
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center"
                }
                : {}
            }
          >
            <h3>{i18n[lang].RefrigeratorDoor.ContactList}</h3>
            <h4>{i18n[lang].RefrigeratorDoor.ContactListInstruction}</h4>
          </Col>
          <Col
            xs={24}
            md={{ span: 12, push: 7 }}
            style={
              this.props.isMobile()
                ? { display: "flex", justifyContent: "center" }
                : {}
            }
          >
            <Button onClick={this.props.toggleForm}>
              {i18n[lang].RefrigeratorDoor.AddContact}
            </Button>
          </Col>
        </Row>
        {!this.state.dataFetched && (
          <Row type="flex" justify="center">
            <Col>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <h5>Fetching Contacts</h5>
                <Spin size="large" />
              </div>
            </Col>
          </Row>
        )}
        {this.state.dataFetched && (
          <Row>
            <Col>
              <List
                itemLayout="horizontal"
                dataSource={this.state.contacts}
                renderItem={item => (
                  <List.Item
                    key={item.id}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <List.Item.Meta
                      title={
                        <div>
                          <h4>{item.name}</h4>
                        </div>
                      }
                      description={
                        <div>
                          <h5>{item.number}</h5>
                          <div>
                            {lang === "en" ? "Notes" : "Notas"}:{" "}
                            <span>{item.notes}</span>
                          </div>
                        </div>
                      }
                    />

                    <Button onClick={() => this.removeContact(item.id)}>
                      {i18n[lang].RefrigeratorDoor.Remove}
                    </Button>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang,
  caregiverId: state.User.id,
  patientId: state.CaregiverReducer.patientcombos[0].pid
});

export default connect(mapState)(withGetScreen(ContactList));
