import React from "react";
import { Row, Col, Icon } from "antd";
import CustomButton from "../CustomButton/CustomButton";
import { number, array, func, object, string } from "prop-types";

/**
 * Buttons for Navigating Back and Forth
 * In the SurveySubmissions component
 * @param {*} props 
 */
export const AssessmentButtons = props => {
  const {
    i18n,
    lang,
    leftIndex,
    rightIndex,
    handleLeftShift,
    handleRightShift,
    submissions
  } = props;
  return (
    <Row type="flex" justify="end" style={{ marginBottom: 15 }}>
      <Col>
        <CustomButton
          onClick={handleLeftShift}
          size="large"
          type="primary"
          disabled={leftIndex === 0}
        >
          <Icon type="left" />
          {i18n[lang].PrevAssessments.Previous}
        </CustomButton>
        <CustomButton
          onClick={handleRightShift}
          size="large"
          type="primary"
          disabled={rightIndex === submissions.length - 1}
        >
          {i18n[lang].PrevAssessments.Next}
          <Icon type="right" />
        </CustomButton>
      </Col>
    </Row>
  );
};

AssessmentButtons.propTypes = {
  leftIndex: number,
  rightIndex: number,
  handleLeftShift: func,
  handleRightShift: func,
  submissions: array,
  i18n: object,
  lang: string
}