import request from "../../utils/apis/request";

export const getPhq2Survey = () => {
  return request({
    url: "/api/surveys/phq2",
    method: "get"
  });
};

export const postPhq2Survey = data => {
  return request({
    url: "/api/surveys/phq2",
    method: "post",
    data
  });
};

export const getPhq2Submissions = () => {
  return request({
    url: "/api/surveys/submissions/phq2",
    method: "post"
  });
};
