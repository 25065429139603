import React from "react";
import { connect } from "react-redux";
import { withGetScreen } from "react-getscreen";
import { GetTrackBehaviorSubmissions } from "../../../modules/trackBehaviorAssessment/operations";
import SurveySubmissions from "../../../components/SurveySubmissions";
import { Row, Col } from "antd";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { GetScreenLimits } from "../../../utils/styling/MediqQueryGenerator";

class TrackBehaviorSubmissions extends React.Component {
  state = {
    displayedSubmissions: [],
    leftIndex: -1,
    rightIndex: -1
  };

  componentDidMount() {
    this.props.getSubmissions().then(res => {
      const { submissions } = this.props;
      const length = submissions.length;
      let latest = [];
      let leftIndex = -1;
      let rightIndex = -1;
      if (length > 2) {
        latest = submissions.slice(length - 3);
        leftIndex = length - 3;
        rightIndex = length - 1;
      } else if (length === 2) {
        latest = submissions.slice(length - 2);
        leftIndex = length - 2;
        rightIndex = length - 1;
      } else if (length === 1) {
        latest = submissions.slice(length - 1);
        leftIndex = length - 1;
        rightIndex = length - 1;
      }

      this.setState({
        displayedSubmissions: latest,
        leftIndex,
        rightIndex
      });
    });
  }

  handleGoBack = () => this.props.history.goBack();

  /**
   * Allows the user to shift through the submissions
   * Children will take care of sending new left and right indeces
   */
  handleIndexShift = (leftIndex, rightIndex) =>
    this.setState({ leftIndex, rightIndex });

  render() {
    const { leftIndex, rightIndex } = this.state;
    const { i18n, lang, isMobile } = this.props;

    if (this.props.loading) {
      // Display the spinner
      return <div>Loading</div>;
    } else {
      // No longer Loading
      // if fetched submissions length greater than 0 and is different from default
      if (
        this.props.submissions.length > 0 &&
        this.props.submissions[0].id !== -1
      ) {
        return (
          <SurveySubmissions
            submissions={this.props.submissions}
            leftIndex={leftIndex}
            rightIndex={rightIndex}
            handleIndexShift={this.handleIndexShift}
          >
            <>
              <h4>{i18n[lang].PrevAssessments.Title}</h4>
              <p>
                {isMobile()
                  ? i18n[lang].PrevAssessments.InstructionsMobile
                  : i18n[lang].PrevAssessments.Instructions}
              </p>
            </>
          </SurveySubmissions>
        );
      } else {
        // else display message indicating there are no submissions
        return (
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ height: "100vh" }}
          >
            <Col>
              <h3>{i18n[lang].PrevAssessments.NoneFound}</h3>
              <CustomButton block onClick={this.handleGoBack} type="primary">
                {i18n[lang].PrevAssessments.GoBack}
              </CustomButton>
            </Col>
          </Row>
        );
      }
    }
  }
}

const mapState = state => ({
  loading: state.TrackBehavior.loading,
  submissions: state.TrackBehavior.submissions,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatch = {
  getSubmissions: GetTrackBehaviorSubmissions
};

export default connect(
  mapState,
  mapDispatch
)(withGetScreen(TrackBehaviorSubmissions, GetScreenLimits));
