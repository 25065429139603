import types from "./types";
import { IAction } from "../../interfaces/modules";

/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

/**
 * Resets the current patient-caregiver combo fields
 */
export const ResetComboAction = (): IAction => ({
  type: types.RESET_CURRENT_COMBO,
});

/**
 * Invoked as part of sequence of events after a provider clicks on
 * a patient-caregiver combo from their dashboard
 * @param {object} combo {caregiver:{}, patient: {]}
 */
export const SetComboAction = (combo): IAction => ({
  type: types.SET_CURRENT_COMBO,
  payload: {
    combo,
  },
});

export const GetPatientsSuccess = (payload): IAction => ({
  type: types.GET_PATIENTS_SUCCESS,
  payload: {
    patientcombos: payload.data,
    message: payload.message,
  },
});

export const GetPatientsFailure = (err): IAction => ({
  type: types.GET_PATIENTS_FAIL,
  payload: {
    patientcombos: [],
    message: err.message,
  },
});

export const SelectComboListAction = (key, label): IAction => ({
  type: types.SELECT_COMBO_LIST,
  payload: {
    key,
    label,
  },
});

export const ResetComboListAction = (): IAction => ({
  type: types.RESET_COMBO_LIST,
});

// Used when selecting a patient combo where the provider's list
export const SetPatientComboIDs = (
  caregiverId: number,
  patientId: number
): IAction => ({
  type: types.SET_CAREGIVER_AND_PATIENT_ID,
  payload: {
    caregiverId,
    patientId,
  },
});

export const ClearPatientComboIDs = (): IAction => ({
  type: types.CLEAR_CAREGIVER_AND_PATIENT_ID,
});
