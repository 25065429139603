import React from "react";
import { Redirect } from 'react-router-dom';

/**
 * Serves as a simple redirect,
 * Could be developed further
 * @param {*} props 
 */
export const NoMatch = props => {
  return <div>404
    <Redirect to="/auth/login" />
  </div>
};