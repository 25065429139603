import React from "react";
import { Row, Col, List, Avatar, Divider } from "antd";
import { format } from "date-fns";

/**
 * HOC for Managing the Contents of the Refrigerator Door
 * that the Provider is Viewing of the Patient
 */
class ProviderRefrigeratorDoor extends React.Component {
  render() {
    const { prescriptions, reminders, phoneNumbers } = this.props;

    return (
      <>
        <Row>
          <Col>
            <h2>Patient's Refrigerator Door</h2>
            <p>
              The refrigerator door includes three types of resources related to
              the patient. Pictures or descriptions of medication they are
              consuming, Reminders the caregiver has created, and important
              contacts.
            </p>
          </Col>
        </Row>
        <Divider />
        {/* <Row style={{ marginBottom: 15 }}>
          <Col>
            <h3>Prescriptions</h3>
            <List
              itemLayout="horizontal"
              dataSource={prescriptions}
              renderItem={item => (
                <List.Item
                  key={item.id}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <List.Item.Meta
                    avatar={<Avatar size={128} src={item.prescriptionImage} />}
                    title={<div>{item.name}</div>}
                    description={<div>{item.notes}</div>}
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Divider /> */}
        <Row style={{ marginBottom: 15 }}>
          <Col>
            <h3>Reminders</h3>
            <List
              itemLayout="horizontal"
              dataSource={reminders}
              renderItem={item => (
                <List.Item
                  key={item.id}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <h4>{item.text}</h4>
                      </div>
                    }
                    description={
                      <div>
                        <h5>
                          Date Created:{" "}
                          {format(new Date(item.createdAt), "MM/DD/YYYY")}
                        </h5>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
        <Divider />
        <Row style={{ marginBottom: 15 }}>
          <Col>
            <h3>Phone Numbers</h3>
            <List
              itemLayout="horizontal"
              dataSource={phoneNumbers}
              renderItem={item => (
                <List.Item
                  key={item.id}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <h4>{item.name}</h4>
                      </div>
                    }
                    description={
                      <div>
                        <h5>{item.number}</h5>
                        <div>
                          Notes: <span>{item.notes}</span>
                        </div>
                      </div>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default ProviderRefrigeratorDoor;
