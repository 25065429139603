import React, { useState } from "react";
import { Row } from "antd";
import RemindersList from "./Reminders/ReminderList";
import AddReminder from "./Reminders/AddReminder";

const Reminders = () => {
  const [addingReminders, setAddingReminders] = useState(false);
  const handleReminderToggle = () => setAddingReminders(!addingReminders);

  return (
    <Row>
      {addingReminders ? (
        <AddReminder toggleForm={handleReminderToggle} />
      ) : (
        <RemindersList toggleForm={handleReminderToggle} />
      )}
    </Row>
  );
};

export default Reminders;
