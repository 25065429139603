import React from "react";
import { Button } from "antd";
import { bool, string, object, func } from "prop-types";
import styled from "styled-components";
import { ProfileButton } from "../../components/ProfileButton/index.tsx";

const ButtonGroup = styled(Button.Group)`
  margin-right: ${props => (props.sideBarCollapsed ? "15px" : "425px")};
`;

/**
 * Buttons for toggling spanish or english translations
 * for the whole app. Exist in the header
 * @param {*} props
 */
const TranslationButtons = props => {
  const {
    sideBarCollapsed,
    i18n,
    lang,
    handleLogout,
    handleLangClick,
    isTakingAssessment
  } = props;

  const translationIsSpanish = lang === "es";
  return (
    <ButtonGroup value="large" sideBarCollapsed={sideBarCollapsed}>
      <ProfileButton i18n={i18n} lang={lang} />
      {translationIsSpanish && (
        <Button
          disabled={isTakingAssessment}
          onClick={() => handleLangClick("en")}
        >
          {i18n[lang].LanguageButton.English}
        </Button>
      )}
      {!translationIsSpanish && (
        <Button
          disabled={isTakingAssessment}
          onClick={() => handleLangClick("es")}
        >
          {i18n[lang].LanguageButton.Spanish}
        </Button>
      )}
      <Button onClick={handleLogout}>{i18n[lang].Logout}</Button>
    </ButtonGroup>
  );
};

TranslationButtons.propTypes = {
  i18n: object.isRequired,
  lang: string.isRequired,
  sideBarCollapsed: bool.isRequired,
  handleLogout: func.isRequired,
  handleLangClick: func.isRequired,
  isTakingAssessment: bool.isRequired
};

export default TranslationButtons;
