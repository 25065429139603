import React from "react";
import { Row, Col, Table } from "antd";
import { format } from "date-fns";
import { connect } from "react-redux";
// import { DeleteWeight } from "../../../../modules/caregiver/operations";
import { caregiverHomeOperations } from "modules/caregiver";

class WeightTrackingTable extends React.Component {
  handleDelete = record => {
    this.props.DeleteWeight(this.props.patientId, record.id);
  };

  render() {
    const { i18n, lang } = this.props;

    // do this in the reducer later TODO:
    const d = this.props.weights
      .map(({ id, createdAt, weight, patientId, ...rest }) => ({
        key: id,
        id: id,
        createdAt: format(createdAt, "MM/DD/YY HH:mm a"),
        patientId: patientId,
        weight: weight
      }))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    const columns = [
      {
        title: i18n[lang].WeightMetric.Date,
        dataIndex: "createdAt",
        key: "date",
        render: text => <span>{text}</span> //eslint-disable-line
      },
      {
        title: `${i18n[lang].WeightMetric.Weight} (lb)`,
        dataIndex: "weight",
        key: "weight",
        render: text => <span>{text}</span> // eslint-disable-line
      },
      {
        title: i18n[lang].WeightMetric.Action,
        key: "action",
        render: (
          text,
          record // eslint-disable-line
        ) => (
            <span>
              <button onClick={() => this.handleDelete(record)}>
                {i18n[lang].WeightMetric.Delete}
              </button>
            </span>
          )
      }
    ];

    return (
      <Row>
        <Col>
          <Table columns={columns} dataSource={d} />
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({
  patientId: state.CaregiverReducer.patientcombos[0].pid,
  weights: state.CaregiverReducer.patientWeightTrack,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatch = {
  DeleteWeight: caregiverHomeOperations.DeleteWeight
};

export default connect(mapState, mapDispatch)(WeightTrackingTable);
