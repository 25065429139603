import * as React from "react";
import { Row, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "modules/translation/actions";

const TranslationButtons = () => {
  const dispatch = useDispatch();
  const lang = useSelector(state => state.LangReducer.lang);
  const i18n = useSelector(state => state.LangReducer.i18n);
  const handleLangClick = lang => dispatch(setLang(lang));

  return (
    <Row type="flex" justify="center">
      <Button onClick={() => handleLangClick("en")}>
        {i18n[lang].LanguageButton.English}
      </Button>
      <Button onClick={() => handleLangClick("es")}>
        {i18n[lang].LanguageButton.Spanish}
      </Button>
    </Row>
  );
};

export default TranslationButtons;
