import types from "./types";
/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const submitDailyLivingSuccess = message => ({
  type: types.DailyLiving_SUCCESS,
  payload: {
    message,
    showResponse: true,
    loading: false
  }
});

export const submitDailyLiving = () => ({
  type: types.DailyLiving_SUBMIT,
  payload: {
    loading: true
  }
});

export const submitDailyLivingFail = message => ({
  type: types.DailyLiving_FAIL,
  payload: {
    message: message,
    loading: false,
    showResponse: false
  }
});

export const viewDailyLiving = () => ({
  type: types.DailyLiving_VIEW,
  payload: {
    showResponse: false,
    score: 0
  }
});

export const getDailyLivingAssessment = (questions, message) => ({
  type: types.DailyLiving_GET_QUESTIONS,
  payload: {
    questions,
    message
  }
});

export const viewDailyLivingResults = () => ({
  type: types.DailyLiving_VIEW_RESULTS,
  payload: {}
});

export const routeChangeDailyLiving = () => ({
  type: types.DailyLiving_ROUTE_CHANGE
});

export const GetDailyLivingSubmissionsAction = message => ({
  type: types.DailyLiving_GET_PAST_SUBMISSIONS,
  payload: {
    loading: true,
    message
  }
});

export const SetDailyLivingSubmissionsAction = (data, message) => ({
  type: types.DailyLiving_SET_PAST_SUBMISSIONS,
  payload: {
    submissions: data,
    message,
    loading: false
  }
});
