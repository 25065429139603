import React from "react";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip
} from "recharts";
import { format } from "date-fns";

let data2 = [
  {
    createdAt: format(
      new Date("2020-03-16T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 220,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-03-24T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 200,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-04-22T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 179,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-04-18T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 178,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-04-28T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 204,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-04-01T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 200,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-05-07T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 201,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-05-28T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 195,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-06-14T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 195,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-06-17T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 145,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-07-05T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 695,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-08-01T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 195,
    opacity: "1.0",
    symbol: "circle"
  },
  {
    createdAt: format(
      new Date("2020-08-27T18:23:20.000Z"),
      "MM/DD/YYYY h:mm a"
    ),
    weight: 195,
    opacity: "1.0",
    symbol: "circle"
  }
];

export const WeightTrackingChart = props => {
  const processData = data =>
    data.map(d => {
      return {
        createdAt: format(d.createdAt, "MM/DD/YYYY h:mm a"),
        weight: d.weight,
        opacity: "1.0",
        symbol: "circle"
      };
    });
  return (
    <ResponsiveContainer width="100%" height={200}>
      <LineChart data={processData(props.data)}>
        <Line
          type="monotone"
          dataKey="weight"
          stroke="#001529"
          dot={{ stroke: "red", strokeWidth: 2 }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="createdAt" />
        <YAxis />
        <Tooltip />
      </LineChart>
    </ResponsiveContainer>
  );
};
