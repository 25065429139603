import React from "react";
import { Row, Col } from "antd";
import {
  submitPositiveAspectsAssessment,
  getPostiveAspectSurveyData
} from "../../modules/positiveAspectsAssessment/operations";
import { connect } from "react-redux";
import Wizard from "../../components/Wizard/AssessmentWizard";
import { withRouter } from "react-router-dom";
import CustomRadioGroup from "../../components/CustomRadioGroup/index";
import { array, func, object, string, bool } from "prop-types";

class PaocAssessment extends React.Component {
  componentDidMount() {
    this.props.getPostiveAspectSurveyData();
  }
  render() {
    const { i18n, lang } = this.props;

    return (
      <Row>
        <Col span={24}>
          <Wizard
            initialValues={{
              q1: { surveyQuestionId: -1, response: "" },
              q2: { surveyQuestionId: -1, response: "" },
              q3: { surveyQuestionId: -1, response: "" },
              q4: { surveyQuestionId: -1, response: "" },
              q5: { surveyQuestionId: -1, response: "" },
              q6: { surveyQuestionId: -1, response: "" },
              q7: { surveyQuestionId: -1, response: "" },
              q8: { surveyQuestionId: -1, response: "" },
              q9: { surveyQuestionId: -1, response: "" }
            }}
            onSubmit={async (values, actions) => {
              let payload = [];
              const { questions } = this.props;

              // each response (obj) has a meta object
              // index : position of answer picked
              // position: the index of the question
              // decision: text of the decision picked
              // lang: language of the decision picked
              for (let [key, obj] of Object.entries(values)) {
                // we are only sending the english responses to the backend, for research purposes
                // so we need to replace the response that is
                // in spanish with the corresponding english version and the surveyQuestionId

                const { index, position, lang } = obj.meta;

                if (lang === "spanish") {
                  obj.response = questions[position].decisions[index];
                  obj.surveyQuestionId = questions[position].id;
                }

                delete obj.meta;
                payload.push(obj);
              }

              this.props.submitPositiveAspectsAssessment(
                payload,
                this.props.history
              );
            }}
            instructions={
              <p>
                <h4>{i18n[lang].PaocQ.InstructionTitle}</h4>
                <p>{i18n[lang].PaocQ.Instructions}</p>
              </p>
            }
          >
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q1.response === "") {
                  errors.q1 =
                    lang === "en"
                      ? "Question 1 Missing An Answer"
                      : "Le falta respuesta a la pregunta 1";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={1}
                    index={0}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q2.response === "") {
                  errors.q2 =
                    lang === "en"
                      ? "Question 2 Missing An Answer"
                      : "Le falta respuesta a la pregunta 2";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={2}
                    index={1}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q3.response === "") {
                  errors.q3 =
                    lang === "en"
                      ? "Question 3 Missing An Answer"
                      : "Le falta respuesta a la pregunta 3";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={3}
                    index={2}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q4.response === "") {
                  errors.q4 =
                    lang === "en"
                      ? "Question 4 Missing An Answer"
                      : "Le falta respuesta a la pregunta 4";
                  return errors;
                }
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={4}
                    index={3}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q5.response === "") {
                  errors.q5 =
                    lang === "en"
                      ? "Question 5 Missing An Answer"
                      : "Le falta respuesta a la pregunta 5";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={5}
                    index={4}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q6.response === "") {
                  errors.q6 =
                    lang === "en"
                      ? "Question 6 Missing An Answer"
                      : "Le falta respuesta a la pregunta 6";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={6}
                    index={5}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q7.response === "") {
                  errors.q7 =
                    lang === "en"
                      ? "Question 7 Missing An Answer"
                      : "Le falta respuesta a la pregunta 7";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={7}
                    index={6}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q8.response === "") {
                  errors.q8 =
                    lang === "en"
                      ? "Question 8 Missing An Answer"
                      : "Le falta respuesta a la pregunta 8";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={8}
                    index={7}
                  />
                );
              }}
            </Wizard.Page>
            <Wizard.Page
              validate={values => {
                const errors = {};
                if (values.q9.response === "") {
                  errors.q9 =
                    lang === "en"
                      ? "Question 9 Missing An Answer"
                      : "Le falta respuesta a la pregunta 9";
                }
                return errors;
              }}
            >
              {props => {
                return (
                  <Question
                    lang={this.props.lang}
                    loading={this.props.loading}
                    questions={this.props.questions}
                    questions_es={this.props.questions_es}
                    patient={this.props.patient}
                    {...props}
                    rank={9}
                    index={8}
                  />
                );
              }}
            </Wizard.Page>
          </Wizard>
        </Col>
      </Row>
    );
  }
}

const mapState = state => {
  return {
    questions: state.PositiveAspectsReducer.questions,
    questions_es: state.PositiveAspectsReducer.questions_es,
    loading: state.PositiveAspectsReducer.loading,
    patient: state.CaregiverReducer.patientcombos[0],
    i18n: state.LangReducer.i18n,
    lang: state.LangReducer.lang
  };
};

const mapDispatch = {
  submitPositiveAspectsAssessment,
  getPostiveAspectSurveyData
};

PaocAssessment.propTypes = {
  questions: array.isRequired,
  questions_es: array.isRequired,
  loading: bool.isRequired,
  patient: object,
  i18n: object.isRequired,
  lang: string.isRequired,
  getPostiveAspectSurveyData: func.isRequired,
  submitPositiveAspectsAssessment: func.isRequired,
  history: object.isRequired
};

export default withRouter(
  connect(
    mapState,
    mapDispatch
  )(PaocAssessment)
);

const Question = props => {
  if (props.lang === "en") {
    return (
      <Row>
        {!props.loading && props.questions.length > 1 && (
          <CustomRadioGroup
            {...props.questions[props.index]}
            {...props}
            lang="english"
            patient={props.patient}
            rank={props.rank}
          />
        )}
      </Row>
    );
  } else {
    return (
      <Row>
        {!props.loading && props.questions_es.length > 1 && (
          <CustomRadioGroup
            {...props.questions_es[props.index]}
            {...props}
            lang="spanish"
            patient={props.patient}
            rank={props.rank}
          />
        )}
      </Row>
    );
  }
};
