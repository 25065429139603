import * as React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, Input } from "formik-antd";
import {
  Input as AntIntput,
  Row,
  Col,
  Upload,
  message,
  Button,
  Icon,
  Modal
} from "antd";
import CustomButton from "components/CustomButton/CustomButton";
import { UpdateUser } from "modules/user/utils";
import { IUser } from "modules/user/interfaces";
import { LoadUser } from "modules/user/actions";
const InputGroup = AntIntput.Group;

function beforeUpload(file) {
  const isValid =
    file.type === "image/jpeg" ||
    file.type === "image/jpeg" ||
    file.type === "image/jpg" ||
    file.type === "image/png";
  if (!isValid) {
    message.error("You can only upload JPG & PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isValid && isLt2M;
}

function getBase64v2(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

const EditCaregiverProfileSchema = Yup.object().shape({
  firstname: Yup.string().required("First Name Required"),
  lastname: Yup.string().required("Last Name Required")
});

const EditCaregiverProfileSchemaEs = Yup.object().shape({
  firstname: Yup.string().required("Primer Nombre Requerido"),
  lastname: Yup.string().required("Apelido Requerido")
});

interface Props {
  toggleEditForm: any;
}
export const EditCaregiverProfile: React.SFC<Props> = props => {
  const { firstname, lastname, picture, id }: IUser = useSelector(
    state => state.User
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const lang = useSelector(state => state.LangReducer.lang);

  const handlePhotoChange = (info, setFieldValueForFormik, field) => {
    if (info.file.status === "uploading") {
      setIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64v2(info.file.originFileObj)
        .then((imageUrl: string) => {
          setFieldValueForFormik(field + "", imageUrl);
          setIsLoading(false);
          setImageUrl(imageUrl);
        })
        .catch(err => {
          setIsLoading(false);
        });
    }
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64v2(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
  };
  return (
    <div>
      <Formik
        initialValues={{ firstname, lastname, picture }}
        validationSchema={
          lang === "en"
            ? EditCaregiverProfileSchema
            : EditCaregiverProfileSchemaEs
        }
        onSubmit={async values => {
          const response = await UpdateUser(values, id);
          dispatch(
            LoadUser({
              firstname: response.data.firstname,
              lastname: response.data.lastname,
              picture: response.data.picture
            })
          );
          props.toggleEditForm(false);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <InputGroup>
              <Row>
                <Col>
                  <Form.Item
                    name="firstname"
                    label={lang === "en" ? "First Name" : "Primer Nombre"}
                  >
                    <Input name="firstname" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="lastname"
                    label={lang === "en" ? "Last Name" : "Apellido"}
                  >
                    <Input name="lastname" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item
                    name="picture"
                    label={lang === "en" ? "Photo" : "Foto"}
                  >
                    <Upload
                      customRequest={dummyRequest}
                      name="picture"
                      listType="picture-card"
                      beforeUpload={beforeUpload}
                      onPreview={handlePreview}
                      onChange={e =>
                        handlePhotoChange(e, setFieldValue, "picture")
                      }
                    >
                      <Button>
                        <Icon type="upload" />
                        {lang === "en" ? "Click to Upload" : "Subir"}
                      </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomButton type="primary" htmlType="submit">
                    {lang === "en" ? "Submit" : "Mandar"}
                  </CustomButton>
                </Col>
              </Row>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </InputGroup>
          </Form>
        )}
      </Formik>
    </div>
  );
};
