import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import { createPageVisit } from "../modules/todo-prescription/operations";
import styled from "styled-components";
import { englishResources, spanishResources } from "../utils/resources/index";

const ProvideResourcesFor = language => {
  if (language === "en") return englishResources;
  if (language === "es") return spanishResources;

  return [];
};

const LinkText = styled.a`
  color: #0349d6;

  &:hover {
    color: 026bff;
  }
`;

const StyledLi = styled.li`
  margin: 15px 0px;
`;

class Resources extends React.Component {
  handleLinkClick = e => {
    e.preventDefault();
    const url = e.target.href;
    this.props.createPageVisit(this.props.cId, e.target.href);
    window.open(url, "_blank");
  };
  render() {
    const { i18n, lang } = this.props;

    const ResourceLink = props => {
      return (
        <StyledLi id={props.id}>
          <LinkText
            onClick={props.handleClick}
            target="_blank"
            rel="noopener noreferrer"
            href={props.link}
          >
            {props.name}
          </LinkText>
        </StyledLi>
      );
    };

    return (
      <div className="content">
        <Row>
          <Col span={24}>
            <h2>{i18n[lang].Resources.Title}</h2>
            <h4>{i18n[lang].Resources.Section1}</h4>
          </Col>
          <Col span={24}>
            <ul>
              {ProvideResourcesFor(lang)
                .slice(0, 5)
                .map(v => (
                  <ResourceLink
                    key={v.id}
                    id={v.id}
                    handleClick={this.handleLinkClick}
                    link={v.url}
                    name={v.body}
                  />
                ))}
            </ul>
          </Col>
          <Col span={24}>
            <h4>{i18n[lang].Resources.Section2}</h4>
            <ul>
              {ProvideResourcesFor(lang)
                .slice(5, 11)
                .map(v => (
                  <ResourceLink
                    key={v.id}
                    id={v.id}
                    handleClick={this.handleLinkClick}
                    link={v.url}
                    name={v.body}
                  />
                ))}
            </ul>
          </Col>
          <Col span={24}>
            <h4>{i18n[lang].Resources.Section3}</h4>
            <ul>
              {ProvideResourcesFor(lang)
                .slice(11, 15)
                .map(v => (
                  <ResourceLink
                    key={v.id}
                    id={v.id}
                    handleClick={this.handleLinkClick}
                    link={v.url}
                    name={v.body}
                  />
                ))}
            </ul>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => ({
  cId: state.User.id,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});
const mapDispatch = {
  createPageVisit
};

export default connect(
  mapState,
  mapDispatch
)(Resources);
