import request from "../request";

/**
 * APi Endpoints for an Admin Account
 */

/**
 * Creates a new Provider in the System
 *
 * Expects user to be {
 *  firstname: "",
 *  lastname: "",
 *  phone: "",
 *  password: "",
 *  email: ""
 * }
 * @param {*} user
 */
export const CreateProvider = user => {
  return request({
    method: "post",
    url: "/api/users/register-provider",
    data: { ...user }
  });
};
