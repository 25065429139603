import {
  submitPositveAspects,
  getPositiveAspect,
  setPositveAspectQuestions,
  viewPositiveAspects,
  viewPositiveAspectsResults,
  routeChangePositveAspects,
  PositiveAspectsSuccessAction,
  GetPaocSubmissionsAction,
  SetPaocSubmissionsAction,
  PositiveAspectFail
} from "./actions";
import {
  postPostiveAspectSurvey,
  getPositveAspectSurvey,
  getPaocSubmissions
} from "./utils";

/**
 * To represent chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

export const submitPositiveAspectsAssessment = (payload, history) => {
  return dispatch => {
    return postPostiveAspectSurvey(payload)
      .then(response => {
        const score = response.data.score.totalScore;
        const message = response.data.message;
        dispatch(PositiveAspectsSuccessAction(score, message));
        history.push("/paoc/response");
      })
      .catch(err => console.log(err));
  };
};

export const getPostiveAspectSurveyData = () => {
  return dispatch => {
    // set the loading/fetching state
    dispatch(getPositiveAspect);

    return getPositveAspectSurvey()
      .then(response => {
        dispatch(setPositveAspectQuestions(response.data));
      })
      .catch(err => console.log(err));
  };
};

export const GetPaocSubmissions = () => {
  return dispatch => {
    dispatch(GetPaocSubmissionsAction("Retrieving past submissions"));
    return getPaocSubmissions()
      .then(response => {
        dispatch(
          SetPaocSubmissionsAction(response.data, "Success Fetch Submissions")
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(PositiveAspectFail(err));
      });
  };
};

export default {
  submitPositiveAspectsAssessment,
  viewPositiveAspectsResults,
  viewPositiveAspects,
  routeChangePositveAspects
};
