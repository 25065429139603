import React from "react";
import { func, string, object, number } from "prop-types";
import { Row, Col, Card, Avatar } from "antd";
import RefrigeratorDoor from "./RefrigeratorDoor";
import TaskPrescription from "./tasks-prescriptions/TaskPrescription.tsx";
import { withGetScreen } from "react-getscreen";
import { connect } from "react-redux";
import {
  GetCaregiverPatients,
  GetCaregiverProvider
} from "../../../modules/caregiver/operations";
import { caregiverHomeOperations } from "../../../modules/caregiver";
import { setLang } from "../../../modules/translation/actions";
import defaultAvatarSvg from "../../../assets/img/avatars/avatar.svg";
import { ContentButtonsContainer, BtnWrapper } from "./Styled";
import CustomButton from "../../../components/CustomButton/CustomButton";
import WeightTracking from "./WeightTracking";

const breakpoints = {
  mobileLimit: 576,
  tabletLimit: 768
};

class CaregiverHome extends React.Component {
  state = {
    showPrescription: true,
    showDoor: false,
    showMetrics: false
  };

  componentDidMount() {
    const { cId } = this.props;

    // Fetch all the patients of the current Caregiver
    this.props.GetCaregiverPatients();

    // Fetch the Provider of the current Caregiver
    this.props.GetCaregiverProvider(cId);
  }

  handleSwitchContent = content => {
    if (content === "prescription") {
      this.setState({
        showPrescription: true,
        showDoor: false,
        showMetrics: false
      });
    }

    if (content === "door") {
      this.setState({
        showPrescription: false,
        showDoor: true,
        showMetrics: false
      });
    }

    if (content === "metrics") {
      this.setState({
        showPrescription: false,
        showDoor: false,
        showMetrics: true
      });
    }
  };

  render() {
    const { i18n, lang } = this.props;

    const setCaregiverAvatar = () => {
      if (this.props.cPicture === "") {
        return defaultAvatarSvg;
      }

      return this.props.cPicture;
    };
    return (
      <Row>
        <Row className="content" type="flex" justify="center">
          <h1>{i18n[lang].CaregiverHome.Greeting}</h1>
        </Row>
        <Row type="flex" justify="space-around">
          <Col span={24}>
            <Row type="flex" justify="space-around">
              <Col
                style={{
                  textAlign: this.props.isMobile() ? "left" : "center",
                  marginTop: "9px"
                }}
                xs={24}
                sm={12}
              >
                <Col xs={8} sm={24}>
                  <Avatar
                    size={this.props.isMobile() ? 82 : 164}
                    src={setCaregiverAvatar()}
                  />
                </Col>
                <Col xs={16} sm={24}>
                  <h3>{`${this.props.cFirstName} ${this.props.cLastName}`}</h3>
                  <h5>{i18n[lang].CaregiverHome.CLabel}</h5>
                </Col>
              </Col>
              <Col
                style={{
                  textAlign: this.props.isMobile() ? "left " : "center",
                  marginTop: "9px"
                }}
                xs={24}
                sm={12}
              >
                <Col xs={8} sm={24}>
                  <Avatar
                    size={this.props.isMobile() ? 82 : 164}
                    src={this.props.pPicture}
                  />
                </Col>
                <Col xs={16} sm={24}>
                  <h3>{`${this.props.pFirstName} ${this.props.pLastName}`}</h3>
                  <h5>{i18n[lang].CaregiverHome.PLabel}</h5>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: "45px" }}>
          <Row type="flex" justify="center">
            <ContentButtonsContainer gutter={8}>
              <BtnWrapper>
                <CustomButton
                  type="primary"
                  size="large"
                  block={this.props.isMobile() ? true : false}
                  onClick={() => this.handleSwitchContent("prescription")}
                >
                  {i18n[lang].CaregiverHome.Prescriptions}
                </CustomButton>
              </BtnWrapper>
              <BtnWrapper>
                <CustomButton
                  type="primary"
                  size="large"
                  onClick={() => this.handleSwitchContent("door")}
                  block={this.props.isMobile() ? true : false}
                >
                  {i18n[lang].CaregiverHome.RefrigeratorDoor}
                </CustomButton>
              </BtnWrapper>
              <BtnWrapper>
                <CustomButton
                  type="primary"
                  size="large"
                  onClick={() => this.handleSwitchContent("metrics")}
                  block={this.props.isMobile() ? true : false}
                >
                  {i18n[lang].CaregiverHome.WeightMetrics}
                </CustomButton>
              </BtnWrapper>
            </ContentButtonsContainer>
          </Row>
          <Col span={24}>
            {this.state.showPrescription && (
              <Card>
                <TaskPrescription />
              </Card>
            )}
            {this.state.showDoor && <RefrigeratorDoor />}
            {this.state.showMetrics && <WeightTracking />}
          </Col>
        </Row>
      </Row>
    );
  }
}

const mapStateToProps = state => ({
  cFirstName: state.User.firstname,
  cLastName: state.User.lastname,
  cPicture: state.User.picture,
  cId: state.User.id,
  pFirstName: state.CaregiverReducer.patientcombos[0].firstname,
  pLastName: state.CaregiverReducer.patientcombos[0].lastname,
  pPicture: state.CaregiverReducer.patientcombos[0].picture,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatch = {
  GetCaregiverPatients: caregiverHomeOperations.GetCaregiverPatients,
  GetCaregiverProvider: caregiverHomeOperations.GetCaregiverProvider,
  setLang
};

const CaregiverHomeConnected = connect(
  mapStateToProps,
  mapDispatch
)(CaregiverHome);

CaregiverHome.propTypes = {
  GetCaregiverPatients: func,
  cFirstName: string,
  cLastName: string,
  cPicture: string,
  cId: number,
  pFirstName: string,
  pLastName: string,
  pPicture: string,
  i18n: object,
  lang: string,
  isMobile: func,
  GetCaregiverProvider: func
};

CaregiverHome.defaultProps = {
  cFirstName: "",
  cLastName: "",
  cPicture: "",
  pFirstName: "",
  pLastName: "",
  pPicture: ""
};

export default withGetScreen(CaregiverHomeConnected, breakpoints);
