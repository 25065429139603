import React from "react";
import { Row, Col } from "antd";
import BehaviorAssessment from "./behaviorTracking/BehaviorTable";
class BehaviorTracking extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col xs={24}>
            <h3>Patient Memory/Behavior History</h3>
            <h5>
              The caregiver was asked about the problems patients sometimes have
              and how much this problem bothered or upset them when it happened.
              The below shows what problems the caregiver reported. The color
              (legend below) represents how much this problem bothered or upset
              the caregiver.
            </h5>
          </Col>
        </Row>

        <BehaviorAssessment />
      </>
    );
  }
}

export default BehaviorTracking;
