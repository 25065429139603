import {
  submitZarit,
  viewZarit,
  viewZaritResults,
  routeChangeZarit,
  GetZaritQuestionsAction,
  SetZaritQuestionsAction,
  ZaritFailAction,
  SubmitZaritSuccessAction,
  GetZaritSubmissionsAction,
  SetZaritSubmissionsAction
} from "./actions";
import { getZaritSurvey, postZaritSurvey, getZaritSubmissions } from "./utils";

/**
 * To represetn chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

export const submitZaritAssessment = (payload, history) => {
  return dispatch => {
    //TODO: dispatch to set loading to true

    return postZaritSurvey(payload)
      .then(response => {
        dispatch(
          SubmitZaritSuccessAction(
            response.data.message,
            response.data.score.totalScore
          )
        );

        history.push("/zarit/response");
      })
      .catch(err => dispatch(ZaritFailAction(err)));
  };
};

export const getZaritAssessment = () => {
  return dispatch => {
    dispatch(GetZaritQuestionsAction("Fetching survey"));

    getZaritSurvey()
      .then(response => {
        dispatch(
          SetZaritQuestionsAction(response.data, "Survey Fetched Successfully")
        );
        // history.push("/zarit/response");
      })
      .catch(err => {
        dispatch(ZaritFailAction(err));
      });
  };
};

export const GetZaritSubmissions = () => {
  return dispatch => {
    dispatch(GetZaritSubmissionsAction("Retrieving past submissions"));
    return getZaritSubmissions()
      .then(response => {
        dispatch(
          SetZaritSubmissionsAction(response.data, "Success Fetch Submissions")
        );
      })
      .catch(err => {
        console.log(err);
        dispatch(ZaritFailAction(err));
      });
  };
};

export default { viewZarit, viewZaritResults, routeChangeZarit };
