import React from "react";
import { Form, Radio, Select } from "antd";
import { func, object, array, string, number } from "prop-types";

const { Option } = Select;

const radioStyle = {
  display: "block",
  height: "30px",
  lineHeight: "30px"
};

class TrackBehaviorChoices extends React.Component {
  state = {
    selectEnabled: false
  };

  // Update the Formik State, radio button selection
  onSymptonSelect = e => {
    // Needed for when the User selects no after selecting yes,
    // will permit easy way of filtering out what questions response no to send
    // if (e.target.value === "No") {
    //   this.props.setFieldValue(`${this.props.qNum}.surveyQuestionId`, -1);
    // }

    if (e.target.value === "yes") {
      this.props.setFieldValue(`${this.props.qNum}.symptomSelected`, "yes");
      this.props.setFieldValue(`${this.props.qNum}.selectEnabled`, true);
      this.setState({
        selectEnabled: true
      });
    } else {
      this.props.setFieldValue(`${this.props.qNum}.symptomSelected`, "No");
      // this.props.setFieldValue(`${this.props.qNum}.response`, "Select")
      this.props.setFieldValue(`${this.props.qNum}.selectEnabled`, false);
      this.setState({
        selectEnabled: false
      });
    }
  };

  /**
   * Update Formik State
   * Select Option
   */
  onReactionChange = decision => {
    // // update the formik state
    console.log(this.props);

    // set this questions id
    this.props.setFieldValue(
      `${this.props.qNum}.surveyQuestionId`,
      this.props.id
    );

    //set this questions response
    this.props.setFieldValue(`${this.props.qNum}.response`, decision);
  };

  /**
   * Checks if there is a previous repsonse
   * in the values object.
   *
   * If there is no response then it will be equal to
   * initialValues ""
   */

  render() {
    const { values, qNum } = this.props;

    return (
      <>
        <Form.Item label={`${this.props.rank}. ${this.props.text}`}>
          <Radio.Group
            onChange={this.onSymptonSelect}
            defaultValue={null}
            value={values[qNum].symptomSelected}
          >
            <Radio style={radioStyle} value="No">
              No
            </Radio>
            <Radio style={radioStyle} value="yes">
              {this.props.lang === "en" || this.props.lang === "english"
                ? "Yes"
                : "Si"}
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={
            this.props.lang === "en" || this.props.lang === "english"
              ? "Select Your Reaction"
              : "Selecciona Tu Reacción"
          }
        >
          <Select
            style={{ width: 200 }}
            disabled={!this.props.values[`${this.props.qNum}`].selectEnabled}
            onChange={this.onReactionChange}
            value={this.props.values[`${this.props.qNum}`].response}
          >
            {this.props.decisions.map((decision, index) => (
              <Option key={index} value={decision}>
                {decision}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </>
    );
  }
}

TrackBehaviorChoices.propTypes = {
  setFieldValue: func.isRequired,
  values: object.isRequired,
  decisions: array.isRequired,
  rank: number.isRequired,
  qNum: string.isRequired,
  id: number.isRequired
};

export default TrackBehaviorChoices;
