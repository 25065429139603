import React from "react";
import { Formik, Field, Errormessage } from "formik";
import { Form, Progress } from "antd";
import { connect } from "react-redux";
import { func, object, string, bool } from "prop-types";
import {
  TakeAssessmentAction,
  NotTakingAssessmentAction
} from "../../modules/translation/actions";

class Wizard extends React.Component {
  static Page = ({ children, parentState }) => {
    return children(parentState);
  };

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues
    };
  }

  componentDidMount() {
    this.props.takeAssesment();
  }

  componentWillUnmount() {
    this.props.stopTakingAssessment();
  }

  next = values => {
    window.scrollTo(0, 0);
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }));
  };

  previous = () => {
    this.setState(state => ({
      page: Math.max(state.page - 1, 0),
      values: this.state.values
    }));
  };

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];
    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === React.Children.count(children) - 1;
    if (isLastPage) {
      return onSubmit(values, bag);
    } else {
      bag.setTouched({});
      bag.setSubmitting(false);
      this.next(values);
    }
  };

  render() {
    const { children, i18n, lang } = this.props;
    const { page, values } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;

    // const b =
    //   activePage && activePage.props && activePage.props.validationSchema;
    // console.log(b);

    // Required for displaying and updating the progress of the assessment
    const pageCount = React.Children.count(children);
    const percentComplete = (page / pageCount) * 100;
    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validate={this.validate}
        onSubmit={this.handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        render={props => (
          <Form onSubmit={props.handleSubmit} layout="vertical">
            <Progress percent={percentComplete} />
            {this.props.instructions}
            {React.cloneElement(activePage, { parentState: { ...props } })}
            <div className="buttons">
              {page > 0 && (
                <button
                  type="button"
                  className="secondary"
                  onClick={this.previous}
                >
                  « {i18n[lang].AssessmentWizard.Previous}
                </button>
              )}

              {!isLastPage && (
                <button type="submit">
                  {i18n[lang].AssessmentWizard.Next} »
                </button>
              )}
              {isLastPage && (
                <button type="submit" disabled={props.isSubmitting}>
                  {i18n[lang].AssessmentWizard.Submit}
                </button>
              )}
            </div>
          </Form>
        )}
      />
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang,
  takingAssessment: state.User.isTakingAssessment
});

const mapDispatch = {
  takeAssesment: TakeAssessmentAction,
  stopTakingAssessment: NotTakingAssessmentAction
};

Wizard.propTypes = {
  i18n: object.isRequired,
  lang: string.isRequired,
  takingAssessment: bool,
  takeAssesment: func.isRequired,
  stopTakingAssessment: func.isRequired
};

export default connect(
  mapState,
  mapDispatch
)(Wizard);
