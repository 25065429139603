import request from "../request";
import axios from "axios";

/**
 * Takes the user's account credentials and stores it in the session storage
 * @param {} account
 */
export const setUserAccount = info => {
  const account = JSON.stringify(info);
  sessionStorage.setItem("auth", account);

  return sessionStorage.getItem("auth").length === account.length
    ? true
    : false;
};

/**
 * Returns the user's account credentials from the session storage
 */
export const getUserAccount = () => {
  const account = sessionStorage.getItem("auth");
  if (account !== undefined && account != null && account.length > 0) {
    return JSON.parse(account);
  } else {
    return false;
  }
};

/**
 * In order to track if a user logs out,
 * we make a post to this api endpoint.
 *
 * Returns a 204 on success, no response
 */
export const logout = () =>
  request({
    method: "post",
    url: "/api/users/logout",
    body: {}
  });

/**
 * Registers a Caregiver with their Patient
 * "email": "caregiver2@mail.com",
 * "password": "",
 * "firstname": "John",
 * "lastname": "Doe",
 * "phone": "786-305-999",
 * "picture": "blob-64",
 * "patientInfo": {
 *    "firstname": "Jane",
 *    "lastname": "Doe",
 *    "relationship": "nephew",
 *    "birthday": "01/20/10",
 *    "gender": "male",
 *    "picture": "blob-64"
 * }
 * @param {*} combo
 */
export const registercaregiver = combo => {
  return request({
    method: "post",
    url: "/api/users/register-caregiver",
    data: combo
  });
};
