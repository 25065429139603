import request from "../../utils/apis/request";

export const fetchRefrigeratorDoorData = caregiverId => {
  return request({
    method: "get",
    url: "/api/refrigerator-door/" + caregiverId
  });
};

/**
 * POSTs
 *
 */

export const postPrescription = data => {
  return request({
    method: "post",
    url: "/api/refrigerator-door/prescription",
    data
  });
};

export const postPhoneNumber = data => {
  return request({
    method: "post",
    url: "/api/refrigerator-door/phone-number",
    data
  });
};

export const postReminder = data => {
  return request({
    method: "post",
    url: "/api/refrigerator-door/reminder",
    data
  });
};

/**
 * UPDATEs
 */
export const putPrescription = (data, id) => {
  return request({
    method: "put",
    url: "/api/refrigerator-door/prescription/" + id,
    data
  });
};

export const putPhoneNumber = (data, id) => {
  return request({
    method: "put",
    url: "/api/refrigerator-door/phone-number/" + id,
    data
  });
};

export const putReminder = (data, id) => {
  return request({
    method: "put",
    url: "/api/refrigerator-door/reminder/" + id,
    data
  });
};

/**
 * DELETES
 */
export const deletePrescription = id => {
  return request({
    method: "delete",
    url: "/api/refrigerator-door/prescription/" + id
  });
};

export const deletePhoneNumber = id => {
  return request({
    method: "delete",
    url: "/api/refrigerator-door/phone-number/" + id
  });
};

export const deleteReminder = id => {
  return request({
    method: "delete",
    url: "/api/refrigerator-door/reminder/" + id
  });
};
