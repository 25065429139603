import types from "./types";
import produce from "immer";
import { AuthAction } from "./types";

interface IAuthState {
  isAuthenticated: boolean;
  redirectAfterLogin: string;
  role: string;
  loading: boolean;
  failed: boolean;
  message: string;
}
const defaultState: IAuthState = {
  isAuthenticated: false,
  redirectAfterLogin: "",
  role: "",
  loading: false,
  failed: false,
  message: ""
};

const authReducer = produce((draft: IAuthState, action: AuthAction) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      draft.role = action.payload.role;
      draft.isAuthenticated = action.payload.isAuthenticated;
      draft.loading = false;
      draft.failed = false;
      break;
    case types.LOGIN_FAIL:
      draft.isAuthenticated = action.payload.isAuthenticated;
      draft.loading = action.payload.loading;
      draft.message = action.payload.message;
      draft.failed = true;
      break;
    case types.LOGIN_START:
    case types.FORGOT_PASSWORD:
    case types.FORGOT_PASSWORD_SUCCESS:
    case types.FORGOT_PASSWORD_FAIL:
      draft.loading = action.payload.loading;
      break;
    case types.CHANGE_PASSWORD: {
      draft.loading = true;
      break;
    }
    case types.CHANGE_PASSWORD_SUCCESS:
    case types.CHANGE_PASSWORD_FAIL:
      draft.loading = false;
      break;
  }
}, defaultState);

export default authReducer;
