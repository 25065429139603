/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const TrackBehavior_SUBMIT_SUCCESS =
  "careheroes/assessments/TrackBehavior_SUBMIT_SUCCESS";
const TrackBehavior_SUCCESS = "careheroes/assessments/TrackBehavior_SUCCESS";
const TrackBehavior_FAIL = "careheroes/assessments/TrackBehavior_FAIL";
const TrackBehavior_VIEW = "careheroes/assessments/TrackBehavior_VIEW";
const TrackBehavior_VIEW_RESULTS =
  "careheroes/assessments/TrackBehavior_VIEW_RESULTS";
const TrackBehavior_ROUTE_CHANGE =
  "carehereoes/assessments/TrackBehavior_ROUTE_CHANGE";
const TrackBehavior_API_REQUEST_INIT =
  "careheroes/assessments/TrackBehavior_API_REQUEST_INIT";
const TrackBheavior_GET_QUESTIONS =
  "careheroes/assessments/TrackBehavior_GET_QUESTIONS";
const TrackBehavior_GET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/TrackBehavior_GET_PAST_SUBMISSIONS";
const TrackBehavior_SET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/TrackBehavior_SET_PAST_SUBMISSIONS";

export default {
  TrackBehavior_SUBMIT_SUCCESS,
  TrackBehavior_VIEW,
  TrackBehavior_VIEW_RESULTS,
  TrackBehavior_ROUTE_CHANGE,
  TrackBehavior_SUCCESS,
  TrackBehavior_FAIL,
  TrackBehavior_API_REQUEST_INIT,
  TrackBheavior_GET_QUESTIONS,
  TrackBehavior_GET_PAST_SUBMISSIONS,
  TrackBehavior_SET_PAST_SUBMISSIONS
};
