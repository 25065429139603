/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const User_LOAD = "careheroes/assessments/User_LOAD";
const User_UPDATE = "careheroes/assesments/User_UPDATE";
const User_RESET = "careheroes/assessments/User_RESET";

export default {
  User_LOAD,
  User_UPDATE,
  User_RESET
};
