import React from "react";

/**
 * Renders an image from a base64 string
 * @param {*} param0
 */

class Base64Image extends React.Component {
  state = {
    objUrl: ""
  };

  render() {
    const { height, width, alt } = this.props;
    return <img src={this.props.src} height={height} width={width} alt={alt} />;
  }
}

export default Base64Image;
