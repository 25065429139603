import types from "./types";

/**
 * This files contains all the action creator functions.
 *
 * All actions are represented by functions, even if they are not parametrized.
 *
 */

export const setMenuIndexAction = index => ({
  type: types.Sidemenu_SET_INDEX,
  payload: {
    index
  }
});
