import styled from "styled-components";

export const MenuSectionTwoLabel = styled.span`
  display: block;
  color: #f8c93e;
  font-size: 1.4rem;
`;

export const MenuSectionWrapper = styled.div`
  margin-top: 25px;
  padding: 15px 0px 15px 24px;
`;
