import React from "react";
import { Col } from "antd";
import { bool, array, number } from "prop-types";
import { setBorderStylingFor } from "../../../modules/trackBehaviorAssessment/utils";
import { DateLabel, Response, Text } from "../SurveyResponses";
import { useSelector } from "react-redux";

export const LastColumn = ({ isDailySurvey, submissions, rightIndex }) => {
  const lang = useSelector(state => state.LangReducer.lang);

  return (
    <Col span={isDailySurvey ? 12 : 8} style={{ border: "2px solid black" }}>
      <DateLabel>
        {submissions[rightIndex].createdAt.format("MM-DD-YYYY")}
      </DateLabel>
      {submissions[rightIndex].question_submission.map((s, index) => (
        <Response key={index}>
          <Text style={setBorderStylingFor(s.response)}>
            {lang === "en" ? s.response : s.response_es}
          </Text>
        </Response>
      ))}
    </Col>
  );
};

LastColumn.propTypes = {
  isDailySurvey: bool.isRequired,
  submissions: array.isRequired,
  rightIndex: number.isRequired
};

export const MiddleColumn = ({ submissions, leftIndex }) => {
  const lang = useSelector(state => state.LangReducer.lang);

  return (
    <Col span={8} style={{ border: "2px solid black" }}>
      <DateLabel>
        {submissions[leftIndex + 1].createdAt.format("MM-DD-YYYY")}
      </DateLabel>
      {submissions[leftIndex + 1].question_submission.map((s, index) => (
        <Response key={index}>
          <Text style={setBorderStylingFor(s.response)}>
            {lang === "en" ? s.response : s.response_es}
          </Text>
        </Response>
      ))}
    </Col>
  );
};

MiddleColumn.propTypes = {
  submissions: array.isRequired,
  leftIndex: number.isRequired
};
