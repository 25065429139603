import {
  GetPatientsSuccess,
  GetPatientsFailure,
  GetProviderAction,
  GetProviderActionFail,
  GetPatientWeightAction,
  GetWeightSuccessAction,
  PostWeightAction,
  PostWeightSuccessAction,
  PostWeightFailAction,
  DeleteWeightAction,
  DeleteWeightFailAction,
  DeleteWeightSuccessAction
} from "./actions";
import {
  getAllPatients,
  getProviderForCaregiver,
  getPatientWeightRecords,
  postPatientWeightRecord,
  deletePatientWeight
} from "./utils";
import { message } from "antd";
import { inDebug } from "../../utils/buildmodes";

/**
 * To represent chained operations you need a redux middleware to enhance the dispatch function.
 *
 * In our case, we use redux-thunk. We want to separate the thunks from the action creators,
 * even with the cost of writing extra code. So we define an operation as wrapper over actions.
 *
 * If the operation oly dispatches a single action - doesn't actualy use redux thunk - we forward
 * the action creator function. If the operation uses a thunk, it can dispatch many actions and
 * chain them with promises.s
 */

export const GetCaregiverPatients = () => {
  return dispatch => {
    getAllPatients()
      .then(payload => {
        if (inDebug()) {
          message.success(payload.message);
        }
        dispatch(GetPatientsSuccess(payload));
      })
      .catch(err => {
        // dispatch error
        if (inDebug()) {
          message.error(err.message);
        }
        dispatch(GetPatientsFailure(err));
      });
  };
};

/**
 * Returns the Provider for the identified Caregiver
 * @param {Number} id Caregiver ID
 */
export const GetCaregiverProvider = id => {
  return dispatch => {
    getProviderForCaregiver(id)
      .then(payload => {
        if (inDebug()) {
          message.success(payload.message);
        }
        dispatch(GetProviderAction(payload));
      })
      .catch(err => {
        // dispatch error
        if (inDebug()) {
          message.error(err.message);
        }
        dispatch(GetProviderActionFail);
      });
  };
};

/**
 * Creates a new weight record for the patient
 */
export const PostWeight = (patientId, weight) => {
  return dispatch => {
    dispatch(PostWeightAction());

    return postPatientWeightRecord({ patientId, weight })
      .then(payload => {
        if (inDebug()) {
          message.success(payload.message);
        }
        dispatch(PostWeightSuccessAction(payload.data));
        return true;
      })
      .catch(() => {
        if (inDebug()) {
          message.error("Error posting weight");
        }
        dispatch(PostWeightFailAction("Failed to submit weight."));
        return false;
      });
  };
};

/**
 * Fetches all the weight records for a given patient
 */
export const GetWeights = patientId => {
  return dispatch => {
    dispatch(GetPatientWeightAction());

    return getPatientWeightRecords(patientId)
      .then(payload => {
        if (inDebug()) {
          message.success(payload.message);
        }
        dispatch(GetWeightSuccessAction(payload.data));
        return true;
      })
      .catch(e => {
        if (inDebug()) {
          message.error(e.message);
        }
        dispatch(PostWeightFailAction);
        return true;
      });
  };
};

export const DeleteWeight = (patientId, recordId) => {
  console.log("inside delete weight");
  return dispatch => {
    dispatch(DeleteWeightAction());
    console.log("inside the dispatch");

    return deletePatientWeight(patientId, recordId)
      .then(() => {
        if (inDebug()) {
          message.success("success deleting weight");
        }
        dispatch(DeleteWeightSuccessAction(recordId));
      })
      .catch(error => {
        dispatch(DeleteWeightFailAction(error));
      });
  };
};

export default {
  GetCaregiverPatients,
  GetCaregiverProvider,
  PostWeight,
  GetWeights,
  DeleteWeight
};
