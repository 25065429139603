import PatientViewContainer from "../pages/PatientViewContainer";
import Register from "../pages/RegisterCaregiver";
import RegisterProvider from "../pages/admin/registerprovider/RegisterProvider.tsx";
import EditPatient from "../pages/provider/editpatient/EditPatient.tsx";
import { AddProvider } from "pages/provider/AddProvider";
/**
 * Routes related to the provider
 */
const ProviderRoutes = [
  {
    path: "/dashboard/provider/patient",
    name: "Patient",
    icon: null,
    component: PatientViewContainer
  },
  {
    path: "/dashboard/provider/patient/edit",
    name: "",
    icon: null,
    component: EditPatient
  },
  {
    path: "/dashboard/provider/register",
    name: "",
    icon: null,
    component: AddProvider
  },
  {
    path: "/register",
    name: "",
    icon: null,
    component: Register
  },
  {
    path: "/register/provider",
    component: RegisterProvider
  }
];

export default ProviderRoutes;
