/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const GET_PATIENTS = "careheroes/dashboard/provider/GET_PATIENTS";
const GET_PATIENTS_SUCCESS =
  "careheroes/dashboard/provider/GET_PATIENTS_SUCCESS";
const GET_PATIENTS_FAIL = "careheroes/dashboard/provider/GET_PATIENTS_FAIL";
const FILTER_COMBOS = "careheroes/dashboard/provider/FILTER_COMBOS";
const SELECT_COMBO_LIST = "careheroes/dashboard/provider/UPDATE_COMBO_LIST";
const RESET_COMBO_LIST = "careheroes/dashboard/provider/RESET_COMBO_LIST";
const SET_CURRENT_COMBO = "careheroes/dashboard/provider/SET_CURRENT_COMBO";
const RESET_CURRENT_COMBO = "careheroes/dashboard/provider/RESET_CURRENT_COMBO";
const SET_CAREGIVER_AND_PATIENT_ID =
  "careheroes/dashboard/provider/SET_CAREGIVER_AND_PATIENT_ID";
const CLEAR_CAREGIVER_AND_PATIENT_ID =
  "careheroes/dashboard/provider/CLEAR_CAREGIVER_AND_PATIENT_ID";

export default {
  GET_PATIENTS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  FILTER_COMBOS,
  SELECT_COMBO_LIST,
  RESET_COMBO_LIST,
  SET_CURRENT_COMBO,
  RESET_CURRENT_COMBO,
  SET_CAREGIVER_AND_PATIENT_ID,
  CLEAR_CAREGIVER_AND_PATIENT_ID,
};
