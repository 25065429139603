import React from "react";
import LoginView from "./login/view/LoginView.tsx";
import { loginAction } from "../modules/auth/operations.ts";
import { connect } from "react-redux";
import styled from "styled-components";
import bg from "../assets/img/pages/login/background/med_pattern_dark.png";
import { withRouter } from "react-router-dom";
import { setMenuIndexAction } from "../modules/sidemenu/actions";
import { object, func, bool, string } from "prop-types";

const bgStyle = {
  height: "100%",
  backgroundImage: `url(${bg})`
};

const Container = styled.div`
  background-image: bg;
`;

class LoginPage extends React.Component {
  componentDidMount() {
    const session = JSON.parse(sessionStorage.getItem("auth"));
    if (session && session.authenticated) {
      this.props.history.push("/dashboard");
    }
  }

  handleSubmit = (email, password) => {
    this.props.setMenuIndexAction(0);
    this.props.loginAction(email, password, this.props.history);
  };

  handleForgotPassword = () => {
    this.props.history.push("/auth/recover-pwd");
  };

  render() {
    return (
      <Container style={bgStyle}>
        <LoginView
          handleSubmit={this.handleSubmit}
          handleForgotPassword={this.handleForgotPassword}
        />
      </Container>
    );
  }
}

/**
 * Redux Config
 * @param {} state
 */
const mapStateToProps = state => {
  return {
    auth: state.auth,
    loading: state.auth.loading,
    message: state.auth.message,
    loginFailed: state.auth.failed
  };
};

const mapDispatch = {
  loginAction,
  setMenuIndexAction
};

LoginPage.propTypes = {
  auth: object.isRequired,
  loading: bool.isRequired,
  message: string.isRequired,
  loginFailed: bool.isRequired,
  loginAction: func.isRequired,
  setMenuIndexAction: func.isRequired,
  history: object.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatch)(LoginPage));
