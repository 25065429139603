import React from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import CustomButton from "../../../components/CustomButton/CustomButton";

/**
 * Positive Aspects of Caregiving
 */
class ZaritResponse extends React.Component {
  handleClick = () => this.props.history.push("/dashboard");
  render() {
    const { i18n, lang } = this.props;

    if (this.props.score >= 9) {
      return (
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "center " }}>
            <h4>
              {i18n[lang].AssessmentResponses.Zarit.Response1_Are_Experiencing}
            </h4>
            <h5>
              {i18n[lang].AssessmentResponses.Zarit.Response2_Are_Experiencing}
            </h5>
            <p>{i18n[lang].AssessmentResponses.Buttons.Instructions}</p>
          </Col>
          <Col>
            <CustomButton onClick={this.handleClick}>
              {i18n[lang].AssessmentResponses.Buttons.Label}
            </CustomButton>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row type="flex" justify="center">
          <Col span={24} style={{ textAlign: "center" }}>
            <h4>
              {i18n[lang].AssessmentResponses.Zarit.Response1_Not_Experiencing}
            </h4>
            <p>{i18n[lang].AssessmentResponses.Buttons.Instructions}</p>
          </Col>
          <Col>
            <CustomButton onClick={this.handleClick}>
              {i18n[lang].AssessmentResponses.Buttons.Label}
            </CustomButton>
          </Col>
        </Row>
      );
    }
  }
}

const mapStateToProps = state => ({
  score: state.zarit.score,
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

export default connect(mapStateToProps)(ZaritResponse);
