import React from "react";
import { Layout, Menu, Icon, Drawer, Button } from "antd";
import { NavLink } from "react-router-dom";
import {
  everyoneRoutes,
  caregiverAssessments,
  patientAssessments
} from "../../routes/dashboard";
import { connect } from "react-redux";
import { authSelectors } from "../../modules/auth";
import { setMenuIndexAction } from "../../modules/sidemenu/actions";
import styles from "./sidebar.module.css";
import { MenuSectionTwoLabel, MenuSectionWrapper } from "./StyledLinks";
import { func, string, object, bool } from "prop-types";
import styled from "styled-components";
import MediaQueries from "../../utils/styling/MediqQueryGenerator";
const { selected } = styles;
const { Sider } = Layout;

const StyledSider = styled(Sider)`
  position: relative;

  ${MediaQueries.between("small", "large")`
    position: absolute;
    z-index: 999;
    height: 100%;
    hamilton: "name";
  `}
`;

const Logo = styled.div`
  height: 30px;
  margin: 16px;
  display: flex;
  justify-content: space-between;
  color: #f8c93e;
  font-size: 1.5em;
`;

const MenuItem = styled(Menu.Item)`
  font-size: 1.2em !important;
`;

/**
 * Sidebar Component for the Full layout
 */
class Sidebar2 extends React.Component {
  state = {
    menuIndex: "0",
    url: ""
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      console.log("you changed locations", location);
      this.setState({
        url: location.pathname
      });
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }

  /**
   * When a Menu.Item route is clicked, change the route.
   *
   * If the device is in mobile, then collapse the menu as well.
   */
  handleRouteClick = (path, index) => {
    // close the side menu if on mobile
    if (this.props.isTablet() || this.props.isMobile())
      this.props.handleCollapse();
    // navigate to the route
    this.props.history.push(path);
  };

  render() {
    const [Home, Contact, Resources, Videos] = everyoneRoutes;
    const [FeelingSad, Overwhelmed, CaregivingTest] = caregiverAssessments;
    const [DailyLiving, TrackBehavior] = patientAssessments;

    const setWidth = () => {
      if (this.props.isMobile() || this.props.isTablet()) {
        return "100vw";
      }

      return "400";
    };
    return (
      <StyledSider
        trigger={null}
        collapsible
        breakpoint="xl"
        collapsed={this.props.collapsed}
        onBreakpoint={this.props.handleBreakpoint}
        collapsedWidth="0"
        width={setWidth()}
      >
        <Logo>
          <a
            href="#"
            className="simple-text logo-normal"
            style={{ color: "#F8C93E" }}
          >
            Care Heroes
          </a>
          <Button onClick={this.props.handleCollapse}>X</Button>
        </Logo>

        <Menu
          // defaultSelectedKeys={[this.state.menuIndex]}
          // selectedKeys={[this.props.menuIndex]}
          selectable={false}
          mode="inline"
          theme="dark"
        >
          <MenuItem
            onClick={() => this.handleRouteClick(Home.path, Home.key)}
            className={this.state.url === Home.path ? selected : ""}
            key={Home.key}
          >
            <Icon type="user" style={{ color: "#F8C93E" }} />
            <span>
              <NavLink style={{ color: "#F8C93E" }} to={Home.path}>
                {this.props.langDict.SideMenu.Home}
              </NavLink>
            </span>
          </MenuItem>

          <MenuItem
            onClick={() => this.handleRouteClick(Contact.path, Contact.key)}
            key={Contact.key}
            className={this.state.url === Contact.path ? selected : ""}
          >
            <Icon type="user" style={{ color: "#F8C93E" }} />
            <span>
              <NavLink style={{ color: "#F8C93E" }} to={Contact.path}>
                {this.props.langDict.SideMenu.Chatbot}
              </NavLink>
            </span>
          </MenuItem>

          <MenuItem
            onClick={() => this.handleRouteClick(Resources.path, Resources.key)}
            key={Resources.key}
            className={this.state.url === Resources.path ? selected : ""}
          >
            <Icon type="user" style={{ color: "#F8C93E" }} />
            <span>
              <NavLink style={{ color: "#F8C93E" }} to={Resources.path}>
                {this.props.langDict.SideMenu.Resources}
              </NavLink>
            </span>
          </MenuItem>

          <MenuItem
            onClick={() => this.handleRouteClick(Videos.path, Videos.key)}
            key={Videos.key}
            className={this.state.url === Videos.path ? selected : ""}
          >
            <Icon type="user" style={{ color: "#F8C93E" }} />
            <span>
              <NavLink style={{ color: "#F8C93E" }} to={Videos.path}>
                {this.props.langDict.SideMenu.Videos}
              </NavLink>
            </span>
          </MenuItem>

          {authSelectors.isCaregiver(this.props.auth) && (
            <Menu.Item
              style={{
                marginTop: "50px",
                textOverflow: "clip",
                overflow: "visible",
                whiteSpace: "normal",
                color: "#F8C93E",
                fontSize: "1.4rem"
              }}
            >
              <span style={{ color: "#F8C93E" }}>
                {this.props.langDict.SideMenu.MenuSectionOne}
              </span>
            </Menu.Item>
          )}

          {/**The next three menu.item should only display if caregiver is signed in */}
          {authSelectors.isCaregiver(this.props.auth) && (
            <Menu.Item
              onClick={() =>
                this.handleRouteClick(FeelingSad.path, FeelingSad.key)
              }
              style={
                FeelingSad.key === 4
                  ? {
                      textOverflow: "clip",
                      overflow: "visible",
                      whiteSpace: "normal",
                      height: "100%",
                      color: "#F8C93E",
                      fontSize: "1.2em"
                    }
                  : {}
              }
              key={FeelingSad.key}
              className={this.state.url === FeelingSad.path ? selected : ""}
            >
              <Icon type="user" style={{ color: "#F8C93E" }} />
              <span>
                <NavLink to={FeelingSad.path} style={{ color: "#F8C93E" }}>
                  {this.props.langDict.SideMenu.SadBlueLabel}
                </NavLink>
              </span>
            </Menu.Item>
          )}

          {authSelectors.isCaregiver(this.props.auth) && (
            <Menu.Item
              onClick={() =>
                this.handleRouteClick(Overwhelmed.path, Overwhelmed.key)
              }
              style={
                Overwhelmed.key === 5
                  ? {
                      textOverflow: "clip",
                      overflow: "visible",
                      whiteSpace: "normal",
                      height: "100%",
                      color: "#F8C93E",
                      fontSize: "1.2em"
                    }
                  : {}
              }
              key={Overwhelmed.key}
              className={this.state.url === Overwhelmed.path ? selected : ""}
            >
              <Icon type="user" style={{ color: "#F8C93E" }} />
              <span>
                <NavLink to={Overwhelmed.path} style={{ color: "#F8C93E" }}>
                  {this.props.langDict.SideMenu.OverwhelmedLabel}
                </NavLink>
              </span>
            </Menu.Item>
          )}

          {authSelectors.isCaregiver(this.props.auth) && (
            <Menu.Item
              onClick={() =>
                this.handleRouteClick(CaregivingTest.path, CaregivingTest.key)
              }
              style={
                CaregivingTest.key === 6
                  ? {
                      textOverflow: "clip",
                      overflow: "visible",
                      whiteSpace: "normal",
                      height: "100%",
                      color: "#F8C93E",
                      fontSize: "1.2em"
                    }
                  : {}
              }
              key={CaregivingTest.key}
              className={this.state.url === CaregivingTest.path ? selected : ""}
            >
              <Icon type="user" />
              <span>
                <NavLink
                  style={{
                    color: "#F8C93E"
                  }}
                  to={CaregivingTest.path}
                >
                  {this.props.langDict.SideMenu.CaregivingTestLabel}
                </NavLink>
              </span>
            </Menu.Item>
          )}

          {/* Taking Care of Your Loved Ones */}
          {authSelectors.isCaregiver(this.props.auth) && (
            <div>
              <MenuSectionWrapper>
                <MenuSectionTwoLabel>
                  {this.props.langDict.SideMenu.MenuSectionTwo}
                </MenuSectionTwoLabel>
              </MenuSectionWrapper>
            </div>
          )}

          {authSelectors.isCaregiver(this.props.auth) && (
            <Menu.Item
              onClick={() =>
                this.handleRouteClick(DailyLiving.path, DailyLiving.key)
              }
              style={
                DailyLiving.key === 7
                  ? {
                      textOverflow: "clip",
                      overflow: "visible",
                      whiteSpace: "normal",
                      height: "100%",
                      fontSize: "1.2em"
                    }
                  : {}
              }
              key={DailyLiving.key}
              className={this.state.url === DailyLiving.path ? selected : ""}
            >
              <Icon type="user" style={{ color: "#F8C93E" }} />
              <span>
                <NavLink to={DailyLiving.path} style={{ color: "#F8C93E" }}>
                  {this.props.langDict.SideMenu.DailyLiving}
                </NavLink>
              </span>
            </Menu.Item>
          )}

          {authSelectors.isCaregiver(this.props.auth) && (
            <Menu.Item
              onClick={() =>
                this.handleRouteClick(TrackBehavior.path, TrackBehavior.key)
              }
              style={
                TrackBehavior.key === 8
                  ? {
                      textOverflow: "clip",
                      overflow: "visible",
                      whiteSpace: "normal",
                      height: "100%",
                      fontSize: "1.2em"
                    }
                  : {}
              }
              key={TrackBehavior.key}
              className={this.state.url === TrackBehavior.path ? selected : ""}
            >
              <Icon type="user" style={{ color: "#F8C93E" }} />
              <span>
                <NavLink style={{ color: "#F8C93E" }} to={TrackBehavior.path}>
                  {this.props.langDict.SideMenu.TrackBehavior}
                </NavLink>
              </span>
            </Menu.Item>
          )}
        </Menu>
      </StyledSider>
    );
  }
}

const mapState = state => ({
  auth: state.auth,
  menuIndex: state.SideMenuReducer.index
});

const mapDispatch = {
  setMenuIndexAction
};

Sidebar2.propTypes = {
  setMenuIndexAction: func.isRequired,
  isTablet: func.isRequired,
  handleCollapse: func.isRequired,
  history: object.isRequired,
  isMobile: func.isRequired,
  langDict: object.isRequired
};

export default connect(
  mapState,
  mapDispatch
)(Sidebar2);
