import React from "react";
import styled from "styled-components";
import { Row, Col } from "antd";

const Avatar = styled.img`
  width: 272px;
  height: 168px;
`;

export default class DashboardAvatar extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col span={24}>
            <h2>{this.props.title}</h2>
          </Col>
        </Row>
        <Row>
          <Avatar src={this.props.picturePath} />
          {/* <img src={this.props.picturePath} /> */}
        </Row>
        <Row>
          <h3>{this.props.name}</h3>
        </Row>
      </>
    );
  }
}
