import types from "./types";
import produce from "immer";

const defaultState = {
  index: 0
};

const SideMenuReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.Sidemenu_SET_INDEX: {
      return produce(state, draft => {
        draft.index = action.payload.index;
      });
    }

    default:
      return state;
  }
};

export default SideMenuReducer;
