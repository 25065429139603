import React, { useEffect, useState } from "react";

import { Row, Col, List, Button, Spin, message } from "antd";
import { useSelector } from "react-redux";
import {
  fetchRefrigeratorDoorData,
  deleteReminder
} from "../../../../../modules/refrigeratordoor/utils";
import { format } from "date-fns";
import { object, string, number, func } from "prop-types";
import { SectionLabel } from "./Styled";
import CustomButton from "../../../../../components/CustomButton/CustomButton";

/**
 * Refrigerator Door Reminder List
 *
 * Takes care of fetching the reminders the user has created
 * and making the request of deleting them if necessary
 * @param props
 */
const RemindersList = props => {
  const [reminders, setReminders] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);

  const { i18n, lang } = useSelector(state => state.LangReducer);
  const caregiverId = useSelector(state => state.User.id);
  const providerLast = useSelector(
    state => state.CaregiverReducer.provider.lastname
  );

  /**
   * Removes a Reminder from the current list
   *
   */
  const removeReminder = async id => {
    const deletionResponse = await deleteReminder(id);
    if (deletionResponse == null) {
      message.error("Error removing reminder.");
    } else {
      setReminders(
        reminders.filter(item => {
          return item.id !== id;
        })
      );
    }
  };

  /**
   * Fetch the Reminders from the backend
   */
  useEffect(() => {
    fetchRefrigeratorDoorData(caregiverId)
      .then(response => {
        setReminders(response.data.reminders);
        setDataFetched(true);
      })
      .catch(() => {
        setDataFetched(true);
      });
  }, []);

  return (
    <Row>
      <Row>
        <SectionLabel xs={24} md={12}>
          <h3>{i18n[lang].RefrigeratorDoor.ReminderList}</h3>
        </SectionLabel>
        <SectionLabel xs={24} md={12}>
          <CustomButton type="primary" onClick={() => props.toggleForm()}>
            {i18n[lang].RefrigeratorDoor.AddReminder}
          </CustomButton>
        </SectionLabel>
        <Col md={24}>
          <h4>{`${i18n[lang].RefrigeratorDoor.ReminderInstruction1} ${providerLast} ${i18n[lang].RefrigeratorDoor.ReminderInstruction2}`}</h4>
        </Col>
      </Row>

      {!dataFetched && (
        <Row type="flex" justify="center">
          <Col>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
              }}
            >
              <h5>Fetching Reminders</h5>
              <Spin size="large" />
            </div>
          </Col>
        </Row>
      )}
      {dataFetched && (
        <Row>
          <Col>
            <List
              itemLayout="horizontal"
              dataSource={reminders}
              renderItem={item => (
                <List.Item
                  key={item.id}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <List.Item.Meta
                    title={
                      <div>
                        <h4>{item.text}</h4>
                      </div>
                    }
                    description={
                      <div>
                        <h5>
                          {lang === "en" ? "Date Created" : "Fecha de creacion"}
                          : {format(new Date(item.createdAt), "MM/DD/YYYY")}
                        </h5>
                      </div>
                    }
                  />
                  <Button onClick={() => removeReminder(item.id)}>
                    {i18n[lang].RefrigeratorDoor.Remove}
                  </Button>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )}
    </Row>
  );
};

RemindersList.propTypes = {
  i18n: object,
  lang: string,
  caregiverId: number,
  patientId: number,
  providerLast: string,
  toggleForm: func
};

export default RemindersList;
