/**
 * The types file contains thename of the actions that you are dispatching in your applicaiton.
 * As a good practice, you should try to scope the names based on the feature they belong to.
 * This helps when debugging more complex applications.
 */

const ZARIT_SUBMIT = "careheroes/assessments/ZARIT_SUBMIT";
const ZARIT_SUCCESS = "careheroes/assessments/ZARIT_SUCCESS";
const ZARIT_FAIL = "careheroes/assessments/ZARIT_FAIL";
const ZARIT_VIEW = "careheroes/assessments/ZARIT_VIEW";
const ZARIT_VIEW_RESULTS = "careheroes/assessments/ZARIT_VIEW_RESULTS";
const ZARIT_ROUTE_CHANGE = "carehereoes/assessments/ZARIT_ROUTE_CHANGE";
const ZARIT_GET_QUESTIONS = "careheroes/assessments/ZARIT_GET_QUESTIONS";
const ZARIT_SET_QUESTIONS = "careheroes/assessments/ZARIT_SET_QUESTIONS";
const ZARIT_GET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/ZARIT_GET_PAST_SUBMISSIONS";
const ZARIT_SET_PAST_SUBMISSIONS =
  "careheroes/assessments/submissions/ZARIT_SET_PAST_SUBMISSIONS";

export default {
  ZARIT_SUBMIT,
  ZARIT_SUCCESS,
  ZARIT_FAIL,
  ZARIT_VIEW,
  ZARIT_VIEW_RESULTS,
  ZARIT_ROUTE_CHANGE,
  ZARIT_GET_QUESTIONS,
  ZARIT_SET_QUESTIONS,
  ZARIT_GET_PAST_SUBMISSIONS,
  ZARIT_SET_PAST_SUBMISSIONS
};
