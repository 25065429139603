import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import { object } from "prop-types";
import App from "./app";
import { PersistGate } from "redux-persist/integration/react";
import ScrollTopTop from "./components/ScrollToTop";

type RootProps = {
  store: any,
  persistor: any
}
class Root extends React.Component<RootProps, {}> {
  static propTypes: any;

  render() {
    const { store, persistor } = this.props;

    return (
      <Provider store={store}>
        {/* 
        // @ts-ignore */}
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <ScrollTopTop>
              <App />
            </ScrollTopTop>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: object.isRequired,
  persistor: object.isRequired,
};

export default Root;
