import types, { CaregiverActions } from "./types";
import produce from "immer";
import { CaregiverState } from "./types";

const defaultState = {
  patientcombos: [
    {
      firstname: "",
      lastname: "",
      picture: ""
    }
  ],
  message: "",
  error: "",
  provider: {
    firstname: "",
    lastname: "",
    phone: ""
  },
  patientWeightTrack: [],
  loading: false
};

const CaregiverReducer = produce(
  (draft: CaregiverState, action: CaregiverActions) => {
    switch (action.type) {
      case types.GET_PATIENTS_SUCCESS: {
        draft.patientcombos = action.payload.patientcombos;
        draft.message = action.payload.message;
        break;
      }

      case types.GET_PATIENTS_FAIL: {
        draft.message = action.payload.message;
        break;
      }

      case types.GET_PATIENTS: {
        draft.patientcombos = [];
        draft.message = "Fetching patients";
        break;
      }

      case types.GET_PROVIDER: {
        // TODO: might change since now more than one provider can exist
        const provider = action.payload.provider[0];
        draft.provider.firstname = provider.firstname;
        draft.provider.lastname = provider.lastname;
        draft.provider.phone = provider.phone;
        break;
      }

      case types.GET_PROVIDER_FAIL: {
        console.log("failed to get the provider");
        break;
      }

      case types.GET_PATIENT_WEIGHT: {
        draft.loading = true;
        break;
      }

      case types.GET_PATIENT_WEIGHT_SUCCESS: {
        draft.loading = false;
        const expectedFormat = action.payload.weights.map(record => {
          const { createdAt, updatedAt, ...rest } = record;
          return {
            createdAt: new Date(createdAt),
            updatedAt: new Date(updatedAt),
            ...rest
          };
        });

        draft.patientWeightTrack = expectedFormat;
        break;
      }

      case types.GET_PATIENT_WEIGHT_FAIL: {
        draft.loading = false;
        draft.error =
          action.payload.error || "Error fetching patient weight records.";
        break;
      }

      case types.POST_PATIENT_WEIGHT: {
        draft.loading = true;
        break;
      }

      case types.POST_PATIENT_WEIGHT_SUCCESS: {
        const { createdAt, updatedAt, ...rest } = action.payload.record;
        const expectedFormat = {
          createdAt: new Date(createdAt),
          updatedAt: new Date(updatedAt),
          ...rest
        };

        draft.patientWeightTrack.push(expectedFormat);
        draft.loading = false;

        break;
      }

      case types.POST_PATIENT_WEIGHT_FAIL: {
        draft.loading = false;
        draft.error = action.payload.error || "Error posting weight record.";

        break;
      }

      case types.DELETE_PATIENT_WEIGHT: {
        draft.loading = false;

        break;
      }

      case types.DELETE_PATIENT_WEIGHT_SUCCESS: {
        draft.patientWeightTrack = draft.patientWeightTrack.filter(
          record => record.id !== action.payload.id
        );

        break;
      }

      case types.DELETE_PATIENT_WEIGHT_FAIL: {
        draft.loading = false;
        draft.error = action.payload.error;
        break;
      }
    }
  },
  defaultState
);

export default CaregiverReducer;
