import React from "react";
// import { Row } from "antd";
import { Row, Col, Button } from "antd";
import TrackBehaviorInstructions from "./track-behavioral-changes/TrackBehaviorInstructions";
import TrackBehaviorAssessment from "./track-behavioral-changes/TrackBehaviorAssessment";
import TrackBehaviorAssessment_ES from "./track-behavioral-changes/TrackBehaviorAssessment_ES";
import {
  submitTrackBehaviorAssessment,
  GetTrackBehaviorSubmissions,
  fetchTrackBehaviorQuestions
} from "../modules/trackBehaviorAssessment/operations";
import { connect } from "react-redux";
import CustomButton from "../components/CustomButton/CustomButton";

class TrackBehaviorChanges extends React.Component {
  state = {
    showWizard: false
  };

  componentDidMount() {
    this.props.GetTrackBehaviorSubmissions();
    this.props.fetchTrackBehaviorQuestions();
  }

  handleToggleWizard = e => {
    e.preventDefault();

    this.setState({
      showWizard: true
    });
  };

  handleShowPastAssessments = e => {
    e.preventDefault();

    this.props.history.push("/tracking-behavioral-changes/view");
  };
  handleSubmit = values => {
    this.props.submitTrackBehaviorAssessment(values, this.props.history);
  };

  render() {
    const { i18n, lang } = this.props;
    const { showWizard } = this.state;

    return (
      <div
        className="content"
        style={
          this.state.showWizard
            ? {}
            : {
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }
        }
      >
        <Row style={this.state.showWizard ? {} : { maxWidth: "600px" }}>
          {!this.state.showWizard && (
            <>
              <Col md={24}>
                <Row style={{ textAlign: "center" }}>
                  <TrackBehaviorInstructions />
                </Row>
              </Col>

              <Col span={24}>
                <CustomButton
                  type="primary"
                  onClick={this.handleToggleWizard}
                  block
                >
                  {i18n[lang].Assessments.BeginTest}
                </CustomButton>
                <Button
                  type="secondary"
                  onClick={this.handleShowPastAssessments}
                  block
                >
                  {i18n[lang].Assessments.ShowSubmissions}
                </Button>
              </Col>
            </>
          )}
          <Col>
            {showWizard && lang === "en" ? (
              <TrackBehaviorAssessment handleSubmit={this.handleSubmit} />
            ) : showWizard && lang === "es" ? (
              <TrackBehaviorAssessment_ES handleSubmit={this.handleSubmit} />
            ) : null}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => ({
  i18n: state.LangReducer.i18n,
  lang: state.LangReducer.lang
});

const mapDispatch = {
  submitTrackBehaviorAssessment,
  GetTrackBehaviorSubmissions,
  fetchTrackBehaviorQuestions
};

export default connect(
  mapState,
  mapDispatch
)(TrackBehaviorChanges);
