import React from "react";
import ReactDOM from "react-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss";
import "assets/demo/demo.css";

import configureStore, { history } from "./store/configureStore";
import Root from "./root";

const { store, persistor } = configureStore();

ReactDOM.render(
  <>
    <Root store={store} history={history} persistor={persistor} />
  </>,
  document.getElementById("root")
);
