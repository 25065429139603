import React from "react";
import { Form, Radio } from "antd";
import styled from "styled-components";
import { object, func, array, string, number } from "prop-types";

const StyledRadioGroup = styled(Radio.Group)`
  label {
    display: block;
    height: 30px;
    line-height: 30px;
  }
`;

/**
 * Radio Group for implementing each question used
 * in the surveys
 * @param {*} props
 */
const CustomRadioGroup = props => {
  const {
    setFieldValue,
    text,
    decisions,
    id,
    qNum,
    lang,
    errors,
    touched,
    setFieldTouched,
    rank,
    values,
    initialValues
  } = props;

  /**
   * Checks if there is a previous repsonse
   * in the values object.
   *
   * If there is no response then it will be equal to
   * initialValues ""
   */
  const previousResponseAnswer = () => {
    if (values[qNum].response !== initialValues[qNum].response) {
      return values[qNum].response;
    }

    return null;
  };

  const updateFormikState = e => {
    // console.log(e.target);

    setFieldTouched(qNum);
    setFieldValue(`${qNum}.meta`, e.target.meta);
    setFieldValue(`${qNum}.surveyQuestionId`, id);
    setFieldValue(`${qNum}.response`, e.target.value);
  };
  return (
    <Form.Item
      label={`${rank}. ${text}`}
      validateStatus={
        errors && errors[qNum] && (touched && touched[qNum]) ? "error" : null
      }
      help={errors && errors[qNum]}
    >
      <StyledRadioGroup
        onChange={updateFormikState}
        defaultValue={null}
        value={previousResponseAnswer()}
      >
        {decisions.map((decision, index) => (
          <Radio
            key={index}
            value={decision}
            meta={{ index, decision, position: rank - 1, lang }}
          >
            {decision}
          </Radio>
        ))}
      </StyledRadioGroup>
    </Form.Item>
  );
};

CustomRadioGroup.propTypes = {
  errors: object.isRequired,
  setFieldTouched: func.isRequired,
  decisions: array.isRequired,
  setFieldValue: func.isRequired,
  text: string.isRequired,
  id: number.isRequired,
  qNum: string.isRequired,
  touched: object,
  rank: number.isRequired,
  values: object.isRequired,
  initialValues: object.isRequired,
  lang: string.isRequired
};

export default CustomRadioGroup;
