import * as React from "react";
import { useState } from "react";
import { Row, Col, message } from "antd";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import CustomButton from "../../../components/CustomButton/CustomButton";
import TranslationButtons from "components/TranslationButtons";

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Debe tener al menos 8 caracteres")
    .matches(/(?=.*?[A-Z])/, "Debe tener al menos una mayúscula")
    .matches(/(?=.*?[a-z])/, "Debe tener al menos una letra minúscula.")
    .matches(/(?=.*?[0-9])/, "Debe tener al menos 1 dígito.")
    .matches(
      /(?=.*?[#?!@$%^&*-])/,
      "Debe tener al menos uno de los siguientes caracteres especiales #?!@$%^&*-"
    )
    .required("Se requiere contraseña"),
  confirmpassword: Yup.string()
    .required("Vuelva a escribir su contraseña.")
    .test("passwords-match", "Las contraseñas no coinciden.", function(value) {
      return this.parent.password === value;
    })
});

const SpanishResetForm = ({ handlePasswordChange, setUserSubmittedForm }) => {
  return (
    <>
      <TranslationButtons />
      <Formik
        initialValues={{
          password: "",
          confirmpassword: ""
        }}
        validationSchema={NewPasswordSchema}
        onSubmit={values => {
          handlePasswordChange(values.password)
            .then(() => {
              setUserSubmittedForm(true);
            })
            .catch(() => {
              message.error(
                "There was an error changing your password. Please contact the CareHeroes tech support team.",
                3
              );
            });
        }}
        render={({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <h5>Cambiar la contraseña</h5>
                <p>Introduzca su nueva contraseña</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Nueva contraseña" name="password">
                  <Input name="password" type="password" />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Confirmar contraseña" name="confirmpassword">
                  <Input name="confirmpassword" type="password" />
                </Form.Item>
              </Col>
            </Row>
            <CustomButton type="primary" htmlType="submit">
              Cambiar
            </CustomButton>
          </Form>
        )}
      />
    </>
  );
};

export default SpanishResetForm;
